import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { getFieldSiteDetails, updateFieldSite } from "store/actions"
import Form from "./Form"

function UpdateFieldSite() {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()

  const [selectedFieldStaff, setSelectedFieldStaff] = useState(
    "Search a field Staff"
  )
  const [selectedFieldStaffId, setSelectedFieldStaffId] =
    useState("Search a Staff")
  const [selectedFieldStaffSearch, setSelectedFieldStaffSearch] = useState("")

  const stateData = {
    selectedFieldStaff,
    setSelectedFieldStaff,
    selectedFieldStaffId,
    setSelectedFieldStaffId,
    selectedFieldStaffSearch,
    setSelectedFieldStaffSearch,
  }

  const { loading, fieldSiteDetails } = useSelector(state => ({
    loading: state.FieldSite.loading,
    fieldSiteDetails: state.FieldSite.fieldsiteDetails,
  }))

  useEffect(() => {
    dispatch(getFieldSiteDetails(params?.id))
  }, [dispatch, params.id])

  const handleValidSubmit = values => {
    const coord1 = values?.location.coordinates[0]
    const coord2 = values?.location.coordinates[1]

    const formattedLocation = `(${coord1},${coord2})`
    const updateValues = {
      ...values,
      field_staff: selectedFieldStaffId,
      location: formattedLocation,
    }
    dispatch(updateFieldSite(updateValues, params?.id, history))
  }

  return (
    <div>
      <div>
        <MetaTags>
          <title>Field Site | Loha </title>
        </MetaTags>
        <div className="page-content">
          <Breadcrumbs title="Field Site" breadcrumbItem="Update" />
          <Container fluid>
            <div className="container-fluid">
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
              >
                <Row>
                  <Col md="8" lg="8">
                    <Card>
                      <CardBody>
                        <Form isUpdate={fieldSiteDetails} states={stateData} />
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            type="submit"
                            color="success"
                            className="w-md mx-1"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Update
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default UpdateFieldSite
