import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { searchPlaces } from "store/actions"
import { map } from "lodash"
import PropTypes from "prop-types"
import { AvField } from "availity-reactstrap-validation"

export function MapSearch({ zoomTo, setMarkers }) {
  const dispatch = useDispatch()
  const [places, setPlaces] = useState("")
  const [showDropDown, setShowDropDown] = useState(false)

  const { AllPlaces } = useSelector(state => ({
    AllPlaces: state?.FieldSite?.places?.results,
  }))

  console.log(AllPlaces)

  useEffect(() => {
    if (places) {
      dispatch(searchPlaces(places))
    }
  }, [places])

  const handleChangePlaces = e => {
    setPlaces(e.target.value)
    setShowDropDown(true)
  }

  const handlePlacesSelect = item => {
    setPlaces(item?.formatted_address)
    zoomTo({
      lat: item?.geometry?.location?.lat,
      lng: item?.geometry?.location?.lng,
    })
    setShowDropDown(false)
    setMarkers(current => [
      {
        lat: item?.geometry?.location?.lat,
        lng: item?.geometry?.location?.lng,
        time: new Date(),
      },
    ])
  }

  return (
    <div className="search_map">
      <AvField
        name="places"
        label="Search Places"
        type="text"
        onChange={e => handleChangePlaces(e)}
        value={places}
        placeholder="Search Places..."
        className="mb-2"
      />
      {showDropDown && (
        <div className="searched_list">
          {map(AllPlaces?.slice(0, 10), (item, key) => (
            <p
              className="d-flex align-items-center cursor-pointer"
              key={key}
              onClick={() => handlePlacesSelect(item)}
            >
              {item?.formatted_address?.slice(0, 30)}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

MapSearch.propTypes = {
  zoomTo: PropTypes.func,
  setMarkers: PropTypes.func,
}
