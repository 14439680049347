/* STOREITEMS */
export const GET_STOREITEMS = "GET_STOREITEMS"
export const GET_STOREITEMS_SUCCESS = "GET_STOREITEMS_SUCCESS"
export const GET_STOREITEMS_FAIL = "GET_STOREITEMS_FAIL"

/* STOREITEMS DETAIL*/
export const GET_STOREITEM_DETAIL = "GET_STOREITEM_DETAIL"
export const GET_STOREITEM_DETAIL_SUCCESS = "GET_STOREITEM_DETAIL_SUCCESS"
export const GET_STOREITEM_DETAIL_FAIL = "GET_STOREITEM_DETAIL_FAIL"

/**
 * add STOREITEM
 */
export const CREATE_STOREITEM = "CREATE_STOREITEM"
export const CREATE_STOREITEM_SUCCESS = "CREATE_STOREITEM_SUCCESS"
export const CREATE_STOREITEM_FAIL = "CREATE_STOREITEM_FAIL"

/**
 * Edit STOREITEM
 */
export const UPDATE_STOREITEM = "UPDATE_STOREITEM"
export const UPDATE_STOREITEM_SUCCESS = "UPDATE_STOREITEM_SUCCESS"
export const UPDATE_STOREITEM_FAIL = "UPDATE_STOREITEM_FAIL"

/**
 * Delete STOREITEM
 */
export const DELETE_STOREITEM = "DELETE_STOREITEM"
export const DELETE_STOREITEM_SUCCESS = "DELETE_STOREITEM_SUCCESS"
export const DELETE_STOREITEM_FAIL = "DELETE_STOREITEM_FAIL"

// store
export const GET_STORESUPPLY = "GET_STORESUPPLY"
export const GET_STORESUPPLY_SUCCESS = "GET_STORESUPPLY_SUCCESS"
export const GET_STORESUPPLY_FAIL = "GET_STORESUPPLY_FAIL"

export const UPDATE_STORESUPPLY = "UPDATE_STORESUPPLY"
export const UPDATE_STORESUPPLY_SUCCESS = "UPDATE_STORESUPPLY_SUCCESS"
export const UPDATE_STORESUPPLY_FAIL = "UPDATE_STORESUPPLY_FAIL"
