import React, { useEffect, useState } from "react"
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { errorNotification } from "components/Common/Notification"
import { createVisitEntryImage, getVisitEntrys } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"

function VisitEntryImageForm({ show, onCloseClick, data }) {
  const dispatch = useDispatch()
  const params = useParams()
  const [images, setImages] = useState()
  const [imagePreview, setImagePreview] = useState()

  const { allVisitEntries } = useSelector(state => ({
    allVisitEntries: state?.VisitEntry?.visitentrys,
  }))

  const handleImageAdd = e => {
    const fileName = Array.from(e.target.files)
    // console.log(e.target.files)
    const myFileName =
      fileName[0]?.name.length <= 100
        ? fileName[0]?.name
        : errorNotification("Ensure this filename has at most 100 characters ")

    setImages({
      name: myFileName,
      size: fileName[0]?.size,
      image: e.target.files[0],
    })
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader()
      reader.onload = e => {
        setImagePreview({ image: e.target.result })
      }
      reader.readAsDataURL(e.target.files[0])
    }
  }

  // console.log(imagePreview)

  //   const onSubmit = (e, values) => {
  //     const finalData = {
  //       image: image,
  //       title: values?.title,
  //       description: values?.description,
  //       testimonial: values?.testimonial,
  //     }
  //     dispatch(onCreateHappyCouples(finalData, history))
  //   }

  const [visitEntry, setVisitEntry] = useState("Select VisitEntry...")
  const [visitEntryId, setVisitEntryId] = useState("")
  const [visitEntrySearch, setVisitEntrySearch] = useState("")

  useEffect(() => {
    dispatch(getVisitEntrys("", "", "", visitEntrySearch))
  }, [dispatch, visitEntrySearch])

  const handleEnters = textEntered => {
    setVisitEntrySearch(textEntered)
  }

  function handlerFinalValue(event) {
    setVisitEntry(event?.label)
    setVisitEntryId(event?.value)
  }

  const optionGroup1 = [
    {
      options: allVisitEntries?.results?.map((result, index) => ({
        key: index,
        label: result?.username,
        value: result?.id,
      })),
    },
  ]

  useEffect(() => {
    setVisitEntryId(data?.field_staff)
    setVisitEntry(data?.field_staff)
  }, [data])

  const handleValidSubmit = v => {
    const createValues = {
      ...v,
      visit_entry: params?.id,
      image: images,
    }
    // console.log(v)
    dispatch(
      createVisitEntryImage(createValues, onCloseClick, handleImageState)
    )
  }

  const handleImageState = () => {
    setImagePreview(null)
  }

  return (
    <div>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="">
          <div className="">
            <span className="text-muted h6">
              <ModalHeader>
                {data?.id
                  ? "Update VisitEntry Image"
                  : "Create VisitEntry Image"}
              </ModalHeader>
            </span>
          </div>
          <AvForm
            onValidSubmit={(e, v) => {
              handleValidSubmit(v)
            }}
          >
            <div className="p-3 row">
              <div className="col-12 mb-2">
                <AvField
                  name="title"
                  className="form-control"
                  id="thick-input"
                  type="text"
                  label="Title"
                  value={data?.title ?? ""}
                />{" "}
              </div>
              {/* <div className="col-12 mb-2">
                <label>Visit Entry</label>
                <Select
                  onInputChange={handleEnters}
                  value={visitEntryId}
                  placeholder={visitEntryId}
                  onChange={handlerFinalValue}
                  options={optionGroup1}
                  classNamePrefix="select2-selection"
                  isLoading={false}
                  required={"required"}
                />
              </div> */}

              <div className="col-12 mb-2">
                <AvField
                  label="Message"
                  id="horizontal-email-Input"
                  name="message"
                  className="form-control"
                  type="textarea"
                  value={data?.message ?? ""}
                />
              </div>
              <div className="col-12 mb-2">
                <AvField
                  label="Image"
                  id="horizontal-email-Input"
                  name="image"
                  className="form-control"
                  type="file"
                  onChange={e => handleImageAdd(e)}
                  validate={{
                    required: {
                      value: false,
                      errorMessage: "location is required",
                    },
                  }}
                />
                {imagePreview ? (
                  <div className="d-flex align-items-center p-1">
                    <img
                      src={imagePreview?.image}
                      style={{
                        height: 200,
                        maxWidth: 400,
                        objectFit: "cover",
                        border: "none",
                        borderRadius: 5,
                      }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="text-end mx-3">
              <Button
                className="px-3 ms-2"
                color="danger"
                onClick={onCloseClick}
              >
                Cancel
              </Button>
              <Button type="submit" className="px-3 mx-1" color="success">
                Create
              </Button>
            </div>
          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default VisitEntryImageForm

VisitEntryImageForm.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.object,
  show: PropTypes.bool,
}
