import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useRef, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import logo from "../../../assets/images/logo/LOHA.png"



import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { getSheetOrdersWeight } from "store/actions"

const TotalWeight = ({ history }) => {
  const dispatch = useDispatch()

  const { sheetOrdersWeight, loading } = useSelector(state => ({
    sheetOrdersWeight: state.Sheet.sheetOrdersWeight,
    loading: state.Sheet.loading,
  }))


  const [orderType, setOrderType] = useState("")
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")



  const handleValidSubmit = (onSubmitProps, values) => {
    setFrom(values["from"])
    setTo(values["to"])
    dispatch(getSheetOrdersWeight(values["from"], values["to"], orderType))
  }



  var today = new Date().toLocaleDateString()

  return (
    <>
      <MetaTags>
        <title>SheetOrdersWeight | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="SheetOrders" breadcrumbItem="SheetOrdersWeight" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={12}>
                <Card>
                  <CardBody className="">
                    <CardTitle className="h4 mb-4 print_only_show text-center">
                      <img src={logo} alt="lohalogo" height="22" className="m-auto d-block" />
                    </CardTitle>
                   
                    <AvForm style={{maxWidth:400}} 
                      className="form-horizontal print_hide card border border-light p-3"
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >


                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-from-Input"
                          className="col-sm-3 col-form-label"
                        >
                          From

                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-from-Input"
                            name="from"
                            type="date"
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-to-Input"
                          className="col-sm-3 col-form-label"
                        >
                          To

                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-to-Input"
                            name="to"
                            type="date"
                          />
                        </Col>
                      </div>

                    <CardTitle className="h4 mb-4 print_hide d-flex justify-content-evenly" style={{maxWidth:400}}>
                      <Button color={orderType == "" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("")}>All</Button>
                      <Button color={orderType == "addition" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("addition")}>Addition</Button>
                      <Button color={orderType == "deduction" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("deduction")}>Deduction</Button>
                    </CardTitle>



                      <div className="row justify-content-end">
                        <Col>
                          <div className="print_hide">
                            <Button
                              type="submit"
                              color="success"
                              className="w-md print_hide m-auto d-block"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </div>

                    </AvForm>

                    <Row>
                      <Col lx="6" md="6" className="mb-3 mt-4">
                        <Card>
                          {from &&
                            <CardBody>
                              <CardTitle
                                className="mb-4"
                                style={{ justifyContent: "space-between" }}
                              >
                                From

                                <div
                                  className="mt-2 clearfix w-fit-content"
                                  style={{ width: "fit-content" }}
                                >
                                  <div className="">
                                    <div
                                      className="input-group input-group-sm d-flex"
                                    >
                                      <h4 className="text-primary"><b>{from}</b></h4>
                                    </div>
                                  </div>
                                </div>
                              </CardTitle>
                            </CardBody>
                          }

                          {to &&
                            <CardBody>
                              <CardTitle
                                className="mb-4"
                                style={{ justifyContent: "space-between" }}
                              >
                                To

                                <div
                                  className="mt-2 clearfix w-fit-content"
                                  style={{ width: "fit-content" }}
                                >
                                  <div className="">
                                    <div
                                      className="input-group input-group-sm d-flex"
                                    >
                                      <h4 className="text-primary"><b>{to}</b></h4>
                                    </div>
                                  </div>
                                </div>
                              </CardTitle>
                            </CardBody>
                          }

                        </Card>
                      </Col>

                      <Col lx="6" md="6" className="mb-3 mt-4">
                        <Card>
                          {sheetOrdersWeight?.addition &&
                            <CardBody>
                              <CardTitle
                                className="mb-4"
                                style={{ justifyContent: "space-between" }}
                              >
                                Total Addition weight

                                <div
                                  className="mt-2 clearfix w-fit-content"
                                  style={{ width: "fit-content" }}
                                >
                                  <div className="">
                                    <div
                                      className="input-group input-group-sm d-flex"
                                    >
                                      <h4 className="text-primary"><b>{sheetOrdersWeight?.addition}</b> kg</h4>
                                    </div>
                                  </div>
                                </div>
                              </CardTitle>
                            </CardBody>
                          }

                          {sheetOrdersWeight?.deduction &&
                            <CardBody>
                              <CardTitle
                                className="mb-4"
                                style={{ justifyContent: "space-between" }}
                              >
                                Total Deduction weight

                                <div
                                  className="mt-2 clearfix w-fit-content"
                                  style={{ width: "fit-content" }}
                                >
                                  <div className="">
                                    <div
                                      className="input-group input-group-sm d-flex"
                                    >
                                      <h4 className="text-primary"><b>{sheetOrdersWeight?.deduction}</b> kg</h4>
                                    </div>
                                  </div>
                                </div>
                              </CardTitle>
                            </CardBody>
                          }

                        </Card>
                      </Col>
                    </Row>





                    <div className="text-left">
                      <Button className="print_hide" onClick={() => {
                        window.print()
                      }} >Print</Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>

        </Container>
      </div>
    </>
  )
}

export default TotalWeight

TotalWeight.propTypes = {
  history: PropTypes.object,
}
