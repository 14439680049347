import { AvField, AvForm } from "availity-reactstrap-validation"
import axios from "axios"
import { Notification } from "components/Common/Notification"
import { API_URL } from "helpers/api_methods"
import { map } from "lodash"
import React, { Fragment, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  Alert,
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

//actions
import {
  createProduct,
  createProductFail,
  createProductSuccess,
  getProductDetailSuccess,
  updateProductFail,
  updateProductSuccess,
} from "store/actions"

//components
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import OtherCost from "./OtherCost"
import RawmaterialForm from "./RawmaterialForm"

const CreateProduct = () => {
  const dispatch = useDispatch()
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [remove, setRemove] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState()

  const { createProducterror, createLoading, productDetail } = useSelector(
    state => ({
      createProducterror: state.Products.createProducterror,
      createLoading: state.Products.loading,
      productDetail: state.Products.productDetail,
    })
  )

  const [state, setState] = useState({})
  const [imagePreview, setImagePreview] = useState()

  const handleImageChange = e => {
    setState({
      ...state,
      image: e.target.files[0],
    })

    const [file] = e.target.files
    setImagePreview(URL.createObjectURL(file))
  }

  const handleSubmit = (e, v) => {
    console.log(v)

    e.preventDefault()

    dispatch(createProduct())

    const form_data = new FormData()
    {
      state?.image?.name &&
        form_data.append("image", state?.image, state?.image?.name)
    }
    form_data.append("serial_number", v.serial_number)
    form_data.append("channel_size", v.channel_size)
    form_data.append("guage", v.guage)
    if (v.perticular) {
      form_data.append("perticular", v.perticular)
    } else {
      form_data.append("perticular", "D")
    }
    if (v.no_of_cols) {
      form_data.append("no_of_cols", v.no_of_cols)
    } else {
      form_data.append("no_of_cols", 1)
    }
    form_data.append("width", v.width)
    form_data.append("height", v.height)
    if (selectedCategory === "is_door") {
      form_data.append("is_door", true)
      form_data.append("is_window", false)
      form_data.append("is_security_door", false)
    } 
    else if (selectedCategory === "is_security_door") {
      form_data.append("is_door", false)
      form_data.append("is_window", false)
      form_data.append("is_security_door", true)
    }else {
      form_data.append("is_window", true)
      form_data.append("is_door", false)
      form_data.append("is_security_door", false)
    }
    form_data.append("description", v.description)

    if (productDetail?.id) {
      let url = `${API_URL}/store/product/${productDetail.id}/`
      axios
        .put(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "token " + localStorage.getItem("token"),
          },
        })
        .then(res => {
          dispatch(updateProductSuccess(res.data))
          setLoading(false)
          Notification({
            type: "success",
            message: "Updated Successfully",
            title: "",
          })
        })
        .catch(err => {
          updateProductFail(err)
          dispatch(updateProductFail(err))
          setLoading(false)
          Notification({
            type: "error",
            message: "Update Faild",
            title: "",
          })
        })
    } else {
      let url = `${API_URL}/store/product/`
      axios
        .post(url, form_data, {
          headers: {
            "content-type": "multipart/form-data",
            Authorization: "token " + localStorage.getItem("token"),
          },
        })
        .then(res => {
          setBtnDisabled(false)
          setRemove(true)
          dispatch(createProductSuccess(res.data))
          dispatch(getProductDetailSuccess(res.data))
          window.scrollTo(0, 600)
        })
        .catch(err => createProductFail(err))
    }
  }

  return (
    <>
      <MetaTags>
        <title>Product | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Products" breadcrumbItem="Create Product" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col lg={11}>{ProductForm()}</Col>
              <Col xl="1"></Col>
            </Row>
          </div>
          {btnDisabled == false && (
            <>
              <div className="container-fluid">
                <RawmaterialForm myDisabled={btnDisabled} />
              </div>
              <div className="container-fluid">
                <OtherCost myDisabled={btnDisabled} />
              </div>

              <Link
                to={`/products/${productDetail?.id}`}
                className="text-end d-block mb-5 mt-3"
              >
                <Badge
                  className={"font-size-12 p-3 mx-3 badge-soft-success"}
                  style={{ cursor: "pointer" }}
                  pill
                >
                  See AlL Details Of Created Product
                </Badge>
              </Link>
            </>
          )}
        </Container>
      </div>
    </>
  )

  function ProductForm() {
    const inputData = inputFeildData

    const handleInput = () => {
      if (selectedCategory === "is_door" || selectedCategory === "is_security_door") {
        const modifiedArr = inputData?.filter(
          i => i?.name !== "perticular" && i?.name !== "no_of_cols"
        )
        return modifiedArr
      } else {
        return inputData
      }
    }

    return (
      <Card>
        <CardBody>
          <AvForm
            onValidSubmit={(e, v) => {
              handleSubmit(e, v)
            }}
          >
            {createProducterror && (
              <Alert color="danger">{createProducterror}</Alert>
            )}
            <div className="row">
              <div className="d-flex">
                {map(inputFeildDoor, (item, key) => (
                  <div key={key} className="m-1">
                    <input
                      type="radio"
                      className="btn-check"
                      name={item.value}
                      checked={selectedCategory === item.value}
                      id={item.id}
                      autoComplete="off"
                      value={item.value}
                      onClick={e => setSelectedCategory(e.target.value)}
                    />
                    <label className="btn btn-outline-info" htmlFor={item.id}>
                      {item?.title}
                    </label>
                  </div>
                ))}
              </div>

              {map(handleInput(), (item, key) => (
                <Fragment key={key}>
                  <Col
                    lg={parseInt(item?.width)}
                    className="mb-4"
                    style={{ display: item?.isHidden && true }}
                  >
                    <AvField
                      name={item?.name}
                      className=""
                      label={item?.title}
                      type={item?.type}
                      min={item?.min}
                      value={item?.value}
                      errorMessage="Invalid Input"
                      validate={{
                        required: { value: item?.isRequire },
                      }}
                    />
                  </Col>
                </Fragment>
              ))}

              {(selectedCategory === "is_door" || selectedCategory === "is_security_door") && <Col lg={5}></Col>}

              <Col lg={4} className="mb-4 mt-4">
                <AvField
                  name={"profit"}
                  className=""
                  label={"Profit (%)"}
                  type={"number"}
                />
                <div
                  className="row mb-4 mt-1"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Label htmlFor="image" className="col-sm-12 col-form-label">
                    Product Images
                  </Label>
                  <Col sm={12}>
                    <input
                      name="image"
                      type="file"
                      id="image"
                      accept="image/png, image/jpeg"
                      className="form-control"
                      onChange={handleImageChange}
                    />
                  </Col>
                  {imagePreview && (
                    <div
                      className="h-25 w-25 mt-4 p-3 sm:p-0"
                      style={{ marginLeft: "auto" }}
                    >
                      <img
                        src={imagePreview}
                        alt="product"
                        className="h-100 w-100"
                        style={{ borderRadius: "10px" }}
                      />
                    </div>
                  )}
                </div>
              </Col>
              <Col lg={8} className="mb-4 mt-4">
                <AvField
                  name={"description"}
                  label={"Description"}
                  type={"textarea"}
                  className=""
                  rows="5"
                  cols="50"
                />
              </Col>
            </div>

            {productDetail?.id ? (
              <div className="d-flex row justify-content-end">
                <div className="d-flex align-items-center justify-content-end">
                  <Button type="submit" color="success" className="w-md">
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    Update
                  </Button>
                </div>
              </div>
            ) : (
              <>
                {remove == false && (
                  <div className="d-flex row justify-content-end">
                    <div className="d-flex align-items-center justify-content-end">
                      <Button type="submit" color="success" className="w-md">
                        {createLoading && (
                          <>
                            <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                          </>
                        )}
                        Create
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
          </AvForm>
        </CardBody>
      </Card>
    )
  }
}

export default CreateProduct

const inputFeildData = [
  {
    id: 1,
    title: "Serial Number",
    name: "serial_number",
    type: "text",
    isRequire: true,
    width: 6,
    value: "LH - ",
  },
  {
    id: 2,
    title: "Channel Size",
    name: "channel_size",
    type: "text",
    isRequire: true,
    width: 6,
  },
  {
    id: 3,
    title: "Guage",
    name: "guage",
    type: "number",
    isRequire: true,
    width: 3, // checkbox
  },
  {
    id: 4,
    title: "Perticular (W/V/FW/KW/D)",
    name: "perticular",
    type: "text",
    isRequire: true,
    width: 3,
  },
  {
    id: 5,
    title: "No.of Columns",
    name: "no_of_cols",
    type: "number",
    isRequire: true,
    width: 2,
  },

  {
    id: 6,
    title: "Width (cm)",
    name: "width",
    type: "number",
    isRequire: true,
    width: 2,
  },
  {
    id: 7,
    title: "Height (cm)",
    name: "height",
    type: "number",
    isRequire: true,
    width: 2,
  },
]

const inputFeildDoor = [
  {
    id: 3,
    title: "Security Door",
    isRequire: true,
    value: "is_security_door",
  },
  {
    id: 2,
    title: "Window",
    isRequire: true,
    value: "is_window",
  },
  {
    id: 1,
    title: "Door Frame",
    isRequire: true,
    value: "is_door",
  },
]
// const handleChange = e => {
//   if (e.target.type === "checkbox") {
//     setState({
//       ...state,
//       [e.target.id]: e.target.checked,
//     })
//   } else {
//     setState({
//       ...state,
//       [e.target.id]: e.target.value,
//     })
//   }
// }
