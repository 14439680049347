import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

//contacts
import Contacts from "./profile/reducer"

//Dealer
import Dealers from "./dealers/reducer"

//supervisor
import Supervisors from "./supervisor/reducer"

//store item
import StoreItems from "./storeItem/reducer"

//sheet
import Sheet from "./sheet/reducer"

//product
import Products from "./product/reducer"

//orders
import Orders from "./orders/reducer"

//storemngr
import Storemngrs from "./storemanager/reducer"

import Sheetusers from "./sheetuser/reducer"

//qualitychecker
import Qltcheckers from "./qltchecker/reducer"

//prodcution manager
import Productionmngrs from "./productionmngr/reducer"

//finished Prod Chart
import Dashboard from "./Dashboard/reducer"

// fieldStaff
import FieldStaff from "./fieldstaff/reducer"

// fieldsite
import FieldSite from "./fieldsite/reducer"

import VisitEntryImage from "./visitentryimage/reducer"

import VisitEntry from "./visitentry/reducer"

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Contacts,
  Dealers,
  Storemngrs,
  Sheetusers,
  Supervisors,
  Qltcheckers,
  StoreItems,
  Sheet,
  Products,
  Orders,
  Productionmngrs,
  Dashboard,
  FieldStaff,
  FieldSite,
  VisitEntryImage,
  VisitEntry,
})

export default rootReducer
