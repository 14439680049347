import React, { useEffect, useState } from "react"
import {
  Button,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { errorNotification } from "components/Common/Notification"
import {
  createVisitEntry,
  getFieldStaffs,
  getMapPublicKey,
  getVisitEntryDetails,
  updateVisitEntry,
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"
import MyGoogleMap from "components/Common/Gmap/googleMap"

const Form = ({ states, isUpdate }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const {
    selectedFieldStaff,
    setSelectedFieldStaff,
    selectedFieldStaffId,
    setSelectedFieldStaffId,
    selectedFieldStaffSearch,
    setSelectedFieldStaffSearch,
    statusValue,
    setStatusValue,
  } = states

  //   const [imagePreview, setImagePreview] = useState()

  const { allVisitEntries, allFieldStaffs, visitEntryDetail, mapKey } =
    useSelector(state => ({
      allVisitEntries: state?.VisitEntry?.visitentrys,
      allFieldStaffs: state?.FieldStaff?.fieldstaffs,
      visitEntryDetail: state.VisitEntry.visitentryDetails,
      mapKey: state.FieldSite.map,
    }))

  console.log(states)

  useEffect(() => {
    dispatch(getFieldStaffs("", "", "", selectedFieldStaffSearch))
  }, [dispatch, selectedFieldStaffSearch])

  const handleEnters = textEntered => {
    setSelectedFieldStaffSearch(textEntered)
  }

  function handlerFinalValue(event) {
    setSelectedFieldStaff(event.label)
    setSelectedFieldStaffId(event?.value)
  }

  console.log(allFieldStaffs)

  const optionGroup1 = [
    {
      options: allFieldStaffs?.results?.map((result, index) => ({
        key: index,
        label: result?.full_name,
        value: result?.id,
      })),
    },
  ]
  console.log(selectedFieldStaff)

  useEffect(() => {
    setSelectedFieldStaffId(visitEntryDetail?.field_staff)
    setSelectedFieldStaff(visitEntryDetail?.fieldstaff_name)
  }, [visitEntryDetail])

  console.log(visitEntryDetail)

  // const handleValidSubmit = v => {
  //   if (!visitEntryDetail?.id) {
  //     const checkIn1 = v?.location.check_in_location[0]
  //     const checkIn2 = v?.location.check_in_location[1]

  //     const checkOut1 = v?.location.check_out_location[0]
  //     const checkOut2 = v?.location.check_out_location[1]

  //     const createValues = {
  //       // field_staff: selectedFieldStaffId,
  //       fieldsite: fieldSiteId,
  //       check_in_date: moment(v?.check_in_date).format(
  //         "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
  //       ),
  //       check_out_date: moment(v?.check_out_date).format(
  //         "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
  //       ),
  //       check_in_location: `(${checkIn1},${checkIn2})`,
  //       check_out_location: `(${checkOut1},${checkOut2})`,
  //       statusValue: statusValue,
  //     }

  //     console.log(createValues)
  //     dispatch(createVisitEntry(createValues, onCloseClick))
  //   } else {
  //     const checkIn1 = v?.location.check_in_location[0]
  //     const checkIn2 = v?.location.check_in_location[1]

  //     const checkOut1 = v?.location.check_out_location[0]
  //     const checkOut2 = v?.location.check_out_location[1]
  //     const updateVisitEntryData = {
  //       ...v,
  //       field_staff: selectedFieldStaffId,
  //       fieldsite: fieldSiteId,
  //       check_in_date: moment(v?.check_in_date).format(
  //         "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
  //       ),
  //       check_out_date: moment(v?.check_out_date).format(
  //         "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
  //       ),
  //       statusValue: statusValue,
  //       duration: v?.duration,
  //       check_in_location: `(${checkIn1},${checkIn2})`,
  //       check_out_location: `(${checkOut1},${checkOut2})`,
  //     }

  //     console.log(updateVisitEntryData)
  //     dispatch(
  //       updateVisitEntry(
  //         updateVisitEntryData,
  //         visitEntryDetail?.id,
  //         onCloseClick
  //       )
  //     )
  //   }
  // }

  console.log(statusValue)
  function handlerUserFinalValue(event) {
    setStatusValue(event.label)
  }
  useEffect(() => {
    setStatusValue(visitEntryDetail?.statusValue)
  }, [visitEntryDetail])

  const optionGroup2 = [
    { label: "Pending", value: "Pending" },
    { label: "Completed", value: "Completed" },
    { label: "In Progress", value: "In Progress" },
  ]

  useEffect(() => {
    setStatusValue(visitEntryDetail?.status)
  }, [visitEntryDetail])

  // map

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  const MAP_PUBLIC_KEY = mapKey?.google_map_api_key
  const [markers, setMarkers] = useState([])
  const [mapValues, setMapValues] = useState([11.0408, 76.0849])

  const pointVals = [mapValues]
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: point => setMapValues(point),
    },
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setMarkers(current => [
          {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            time: new Date(),
          },
        ])
      },
      () => null
    )
  }, [])

  return (
    <div>
      <div className="h-100 w-100">
        <span className="text-muted h6">
          <span>
            {visitEntryDetail?.id ? "Update VisitEntry " : "Create VisitEntry "}
          </span>
        </span>
      </div>

      <div className="p-3 row">
        <div className="col-6 mb-2">
          <AvField
            name="check_in_date"
            className="form-control"
            id="thick-input"
            type="date"
            label="CheckIn Date"
            value={moment(
              isUpdate?.check_in_date,
              "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
            ).format("YYYY-MM-DD")}
          />
        </div>

        {/* <div className="col-12 mb-2">
                <AvField
                  label="CheckIn Location"
                  id="horizontal-email-Input"
                  name="check_in_location"
                  className="form-control"
                  type="text"
                  value={isUpdate?.check_in_location ?? ""}
                />
              </div> */}
        <Row className="my-5">
          <Label>CheckIn Location</Label>
          <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
            <MyGoogleMap
              MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
              setMarkers={setMarkers}
              markers={markers}
              height={200}
            />
          </Col>
          <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
            <div className="col-12 mt-5 mx-5">
              <Label>
                <i className="bx bx-map"></i> Location( lat / lng )
              </Label>
            </div>
            <div className="col-6 mb-3 mt-2 mx-5">
              <AvField
                name="location.check_in_location[0]"
                placeholder="Latitude"
                type="text"
                id="#geolocation"
                errorMessage="Invalid latitude"
                validate={{
                  required: { value: true },
                }}
                value={markers[0]?.lat}
              />
            </div>
            <div className="col-6 mb-3 mt-3 mx-5">
              <AvField
                name="location.check_in_location[1]"
                placeholder="Longitude"
                type="text"
                errorMessage="Invalid longitude"
                validate={{
                  required: { value: true },
                }}
                value={markers[0]?.lng}
              />
            </div>
          </Col>
        </Row>
        <div className="col-6 mb-2">
          <AvField
            label="Checkout Date"
            id="horizontal-email-Input"
            name="check_out_date"
            className="form-control"
            type="date"
            value={moment(
              isUpdate?.check_out_date,
              "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
            ).format("YYYY-MM-DD")}
            //   onChange={e => handleImageAdd(e)}
            validate={{
              required: {
                value: false,
                errorMessage: "location is required",
              },
            }}
          />
        </div>

        {/* <div className="col-12 mb-2">
                  <AvField
                    label="Checkout Location"
                    id="horizontal-email-Input"
                    name="check_out_location"
                    className="form-control"
                    type="text"
                    value={isUpdate?.check_out_location ?? ""}
                  />
                </div> */}
        <Row className="my-5">
          <Label>Checkout Location</Label>
          <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
            <MyGoogleMap
              MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
              setMarkers={setMarkers}
              markers={markers}
              height={200}
            />
          </Col>
          <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
            <div className="col-12 mt-5 mx-5">
              <Label>
                <i className="bx bx-map"></i> Location( lat / lng )
              </Label>
            </div>
            <div className="col-6 mb-3 mt-2 mx-5">
              <AvField
                name="location.check_out_location[0]"
                placeholder="Latitude"
                type="text"
                id="#geolocation"
                errorMessage="Invalid latitude"
                validate={{
                  required: { value: true },
                }}
                value={markers[0]?.lat}
              />
            </div>
            <div className="col-6 mb-3 mt-3 mx-5">
              <AvField
                name="location.check_out_location[1]"
                placeholder="Longitude"
                type="text"
                errorMessage="Invalid longitude"
                validate={{
                  required: { value: true },
                }}
                value={markers[0]?.lng}
              />
            </div>
          </Col>
        </Row>

        {isUpdate?.id && (
          <div className="col-6 mb-2">
            <AvField
              label="Duration"
              id="horizontal-email-Input"
              name="duration"
              className="form-control"
              type="text"
              value={isUpdate?.duration ?? ""}
            />
          </div>
        )}

        <div className="col-6 mb-2">
          <label>statusValue</label>
          <Select
            value={statusValue}
            placeholder={statusValue}
            onChange={handlerUserFinalValue}
            options={optionGroup2}
            classNamePrefix="select2-selection"
            isLoading={false}
            required={"required"}
          />
        </div>
        {isUpdate?.id && (
          <div className="col-6">
            <label>Field Staff</label>
            <Select
              onInputChange={handleEnters}
              value={selectedFieldStaff}
              placeholder={selectedFieldStaff}
              onChange={handlerFinalValue}
              options={optionGroup1}
              classNamePrefix="select2-selection"
              isLoading={false}
              required={"required"}
            />
          </div>
        )}
        <div className="col-6 mb-2">
          <AvField
            label="Remark"
            id="horizontal-email-Input"
            name="remark"
            className="form-control"
            type="textarea"
            value={isUpdate?.remark ?? ""}
          />
        </div>
      </div>
    </div>
  )
}

export default Form

Form.propTypes = {
  states: PropTypes?.object,
  isUpdate: PropTypes.object,
}
