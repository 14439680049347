import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from "reactstrap"
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import {
  deleteVisitEntry,
  deleteVisitEntryImage,
  getMapPublicKey,
  getVisitEntryDetails,
  getVisitEntryImages,
} from "store/actions"
import moment from "moment"
import VisitEntryForm from "../Crud/Form"
import DeleteModal from "components/Common/DeleteModal"
import VisitEntryImageForm from "pages/FieldSite/VisitEntryImage/VisitEntryImageForm"
import MyPagination from "components/Common/MyPagination"
import { range } from "lodash"
import noImage from "assets/images/product.jpeg"
import { AvField, AvForm } from "availity-reactstrap-validation"
import MyGoogleMap from "components/Common/Gmap/googleMap"
import Gmap from "components/Common/Gmap/useGmap"
import { Map, Marker, Popup, TileLayer } from "react-leaflet"
import { Icon } from "leaflet"
import location from "assets/images/location.png"

function VisitEntryDetails() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const location = useLocation()
  const [page, setPage] = useState(1)
  const [visitEntryOpen, setVisitEntryOpen] = useState({
    status: false,
    data: "",
  })

  const [visitEntryImageOpen, setVisitEntryImageOpen] = useState({
    status: false,
    data: "",
  })

  const { imageLoading, visitEntryDetail, VisitEntryImage, mapKey } =
    useSelector(state => ({
      imageLoading: state.VisitEntryImage.loading,
      visitEntryDetail: state.VisitEntry.visitentryDetails,
      VisitEntryImage: state.VisitEntryImage.visitentryimages,
      mapKey: state?.FieldSite.map?.google_map_api_key,
    }))

  const totalPages = Math.ceil(VisitEntryImage?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  const onCloseClick = () => {
    setVisitEntryImageOpen({ status: false })
  }

  const [isOpen, setIsOpen] = useState({ status: false, id: "", content: "" })

  useEffect(() => {
    dispatch(getVisitEntryDetails(params?.id))
  }, [dispatch, params])

  const handleDeleteEvent = () => {
    if (isOpen?.content === "image") {
      dispatch(deleteVisitEntryImage(isOpen?.id, history))
      setIsOpen({ ...isOpen, status: false })
    } else {
      dispatch(deleteVisitEntry(isOpen?.id, history))
      setIsOpen({ ...isOpen, status: false })
    }
  }

  const handleDelete = (id, content) => {
    if (id) {
      setIsOpen({ ...isOpen, status: true, id: id, content: content })
    }
  }

  useEffect(() => {
    dispatch(getVisitEntryImages(pageSend(), params?.id, "", ""))
  }, [dispatch, params?.id, page])

  const coordinates = visitEntryDetail?.check_in_location
    ? visitEntryDetail?.check_in_location
    : ""
  const longitudeCheckin = coordinates.split(",")[0]?.replace("(", "")
  const latitudeCheckin = coordinates.split(",")[1]?.replace(")", "")

  const coordinates2 = visitEntryDetail?.check_out_location
    ? visitEntryDetail?.check_in_location
    : ""

  //map

  const myLocations = visitEntryDetail && visitEntryDetail?.check_in_location

  console.log(myLocations)

  let cleanedCoordinates = myLocations?.replace(/[()]/g, "")

  const coordinatesArray = cleanedCoordinates?.split(",")

  // Access the individual values
  const latitude = coordinatesArray && coordinatesArray[0]
  const longitude = coordinatesArray && coordinatesArray[1]

  console.log(latitude)

  console.log(longitude)

  const position = [latitude ?? "", longitude ?? ""]

  const myIcon = new Icon({
    iconUrl: location,
    iconSize: [35, 40],
  })

  console.log(position)

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  // console.log(markers)
  const MAP_PUBLIC_KEY = mapKey
  const [markers, setMarkers] = useState([])
  const [mapValues, setMapValues] = useState([])

  const [backendCoordinates, setBackendCoordinates] = useState({})

  const pointVals = [mapValues]
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: point => setMapValues(point),
    },
  }

  const myCheckout = visitEntryDetail && visitEntryDetail?.check_out_location

  let checkoutCoordinates = myCheckout?.replace(/[()]/g, "")

  const checkOutLocation = checkoutCoordinates?.split(",")

  // Access the individual values
  const checkoutLatitude = checkOutLocation && checkOutLocation[0]
  const checkoutLongitude = checkOutLocation && coordinatesArray[1]

  useEffect(() => {
    const myLocations = visitEntryDetail && visitEntryDetail?.check_in_location
    console.log("myLocations")
    console.log(myLocations)

    let cleanedCoordinates = myLocations?.replace(/[()]/g, "")

    const coordinatesArray = cleanedCoordinates?.split(",")

    // Access the individual values
    const latitude = coordinatesArray && coordinatesArray[0]
    const longitude = coordinatesArray && coordinatesArray[1]

    setBackendCoordinates({
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
    })
    console.log("backendCoordinates ::")
    console.log(backendCoordinates)
    // navigator.geolocation.getCurrentPosition(

    // navigator.geolocation.set
    navigator.geolocation.getCurrentPosition(
      position => {
        console.log(position.coords)

        setMarkers(current => [
          {
            lat: backendCoordinates.latitude,
            lng: backendCoordinates.longitude,
            time: new Date(),
          },
        ])
      },
      () => null
    )

    console.log("markers ::")
    console.log(markers)
  }, [visitEntryDetail])

  return (
    <div>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <VisitEntryImageForm
        show={visitEntryImageOpen?.status}
        onCloseClick={() => setVisitEntryImageOpen({ status: false })}
        data={visitEntryImageOpen?.data}
      />
      <MetaTags>
        <title>Site Visit Details | Loha </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs title="Site Visit" breadcrumbItem="Site Visit Details" />
        <Container fluid>
          <div className="container-fluid">
            <div className="row">
              <Col md="5">
                <Card>
                  <CardBody>
                    <p className="text-muted h5">
                      <Link to={`/fieldsite/${visitEntryDetail?.fieldsite}`}>
                        {visitEntryDetail?.fieldsite_name}
                      </Link>
                    </p>
                    <span>{visitEntryDetail?.owner_name}</span>
                    <br />
                    <span>{visitEntryDetail?.owner_address}</span>
                    <br />
                    <span>{visitEntryDetail?.owner_phone}</span>
                    <br />
                    <div className="d-flex align-items-center justify-content-end">
                      <Button
                        style={{ cursor: "pointer" }}
                        className="mx-2"
                        onClick={() => handleDelete(params?.id)}
                        size="sm"
                        color="danger"
                      >
                        {/* <i className="bx bxs-trash-alt text-danger font-size-16" /> */}
                        Remove
                      </Button>
                      <Link
                        to={`/visitEntry/update/${params?.id}`}
                        className=""
                      >
                        <Button className="px-3" size="sm" color="success">
                          {/* <i className="bx bx-edit-alt font-size-16" /> */}
                          Edit
                        </Button>
                      </Link>
                    </div>
                  </CardBody>
                </Card>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <span>Staff :</span>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="text-muted h5">
                          <Link
                            to={`/fieldstaff/${visitEntryDetail?.field_staff}`}
                          >
                            {visitEntryDetail?.fieldstaff_name}
                          </Link>
                        </p>
                        <Badge
                          className={
                            "font-size-12 badge-soft-success d-flex align-items-center justify-content-center"
                          }
                          pill
                        >
                          <i className="bx bx-timer ms-1  font-size-16  " />
                          &nbsp;
                          {visitEntryDetail?.duration} Minutes
                        </Badge>
                      </div>
                      <div className="d-flex align-items-center justify-content-between py-1">
                        <div>
                          <span>Check In :</span>
                          {visitEntryDetail?.check_in_date && (
                            <p>
                              {" "}
                              {moment(visitEntryDetail?.check_in_date).format(
                                "DD-MM-YYYY h:mm a"
                              )}
                            </p>
                          )}
                        </div>
                        <div>
                          <span>Check Out :</span>
                          {visitEntryDetail?.check_in_date && (
                            <p>
                              {moment(visitEntryDetail?.check_out_date).format(
                                "DD-MM-YYYY h:mm a"
                              )}
                            </p>
                          )}
                        </div>
                      </div>
                      <div style={{ minHeight: 20 }}>
                        <span>Notes :</span>
                        <br />
                        <p className="mx-1">{visitEntryDetail?.remark}</p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Col>

              <Col md="7">
                <AvForm>
                  <Card style={{ minHeight: 285, padding: 15 }}>
                    {visitEntryDetail?.check_in_location ? (
                      <>
                        {/* <Gmap longitude={longitude} latitude={latitude} /> */}

                        <Row className="">
                          <Col className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                            <MyGoogleMap
                              MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
                              setMarkers={setMarkers}
                              markers={markers}
                              height={300}
                              isSearch={false}
                              // link={true}
                              longitude={backendCoordinates.longitude}
                              latitude={backendCoordinates.latitude}
                            />
                          </Col>
                          <div className="col-12 mt-2 mx-1">
                            <Label>
                              <i className="bx bx-map"></i>CheckIn Location( lat
                              / lng )
                            </Label>
                          </div>
                        </Row>
                        <Row>
                          <Col md={4}>
                            {" "}
                            <AvField
                              name="location.check_in_location[0]"
                              placeholder="Latitude"
                              type="text"
                              id="#geolocation"
                              errorMessage="Invalid latitude"
                              validate={{
                                required: { value: true },
                              }}
                              value={latitude}
                              readOnly
                            />
                          </Col>
                          <Col md={4}>
                            <AvField
                              name="location.check_in_location[1]"
                              placeholder="Longitude"
                              type="text"
                              errorMessage="Invalid longitude"
                              validate={{
                                required: { value: true },
                              }}
                              value={longitude}
                              readOnly
                            />
                          </Col>
                          <Col md={4}>
                            <div className="d-flex align-items-center justify-content-center text-align-center">
                              <Button
                                size="sm"
                                className=""
                                style={{ textDecoration: "blue" }}
                                outline
                                color="primary"
                              >
                                <a
                                  href={`https://www.google.com/maps?q=${checkoutLatitude},${checkoutLongitude}`}
                                  rel="noreferrer"
                                  target="_blank"
                                  className="text-muted font-size-13"
                                >
                                  Check out Location{" "}
                                  <i className="bx bx-right-arrow-alt  font-size-15" />
                                </a>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <span
                        className="p-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        No CheckIn Location
                      </span>
                    )}
                  </Card>
                </AvForm>
              </Col>
              <Col md="12">
                <Card className="">
                  <CardBody>
                    <div className="d-flex align-items-centre justify-content-between m-2">
                      <div>Site Photos</div>
                      <div>
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() =>
                            setVisitEntryImageOpen({ status: true, data: "" })
                          }
                        >
                          + Add Site Photos
                        </Button>
                      </div>
                    </div>
                    {imageLoading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <Row className="p-2">
                        {VisitEntryImage?.results?.map(
                          (visitEntryImage, key) => (
                            <Col key={key} md="6">
                              <div
                                className="visitEntryImage-card mx-1 card p-3 card-bg-color"
                                style={{
                                  borderRadius: 10,
                                  backgroundColor: "#e9e3f5",
                                  minHeight: 250,
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    position: "absolute",
                                    top: 0,
                                    right: 2,
                                    padding: 5,
                                  }}
                                  onClick={() =>
                                    handleDelete(visitEntryImage?.id, "image")
                                  }
                                >
                                  <i className="bx bxs-trash-alt text-danger font-size-15 " />
                                </div>
                                <img
                                  src={
                                    visitEntryImage.image
                                      ? visitEntryImage?.image
                                      : noImage
                                  }
                                  alt={visitEntryImage.name}
                                  className="visitEntryImage-image mt-1"
                                  style={{
                                    height: "30%",
                                    width: "100%",
                                    borderRadius: 3,
                                    objectFit: "cover",
                                  }}
                                />
                                <div className="visitEntryImage-info p-2 text-center">
                                  <h2 className="visitEntryImage-name font-size-16 text-muted">
                                    {visitEntryImage.title}
                                  </h2>
                                  <p className="visitEntryImage-description">
                                    {visitEntryImage?.message?.length > 25
                                      ? `${visitEntryImage?.message?.slice(
                                          0,
                                          25
                                        )}...`
                                      : visitEntryImage?.message}
                                  </p>
                                </div>
                              </div>
                            </Col>
                          )
                        )}
                        {VisitEntryImage?.results?.length === 0 && (
                          <div className="d-flex align-items-center justify-content-center p-3">
                            <p>No VisitEntry Images</p>
                          </div>
                        )}
                      </Row>
                    )}

                    <MyPagination
                      pages={pages}
                      clcickedPage={page}
                      onNunClick={item => setPage(item)}
                      onNextClick={() => setPage(page + 1)}
                      onPrevClick={() => setPage(page - 1)}
                      onFastNextClick={() => setPage(pages.length)}
                      onFastPrevClick={() => setPage(1)}
                      apiPage={pageSend}
                    />
                  </CardBody>
                </Card>
              </Col>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default VisitEntryDetails
