import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Button, CardTitle } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { deleteSheet, getSheets, getSheetTotalWeight, updateSheet } from "store/actions"

import DeleteModal from "components/Common/DeleteModal"
import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"

const Sheets = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [isOpen, setIsOpen] = useState({ state: false, sheetId: "" })
  const [reaload, setReload] = useState(false)
  const [thickTab, setThickTab] = useState("all")
  const [widthTab, setWidthTab] = useState("all")

  const { sheets, sheets_total_weight, loading } = useSelector(state => ({
    sheets: state.Sheet.sheets,
    sheets_total_weight: state.Sheet.sheets_total_weight,
    loading: state.Sheet.loading,
  }))
  console.log(sheets_total_weight);

  const totalPages = Math.ceil(sheets?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getSheets(searchText,thickTab,widthTab, pageSend()))
    dispatch(getSheetTotalWeight(thickTab,widthTab))
  }, [dispatch, page,thickTab,widthTab, searchText])


  const handleReload = () => {
    dispatch(getSheets(searchText, pageSend()))
    setReload(false)
  }

  const handleDelete = sheetId => {
    setIsOpen({ ...isOpen, ["state"]: true, ["sheetId"]: sheetId })
  }

  const handleDeleteEvent = () => {
    dispatch(deleteSheet(isOpen.sheetId))
    setIsOpen({ ...isOpen, ["state"]: false })
    setReload(true)
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "thick",
      text: "Thickness",
      classes: (cell, row, rowIndex, colIndex) => {
        return `thick_${cell?.replace(".","_")}`;
      }
    },
    {
      dataField: "width",
      text: "Width",
      classes: (cell, row, rowIndex, colIndex) => {
        return `width_${cell?.replace(".","_")}`;
      }
    },
    {
      dataField: "height",
      text: "Length",
    },
    {
      dataField: "sqft",
      text: "Sqft",
    },
    {
      dataField: "unit_kg",
      text: "Kg/pc",
      
    },
    {
      dataField: "quantity",
      text: "Stock",
      classes: (cell, row, rowIndex, colIndex) => {
        if(cell<=50){
          return "stock_low";
        }else{
          return ""
        }
      }
    },
    {
      dataField: "total_weight",
      text: "Weight",
    },
    // {
    //   dataField: "unit_price",
    //   text: "Price",
    // },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  // const handleValidSubmit = (onSubmitProps, values, sheetId) => {
  //   dispatch(updateSheet(values, sheetId, "", "isUpdate"))
  // }

  const sheetData = map(sheets, (item, index) => ({
    ...item,
    key: index,
    update: (
      <AvForm
        className="form-horizontal "
        onValidSubmit={(onSubmitProps, v) => {
          handleValidSubmit(onSubmitProps, v, item.id)
        }}
      >
        <Row style={{ alignItems: "center" }} className="d-flex">
          <Col sm={2} lg={2} style={{ width: "100px" }}>
            <AvField
              id="horizontal-price-Input"
              name="stock"
              className="form-control"
              min={0}
              type="number"
              placeholder="Stock"
              required
            />
          </Col>
          <Col sm={2} lg={2} style={{ width: "100px" }}>
            <AvField
              id="horizontal-price-Input"
              name="price"
              min={0}
              className="form-control"
              placeholder="Price"
              type="number"
              required
            />
          </Col>
          <Col sm={2} lg={3} style={{ width: "100px" }}>
            <button
              type="submit"
              className="btn btn-sm btn-success btn-lg ms-2"
            >
              Save
            </button>
          </Col>
        </Row>
      </AvForm>
    ),
    action: (
      <div className="d-flex">
        <div>
          <Link
            to={`/sheet/update/${item?.id}`}
            className="btn btn-sm"
            title="Update"
          >
            <i className="bx bx-pen text-success font-size-15"></i>
          </Link>
        </div>
        <div title="Remove">
          <i
            style={{ cursor: "pointer" }}
            className="bx bx-trash text-danger font-size-15 px-4"
            onClick={() => handleDelete(item?.id)}
          ></i>
        </div>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen.state}
        onCloseClick={() => setIsOpen({ ...isOpen, ["state"]: false })}
        onDeleteClick={handleDeleteEvent}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>

              <CardTitle className="h4 mb-4">
                <Button color={thickTab=="all" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("all")}>All</Button>
                <Button color={thickTab=="1.5" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("1.5")}>1.5 mm</Button>
                <Button color={thickTab=="1.2" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("1.2")}>1.2 mm</Button>
                <Button color={thickTab=="1" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("1")}>1.0 mm</Button>
                <Button color={thickTab=="2" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("2")}>2.0 mm</Button>
              </CardTitle>
              <CardTitle className="h4 mb-4">
                <Button color={widthTab=="all" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("all")}>All</Button>
                <Button color={widthTab=="1220.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1220.00")}>1220</Button>
                <Button color={widthTab=="1225.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1225.00")}>1225</Button>
                <Button color={widthTab=="1250.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1250.00")}>1250</Button>
                <Button color={widthTab=="1500.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1500.00")}>1500</Button>
                <Button color={widthTab=="900.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("900.00")}>900</Button>
              </CardTitle>

              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={sheetData}
                search 
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="8">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>{" "}
                          </div>
                        </div>
                      </Col>
                      {reaload == true && (
                        <Col
                          md="4 d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "right",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <div className="text-sm-end">
                                <a
                                  href="#dailywork"
                                  type="button"
                                  className="btn-light btn btn-rounded m-0"
                                  onClick={() => handleReload()}
                                >
                                  Refresh
                                </a>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                      <Col
                        md="4 d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "right",
                        }}
                      >
                        <div>
                          <h5 className="text-end">Total weight : <span className="text-secondery text-primary fw-bold">{sheets_total_weight}</span> kg</h5>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Sheets
