import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { createVisitEntry, getFieldStaffs } from "store/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useDispatch, useSelector } from "react-redux"
import Form from "./Form"
import moment from "moment"

function Create() {
  const history = useHistory()
  const dispatch = useDispatch()

  const [selectedFieldStaff, setSelectedFieldStaff] = useState(
    "Select FieldStaff..."
  )
  const [selectedFieldStaffId, setSelectedFieldStaffId] = useState("")
  const [selectedFieldStaffSearch, setSelectedFieldStaffSearch] = useState("")
  const [statusValue, setStatusValue] = useState()

  const { allVisitEntries, allFieldStaffs, visitEntryDetail, loading } =
    useSelector(state => ({
      allVisitEntries: state?.VisitEntry?.visitentrys,
      allFieldStaffs: state?.FieldStaff?.fieldstaffs,
      visitEntryDetail: state.VisitEntry.visitentryDetails,
      loading: state?.VisitEntry?.loading,
    }))
  const fieldSiteId = localStorage.getItem("fieldSiteId")

  const handleValidSubmit = v => {
    const checkIn1 = v?.location.check_in_location[0]
    const checkIn2 = v?.location.check_in_location[1]

    const checkOut1 = v?.location.check_out_location[0]
    const checkOut2 = v?.location.check_out_location[1]

    const createValues = {
      // field_staff: selectedFieldStaffId,
      fieldsite: fieldSiteId,
      check_in_date: moment(v?.check_in_date).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
      ),
      check_out_date: moment(v?.check_out_date).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
      ),
      check_in_location: `(${checkIn1},${checkIn2})`,
      check_out_location: `(${checkOut1},${checkOut2})`,
      status: statusValue,
    }

    console.log(createValues)
    dispatch(createVisitEntry(createValues, history))
  }
  useEffect(() => {
    dispatch(getFieldStaffs("", "", "", selectedFieldStaffSearch))
  }, [dispatch, selectedFieldStaffSearch])

  const stateValues = {
    selectedFieldStaff,
    setSelectedFieldStaff,
    selectedFieldStaffId,
    setSelectedFieldStaffId,
    selectedFieldStaffSearch,
    setSelectedFieldStaffSearch,
    statusValue,
    setStatusValue,
  }

  console.log("stateValues=>", stateValues)

  return (
    <div>
      <MetaTags>
        <title>Visit Entry | Loha </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs title="Visit Entry" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row>
                <Col md="8" lg="8">
                  <Card>
                    <CardBody>
                      <Form states={stateValues} />
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          type="submit"
                          color="success"
                          className="w-md mx-1"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Create
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default Create
