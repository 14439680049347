import {
  GET_FIELDSITES,
  GET_FIELDSITES_SUCCESS,
  GET_FIELDSITES_FAIL,
  GET_FIELDSITE_DETAILS,
  GET_FIELDSITE_DETAILS_SUCCESS,
  GET_FIELDSITE_DETAILS_FAIL,
  CREATE_FIELDSITE,
  CREATE_FIELDSITE_SUCCESS,
  CREATE_FIELDSITE_FAIL,
  UPDATE_FIELDSITE,
  UPDATE_FIELDSITE_SUCCESS,
  UPDATE_FIELDSITE_FAIL,
  DELETE_FIELDSITE_SUCCESS,
  DELETE_FIELDSITE_FAIL,
  DELETE_FIELDSITE,
  GET_GMAP_PKEY_SUCCESS,
  GET_GMAP_PKEY_FAIL,
  SEARCH_LOCATION,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION_FAIL,
  GET_GMAP_PKEY,
} from "./actionTypes"

// FieldSite - This line cannot be edited or removed
export const getFieldSites = (page, sort, limit, searchText) => ({
  type: GET_FIELDSITES,
  payload: { page, sort, limit, searchText },
})

export const getFieldSitesSuccess = fieldsites => ({
  type: GET_FIELDSITES_SUCCESS,
  payload: fieldsites,
})

export const getFieldSitesFail = error => ({
  type: GET_FIELDSITES_FAIL,
  payload: error,
})

export const getFieldSiteDetails = fieldsiteId => ({
  type: GET_FIELDSITE_DETAILS,
  payload: fieldsiteId,
})

export const getFieldSiteDetailsSuccess = fieldsiteDetails => ({
  type: GET_FIELDSITE_DETAILS_SUCCESS,
  payload: fieldsiteDetails,
})

export const getFieldSiteDetailsFail = error => ({
  type: GET_FIELDSITE_DETAILS_FAIL,
  payload: error,
})

export const createFieldSite = (fieldsite, history) => ({
  type: CREATE_FIELDSITE,
  payload: { fieldsite, history },
})

export const createFieldSiteSuccess = fieldsite => ({
  type: CREATE_FIELDSITE_SUCCESS,
  payload: fieldsite,
})

export const createFieldSiteFail = error => ({
  type: CREATE_FIELDSITE_FAIL,
  payload: error,
})

export const getFieldSiteDetail = fieldsiteId => ({
  type: GET_FIELDSITE_DETAILS,
  payload: fieldsiteId,
})

export const updateFieldSite = (fieldsite, fieldsiteId, history) => ({
  type: UPDATE_FIELDSITE,
  payload: { fieldsite, fieldsiteId, history },
})

export const updateFieldSiteSuccess = fieldsite => ({
  type: UPDATE_FIELDSITE_SUCCESS,
  payload: fieldsite,
})

export const updateFieldSiteFail = error => ({
  type: UPDATE_FIELDSITE_FAIL,
  payload: error,
})

export const deleteFieldSite = (fieldsiteId, history, onCloseClick) => (
  console.log(fieldsiteId),
  {
    type: DELETE_FIELDSITE,
    payload: { fieldsiteId, history, onCloseClick },
  }
)

export const deleteFieldSiteSuccess = fieldsite => ({
  type: DELETE_FIELDSITE_SUCCESS,
  payload: fieldsite,
})

export const deleteFieldSiteFail = error => ({
  type: DELETE_FIELDSITE_FAIL,
  payload: error,
})
// map

export const getMapPublicKey = () => ({
  type: GET_GMAP_PKEY,
})

export const getMapPublicKeySuccess = pKey => ({
  type: GET_GMAP_PKEY_SUCCESS,
  payload: pKey,
})

export const getMapPublicKeyFail = error => ({
  type: GET_GMAP_PKEY_FAIL,
  payload: error,
})

export const searchPlaces = query => ({
  type: SEARCH_LOCATION,
  payload: query,
})

export const searchPlacesSuccess = places => ({
  type: SEARCH_LOCATION_SUCCESS,
  payload: places,
})

export const searchPlacesFail = error => ({
  type: SEARCH_LOCATION_FAIL,
  payload: error,
})
