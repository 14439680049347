import React from "react"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Container } from "reactstrap"
import Fields from "./DatatableTables"

function AllFields() {
  return (
    <div>
      <MetaTags>
        <title>Field App | Loha </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs title="Field App" breadcrumbItem="All Field App " />
        <Container fluid>
          <div className="container-fluid">
            <Fields />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AllFields
