import {
  GET_FIELDSTAFFS,
  GET_FIELDSTAFFS_SUCCESS,
  GET_FIELDSTAFFS_FAIL,
  GET_FIELDSTAFF_DETAILS,
  GET_FIELDSTAFF_DETAILS_SUCCESS,
  GET_FIELDSTAFF_DETAILS_FAIL,
  CREATE_FIELDSTAFF,
  CREATE_FIELDSTAFF_SUCCESS,
  CREATE_FIELDSTAFF_FAIL,
  UPDATE_FIELDSTAFF,
  UPDATE_FIELDSTAFF_SUCCESS,
  UPDATE_FIELDSTAFF_FAIL,
  DELETE_FIELDSTAFF,
  DELETE_FIELDSTAFF_SUCCESS,
  DELETE_FIELDSTAFF_FAIL,
  GET_ALL_FIELDS_COUNT,
  GET_ALL_FIELDS_COUNT_FAIL,
  GET_ALL_FIELDS_COUNT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  fieldstaffs: {
    fieldstaffs: [],
    total: "",
    page: "",
  },
  fieldstaffDetails: {},
  error: {},
  loading: false,
  fieldsCount: {},
}

const FieldStaff = (state = INIT_STATE, action) => {
  switch (action.type) {
    // FieldStaff - This line cannot be edited or removed
    case GET_FIELDSTAFFS:
    case GET_FIELDSTAFF_DETAILS:
    case CREATE_FIELDSTAFF:
    case UPDATE_FIELDSTAFF:
    case DELETE_FIELDSTAFF:
    case GET_ALL_FIELDS_COUNT:
      return {
        ...state,
        loading: true,
      }

    case DELETE_FIELDSTAFF_FAIL:
    case GET_FIELDSTAFFS_FAIL:
    case GET_FIELDSTAFF_DETAILS_FAIL:
    case UPDATE_FIELDSTAFF_FAIL:
    case CREATE_FIELDSTAFF_FAIL:
    case GET_ALL_FIELDS_COUNT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FIELDSTAFFS_SUCCESS:
      return {
        ...state,
        fieldstaffs: action.payload,
        error: {},
        loading: false,
      }

    case GET_FIELDSTAFF_DETAILS_SUCCESS:
      return {
        ...state,
        fieldstaffDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_FIELDSTAFF_SUCCESS:
      console.log(state)
      return {
        ...state,
        fieldstaffs: {
          ...state?.fieldstaffs,
          results: [action?.payload, ...state?.fieldstaffs?.results],
          total: state.fieldstaffs?.count + 1,
        },
        fieldstaffDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_FIELDSTAFF_SUCCESS:
      return {
        ...state,
        fieldstaffs: {
          ...state.fieldstaffs,
          results: state.fieldstaffs.results?.map(fieldstaff =>
            fieldstaff?.id === action?.payload?.id
              ? { ...fieldstaff, ...action.payload }
              : fieldstaff
          ),
        },
        fieldstaffDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_FIELDSTAFF_SUCCESS:
      console.log("action.payload => ", action?.payload)
      console.log("state?.fieldstaffs?.results =>", state?.fieldstaffs?.results)
      return {
        ...state,
        fieldstaffs: {
          ...state.fieldstaffs,
          results: state?.fieldstaffs?.results?.filter(
            fieldstaff => fieldstaff?.id !== action?.payload
          ),
        },
        error: {},
        loading: false,
      }

    case GET_ALL_FIELDS_COUNT_SUCCESS:
      return {
        ...state,
        fieldsCount: action.payload,
        error: {},
        loading: false,
      }

    default:
      return state
  }
}

export default FieldStaff
