import PDFGenerator from "components/Common/Pdf"
import React from "react"
import { Card, CardBody } from "reactstrap"

//ations

function OrderItems() {
  // const dispatch = useDispatch()
  // const params = useParams()

  // useEffect(() => {
  //   // dispatch(getOrderDetail(params.id))
  // }, [dispatch])

  return (
    <>
      <Card className="print-boxshadow">
        <CardBody>
          <PDFGenerator />
        </CardBody>
      </Card>
    </>
  )
}

export default OrderItems
