import React, { useState, useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import {
  Card,
  CardBody,
  Media,
  Spinner,
  Col,
  Container,
  Row,
  Button,
  Badge,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"

//componetns
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Images
import userProfile from "assets/images/logo/user.png"

//actions
import {
  deleteFieldStaff,
  deleteSheetUser,
  getFieldStaffDetails,
  getSheetUserDetail,
  getVisitEntrys,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import avatar1 from "../../../assets/images/logo/user.png"
import Form from "../Crud/Form"
import MyPagination from "components/Common/MyPagination"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { map, range } from "lodash"
import moment from "moment"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const FieldStaffDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { detailLoading, loading, details, allVisitEntries } = useSelector(
    state => ({
      loading: state.VisitEntry.loading,
      details: state.FieldStaff.fieldstaffDetails,
      allVisitEntries: state.VisitEntry.visitentrys,
    })
  )
  const [isOpenForm, setIsOpenForm] = useState({ status: false, data: "" })

  const [isOpen, setIsOpen] = useState(false)
  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const handleDelete = () => {
    setIsOpen(true)
  }
  const handleDeleteEvent = () => {
    dispatch(deleteFieldStaff(params?.id, onCloseClick, history))
    setIsOpen({ ...isOpen, status: false })
  }
  useEffect(() => {
    dispatch(getFieldStaffDetails(params.id))
  }, [dispatch, params?.id])

  const onCloseClick = () => {
    setIsOpen({ state: false })
  }

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }
  console.log(handleDateSearch())

  const totalPages = Math.ceil(allVisitEntries?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(
      getVisitEntrys(
        pageSend(),
        "",
        10,
        searchText,
        params?.id,
        handleDateSearch()
      )
    )
  }, [dispatch, page, searchText, finalDateFormated])

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  const handleStatusColor = color => {
    switch (color) {
      case "Pending":
        return "warning"
      case "Completed":
        return "success"
      case "In Progess":
        return "primary"
    }
  }
  const columns = [
    {
      dataField: "checkIn",
      text: "Check In",
      sort: true,
    },
    {
      dataField: "check_in_location",
      text: "Check In Location",
      sort: true,
    },
    {
      dataField: "checkOut",
      text: "Name",
      sort: true,
    },

    {
      dataField: "check_out_location",
      text: "Check Out Location",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
  ]

  const visitEntryData = map(allVisitEntries?.results, (item, index) => ({
    ...item,
    key: index,
    checkIn:
      item?.check_in_date && moment(item?.check_in_date).format("DD/MM/YYYY"),
    checkOut:
      item?.check_out_date && moment(item?.check_out_date).format("DD/MM/YYYY"),
    status: (
      <Badge
        className={
          "font-size-12 badge-soft-" + `${handleStatusColor(item?.status)}`
        }
        pill
      >
        {item?.status}
      </Badge>
    ),
    action: (
      <Link
        to={{
          pathname: `/visit-entry/${item?.id}`,
          state: "visitEntry",
        }}
      >
        View Details
      </Link>
    ),
  }))

  return (
    <>
      <DeleteModal
        show={isOpen}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <Form
        show={isOpenForm?.status}
        onCloseClick={() => setIsOpenForm({ ...isOpenForm, status: false })}
        data={isOpenForm?.data}
      />

      <MetaTags>
        <title>Field Staff Details | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs
          title="Field Staff Details"
          breadcrumbItem="Field Staff Details"
        />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="12">
                <Row>
                  <Col lg="6">
                    <Card>
                      <CardBody>
                        <Row>
                          <Col lg="12">
                            <Media>
                              <div className="me-3">
                                <img
                                  src={avatar1}
                                  alt=""
                                  className="avatar-md rounded-circle img-thumbnail"
                                />
                              </div>
                              <Media className="align-self-center" body>
                                <div className="text-muted">
                                  <h5 className="mb-1">
                                    {" "}
                                    {details?.full_name}
                                  </h5>
                                  <p className="mb-0">{details?.username}</p>
                                  <p className="mb-0">{details?.email}</p>
                                  <p className="mb-0">{details?.phone}</p>
                                </div>
                              </Media>
                            </Media>
                          </Col>
                          <div className="d-flex align-items-center justify-content-end">
                            <Button
                              color="danger"
                              onClick={() => handleDelete(details?.id)}
                            >
                              delete
                            </Button>
                            <Button
                              className="mx-1"
                              color="success"
                              onClick={() =>
                                setIsOpenForm({ status: true, data: details })
                              }
                            >
                              update
                            </Button>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <p className="text-muted h6"> Visit Entries</p>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={visitEntryData}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="3">
                              <div className="search-box me-2 mb-0 d-inline-block">
                                <div className="position-relative">
                                  <form
                                    className="app-search d-lg-block"
                                    onChange={e => handleSearch(e)}
                                  >
                                    <label></label>
                                    <div className="position-relative">
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search"
                                        defaultValue={searchText}
                                      />
                                      <span className="bx bx-search-alt" />
                                    </div>
                                  </form>{" "}
                                </div>
                              </div>
                            </Col>

                            <Col md="6">
                              {" "}
                              <AvForm style={{ marginTop: 8 }}>
                                <span className="mt-1">Sort By Date</span>
                                <div className={`d-flex date_wrapper `}>
                                  <div className="d-flex align-items-start position-relative">
                                    <AvField
                                      style={{ width: "260px" }}
                                      name="dateRange"
                                      type="date"
                                      validate={{
                                        dateRange: {
                                          start: { value: -1, units: "years" },
                                          end: { value: 1, units: "years" },
                                        },
                                      }}
                                      onChange={e =>
                                        setFinalDateFormated({
                                          ...finalDateFormated,
                                          from: e.target.value,
                                        })
                                      }
                                      value={finalDateFormated?.from}
                                    />
                                    <button
                                      onClick={() =>
                                        setFinalDateFormated({
                                          ...finalDateFormated,
                                          from: "",
                                        })
                                      }
                                      style={{
                                        position: "absolute",
                                        left: 202,
                                        bottom: 3,
                                      }}
                                      type="button"
                                      className="btn btn-white editable-cancel date_close btn-sm"
                                    >
                                      <i className="mdi mdi-close text-danger "></i>
                                    </button>
                                  </div>
                                  <div className="d-flex align-items-start position-relative">
                                    <AvField
                                      name="dateRange"
                                      className="mx-1"
                                      type="date"
                                      style={{ width: "260px" }}
                                      validate={{
                                        dateRange: {
                                          start: { value: -1, units: "years" },
                                          end: { value: 1, units: "years" },
                                        },
                                      }}
                                      onChange={e =>
                                        setFinalDateFormated({
                                          ...finalDateFormated,
                                          to: e.target.value,
                                        })
                                      }
                                      value={finalDateFormated?.to}
                                    />
                                    <button
                                      onClick={() =>
                                        setFinalDateFormated({
                                          ...finalDateFormated,
                                          to: "",
                                        })
                                      }
                                      style={{
                                        position: "absolute",
                                        left: 200,
                                        bottom: 3,
                                      }}
                                      type="button"
                                      className="btn btn-white editable-cancel date_close btn-sm"
                                    >
                                      <i className="mdi mdi-close text-danger "></i>
                                    </button>
                                  </div>
                                </div>
                              </AvForm>
                            </Col>
                          </Row>
                          {loading ? (
                            <Spinner
                              color="secondary"
                              className="d-block m-auto"
                            />
                          ) : allVisitEntries?.count > 0 ? (
                            <>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      // defaultSorted={defaultSorted}
                                      // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <MyPagination
                                pages={pages}
                                clcickedPage={page}
                                onNunClick={item => setPage(item)}
                                onNextClick={() => setPage(page + 1)}
                                onPrevClick={() => setPage(page - 1)}
                                onFastNextClick={() => setPage(pages.length)}
                                onFastPrevClick={() => setPage(1)}
                                apiPage={pageSend}
                              />
                            </>
                          ) : (
                            <p>No Data</p>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default FieldStaffDetails

FieldStaffDetails.propTypes = {
  history: PropTypes.object,
}
