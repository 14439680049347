import {
  GET_SHEETS,
  GET_SHEETS_SUCCESS,
  GET_SHEETS_FAIL,
  GET_SHEET_DETAIL,
  CREATE_SHEET,
  UPDATE_SHEET,
  DELETE_SHEET,
  GET_SHEET_DETAIL_SUCCESS,
  GET_SHEET_DETAIL_FAIL,
  CREATE_SHEET_SUCCESS,
  CREATE_SHEET_FAIL,
  UPDATE_SHEET_SUCCESS,
  UPDATE_SHEET_FAIL,
  DELETE_SHEET_SUCCESS,
  DELETE_SHEET_FAIL,

  GET_SHEET_ORDERS,
  GET_SHEET_ORDERS_SUCCESS,
  GET_SHEET_ORDERS_FAIL,
  GET_SHEET_ORDER_DETAIL,
  CREATE_SHEET_ORDER,
  UPDATE_SHEET_ORDER,
  DELETE_SHEET_ORDER,
  GET_SHEET_ORDER_DETAIL_SUCCESS,
  GET_SHEET_ORDER_DETAIL_FAIL,
  CREATE_SHEET_ORDER_SUCCESS,
  CREATE_SHEET_ORDER_FAIL,
  UPDATE_SHEET_ORDER_SUCCESS,
  UPDATE_SHEET_ORDER_FAIL,
  DELETE_SHEET_ORDER_SUCCESS,
  DELETE_SHEET_ORDER_FAIL,
  ADD_SHEET_QUANTITY,
  UPDATE_ORDER_ITEM_QUANTITY,
  ORDER_DELIVERY_CHANGE,
  GET_SHEET_TOTAL_WEIGHT,
  GET_SHEET_TOTAL_WEIGHT_SUCCESS,
  GET_SHEET_TOTAL_WEIGHT_FAIL,
  GET_SHEET_ORDERS_WEIGHT,
  GET_SHEET_ORDERS_WEIGHT_SUCCESS,
  GET_SHEET_ORDERS_WEIGHT_FAIL,

} from "./actionTypes"

const INIT_STATE = {
  sheets: [],
  error: {},
  loading: false,
  createSheeterror: null,
  total_weight: 0,
  sheetOrdersWeight: {},

}

const Sheet = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHEETS:
    case GET_SHEET_DETAIL:
    case CREATE_SHEET:
    case UPDATE_SHEET:
    case DELETE_SHEET:
    case GET_SHEET_TOTAL_WEIGHT:
    case GET_SHEET_ORDERS:
    case GET_SHEET_ORDER_DETAIL:
    case CREATE_SHEET_ORDER:
    case UPDATE_SHEET_ORDER:
    case DELETE_SHEET_ORDER:
    case GET_SHEET_ORDERS_WEIGHT:

      return {
        ...state,
        loading: true,
      }
    case GET_SHEETS_SUCCESS:
      console.log(action.payload);
      let sheets = action.payload.payload
      // if (action.payload.thick) {
      //   sheets = action.payload.payload.filter(
      //     i => i.thick.toString() == action.payload.thick.toString()
      //   )
      // }
      return {
        ...state,
        sheets: sheets,
        loading: false,
      }

    case GET_SHEETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SHEET_DETAIL_SUCCESS:
      return {
        ...state,
        sheetDetail: action.payload,
        loading: false,
      }

    case GET_SHEET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SHEET_SUCCESS:
      return {
        ...state,
        sheets: action.payload,
        loading: false,
      }

    case CREATE_SHEET_FAIL:
      return {
        ...state,
        createSheeterror: action.payload,
        loading: false,
      }

    case UPDATE_SHEET_SUCCESS:
      return {
        ...state,
        sheets: action.payload,
        loading: false,
      }

    case UPDATE_SHEET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case GET_SHEET_TOTAL_WEIGHT_SUCCESS:
      return {
        ...state,
        sheets_total_weight: action.payload,
        loading: false,
      }

    case GET_SHEET_TOTAL_WEIGHT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case DELETE_SHEET_SUCCESS:
      return {
        ...state,
        sheets: state.sheets.filter(
          i => i.id.toString() !== action.payload.id.toString()
        ),
        loading: false,
      }

    case DELETE_SHEET_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }



    case GET_SHEET_ORDERS_SUCCESS:
      return {
        ...state,
        sheetOrders: action.payload,
        loading: false,
      }

    case GET_SHEET_ORDERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SHEET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        sheetOrderDetail: action.payload,
        loading: false,
      }

    case GET_SHEET_ORDER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case CREATE_SHEET_ORDER_SUCCESS:
      return {
        ...state,
        sheetOrderDetail: action.payload,
        loading: false,
      }

    case CREATE_SHEET_ORDER_FAIL:
      return {
        ...state,
        createSheeterror: action.payload,
        loading: false,
      }

    case UPDATE_SHEET_ORDER_SUCCESS:
      return {
        ...state,
        sheetOrderDetail: action.payload,
        loading: false,
      }

    case UPDATE_SHEET_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }


    case DELETE_SHEET_ORDER_SUCCESS:
      var sheetOrders = state.sheetOrders
      sheetOrders.results = state.sheetOrders.results.filter(
        i => i.id !== action.payload.id
      )
      return {
        ...state,
        sheetOrders: sheetOrders,
        loading: false,
      }



    case DELETE_SHEET_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SHEET_ORDERS_WEIGHT_SUCCESS:
      console.log(action.payload);
      return {
        ...state,
        sheetOrdersWeight: action.payload,
        loading: false,
      }

    case GET_SHEET_ORDERS_WEIGHT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

        

    case ADD_SHEET_QUANTITY:
      var total_weight = 0
      var new_sheets = state.sheets
      new_sheets = state.sheets.filter((e) => {
        if (e.id == action.payload.sheet_id) {
          if (!action.payload.qty) {
            action.payload.qty = 0
          }
          e["qty"] = parseFloat(action.payload.qty)
          e["weight"] = (parseFloat(action.payload.qty) * parseFloat(e.unit_kg)).toFixed(2)
        }
        if (e["weight"] != null) {
          total_weight = (parseFloat(total_weight) + parseFloat(e["weight"])).toFixed(2)
        }
        return e
      })
      return {
        ...state,
        sheets: new_sheets,
        total_weight: total_weight,
        loading: false,
      }




    case UPDATE_ORDER_ITEM_QUANTITY:
      var total_weight = 0
      var new_order = state.sheetOrderDetail
      new_order.order_items = state.sheetOrderDetail.order_items.filter((e) => {
        if (e.id == action.payload.id) {
          if (!action.payload.qty) {
            action.payload.qty = 0
          }
          e["qty"] = parseFloat(action.payload.qty)
          e["quantity"] = parseFloat(action.payload.qty)
          e["weight"] = (parseFloat(action.payload.qty) * parseFloat(e.unit_kg)).toFixed(2);
        }
        if (e["weight"] != null) {
          console.log(typeof(total_weight));
          total_weight = (parseFloat(total_weight) + parseFloat(e["weight"])).toFixed(2)
        }
        return e
      })
      new_order.total_weight = total_weight
      return {
        ...state,
        total_weight: total_weight,
        sheetOrderDetail: new_order,
        loading: false
      }


    case ORDER_DELIVERY_CHANGE:
      var new_order = state.sheetOrderDetail
      new_order.is_delivered = action.payload.val
      console.log(action.payload);
      return {
        ...state,
        sheetOrderDetail: new_order,
        loading: false
      }

    default:
      return state
  }
}

export default Sheet


// 'id','name','thick','width',
// 'height','sqft','unit_kg','quantity'
// ,'unit_price'
// auto_id','title','date','is_delivered