/* STOREITEMS */
export const GET_SHEETS = "GET_SHEETS"
export const GET_SHEETS_SUCCESS = "GET_SHEETS_SUCCESS"
export const GET_SHEETS_FAIL = "GET_SHEETS_FAIL"

/* STOREITEMS DETAIL*/
export const GET_SHEET_DETAIL = "GET_SHEET_DETAIL"
export const GET_SHEET_DETAIL_SUCCESS = "GET_SHEET_DETAIL_SUCCESS"
export const GET_SHEET_DETAIL_FAIL = "GET_SHEET_DETAIL_FAIL"

/**
 * add SHEET
 */
export const CREATE_SHEET = "CREATE_SHEET"
export const CREATE_SHEET_SUCCESS = "CREATE_SHEET_SUCCESS"
export const CREATE_SHEET_FAIL = "CREATE_SHEET_FAIL"

/**
 * Edit SHEET
 */
export const UPDATE_SHEET = "UPDATE_SHEET"
export const UPDATE_SHEET_SUCCESS = "UPDATE_SHEET_SUCCESS"
export const UPDATE_SHEET_FAIL = "UPDATE_SHEET_FAIL"

/**
 * Delete SHEET
 */
export const DELETE_SHEET = "DELETE_SHEET"
export const DELETE_SHEET_SUCCESS = "DELETE_SHEET_SUCCESS"
export const DELETE_SHEET_FAIL = "DELETE_SHEET_FAIL"

export const GET_SHEET_TOTAL_WEIGHT = "GET_SHEET_TOTAL_WEIGHT"
export const GET_SHEET_TOTAL_WEIGHT_SUCCESS = "GET_SHEET_TOTAL_WEIGHT_SUCCESS"
export const GET_SHEET_TOTAL_WEIGHT_FAIL = "GET_SHEET_TOTAL_WEIGHT_FAIL"


/* STOREITEMS */
export const GET_SHEET_ORDERS = "GET_SHEET_ORDERS"
export const GET_SHEET_ORDERS_SUCCESS = "GET_SHEET_ORDERS_SUCCESS"
export const GET_SHEET_ORDERS_FAIL = "GET_SHEET_ORDERS_FAIL"




/* STOREITEMS DETAIL*/
export const GET_SHEET_ORDER_DETAIL = "GET_SHEET_ORDER_DETAIL"
export const GET_SHEET_ORDER_DETAIL_SUCCESS = "GET_SHEET_ORDER_DETAIL_SUCCESS"
export const GET_SHEET_ORDER_DETAIL_FAIL = "GET_SHEET_ORDER_DETAIL_FAIL"

/**
 * add SHEET_ORDER
 */
export const CREATE_SHEET_ORDER = "CREATE_SHEET_ORDER"
export const CREATE_SHEET_ORDER_SUCCESS = "CREATE_SHEET_ORDER_SUCCESS"
export const CREATE_SHEET_ORDER_FAIL = "CREATE_SHEET_ORDER_FAIL"

/**
 * Edit SHEET_ORDER
 */
export const UPDATE_SHEET_ORDER = "UPDATE_SHEET_ORDER"
export const UPDATE_SHEET_ORDER_SUCCESS = "UPDATE_SHEET_ORDER_SUCCESS"
export const UPDATE_SHEET_ORDER_FAIL = "UPDATE_SHEET_ORDER_FAIL"

/**
 * Delete SHEET_ORDER
 */
export const DELETE_SHEET_ORDER = "DELETE_SHEET_ORDER"
export const DELETE_SHEET_ORDER_SUCCESS = "DELETE_SHEET_ORDER_SUCCESS"
export const DELETE_SHEET_ORDER_FAIL = "DELETE_SHEET_ORDER_FAIL"


export const GET_SHEET_ORDERS_WEIGHT = "GET_SHEET_ORDERS_WEIGHT"
export const GET_SHEET_ORDERS_WEIGHT_SUCCESS = "GET_SHEET_ORDERS_WEIGHT_SUCCESS"
export const GET_SHEET_ORDERS_WEIGHT_FAIL = "GET_SHEET_ORDERS_WEIGHT_FAIL"


export const ADD_SHEET_QUANTITY = "ADD_SHEET_QUANTITY"
export const UPDATE_ORDER_ITEM_QUANTITY = "UPDATE_ORDER_ITEM_QUANTITY"
export const ORDER_DELIVERY_CHANGE = "ORDER_DELIVERY_CHANGE"



