function sidebardata() {
  return [
    {
      heading: "Orders",
      badgeClass: "danger",
      badgeValue: "",
      iconClass: "cart-alt",
      subTitles: [
        { title: "All Orders", routeLink: "/orders" },
        {
          title: "Create Order",
          routeLink: "/order/create",
        },
      ],
    },
    {
      heading: "Quality Check",
      badgeClass: "",
      badgeValue: "",
      iconClass: "shield",
      subTitles: [
        {
          title: "Tested Products",
          routeLink: "/product/finished",
        },
        {
          title: "Checked New Product",
          routeLink: "/product/finished?create",
        },
      ],
    },

    {
      heading: "Store",
      badgeClass: "",
      badgeValue: "",
      iconClass: "store",
      subTitles: [
        {
          title: "Store Supply",
          routeLink: "/store",
        },
        { title: "All Store Items", routeLink: "/stores" },
        {
          title: "Create Store Item",
          routeLink: "/store/create",
        },
      ],
    },
    {
      heading: "Sheets",
      badgeClass: "",
      badgeValue: "",
      iconClass: "layer",
      subTitles: [
        { title: "Sheet orders", routeLink: "/sheets/sheetorders" },
        {
          title: "Create Sheet order",
          routeLink: "/sheets/sheetorders/create",
        },
        {
          title: "Sheets",
          routeLink: "/sheets",
        },

        {
          title: "Create Sheet",
          routeLink: "/sheet/create",
        },
      ],
    },
    {
      heading: "Product",
      badgeClass: "",
      badgeValue: "",
      iconClass: "shopping-bags",
      subTitles: [
        { title: "All Products", routeLink: "/products" },
        {
          title: "Create Product",
          routeLink: "/product/create",
        },
      ],
    },
    {
      heading: "Work Schedules",
      badgeClass: "",
      badgeValue: "",
      iconClass: "notepad",
      subTitles: [
        { title: "All Schedules", routeLink: "/supervisor/schedules" },
      ],
    },
    {
      heading: "Field App",
      badgeClass: "",
      badgeValue: "",
      iconClass: "user-circle",
      link: "/fields",
      isHeading: true,
      // subTitles: [
      //   { title: "Field Staff", routeLink: "/fieldstaff" },
      //   { title: "Field Site", routeLink: "/fieldsite" },
      //   { title: "All Visit Entries", routeLink: "/visit-entry" },
      // ],
    },

    {
      heading: "",
      headingSaparetor: "Staff",
      badgeClass: "",
      badgeValue: "",
      iconClass: "",
      subTitles: [{ title: "", routeLink: "" }],
    },
    {
      heading: "Production Manager",
      badgeClass: "",
      badgeValue: "",
      iconClass: "user",
      subTitles: [
        { title: "All Production Managers", routeLink: "/productionmanagers" },
        {
          title: "Create Production Manager",
          routeLink: "/productionmanager/create",
        },
      ],
    },

    {
      heading: "Store Manager",
      badgeClass: "",
      badgeValue: "",
      iconClass: "user",
      subTitles: [
        { title: "All Store Manager", routeLink: "/storemanagers" },
        {
          title: "Create Store Manager",
          routeLink: "/storemanager/create",
        },
      ],
    },
    {
      heading: "Sheet User",
      badgeClass: "",
      badgeValue: "",
      iconClass: "user",
      subTitles: [
        { title: "All Sheet User", routeLink: "/sheetusers" },
        {
          title: "Create Sheet User",
          routeLink: "/sheetuser/create",
        },
      ],
    },
    {
      heading: "Supervisor",
      badgeClass: "",
      badgeValue: "",
      iconClass: "user-detail",
      subTitles: [
        { title: "All Supervisor", routeLink: "/supervisors" },
        { title: "Daily Works", routeLink: "/supervisor/dailywork" },
        {
          title: "Create Supervisor",
          routeLink: "/supervisor/create",
        },
      ],
    },
    {
      heading: "Dealers / Customer",
      badgeClass: "",
      badgeValue: "",
      iconClass: "analyse",
      subTitles: [
        { title: "All Dealers", routeLink: "/dealers" },
        {
          title: "Create Dealers",
          routeLink: "/dealer/create",
        },
      ],
    },
    {
      heading: "Quality Checker",
      badgeClass: "",
      badgeValue: "",
      iconClass: "user-detail",
      subTitles: [
        { title: "All Quality Checker", routeLink: "/qualitycheckers" },
        {
          title: "Create Quality Checker",
          routeLink: "/qualitychecker/create",
        },
      ],
    },
  ]
}

export const mySideBar = sidebardata()
