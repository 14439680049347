import {
  GET_FIELDSTAFFS,
  GET_FIELDSTAFFS_SUCCESS,
  GET_FIELDSTAFFS_FAIL,
  GET_FIELDSTAFF_DETAILS,
  GET_FIELDSTAFF_DETAILS_SUCCESS,
  GET_FIELDSTAFF_DETAILS_FAIL,
  CREATE_FIELDSTAFF,
  CREATE_FIELDSTAFF_SUCCESS,
  CREATE_FIELDSTAFF_FAIL,
  UPDATE_FIELDSTAFF,
  UPDATE_FIELDSTAFF_SUCCESS,
  UPDATE_FIELDSTAFF_FAIL,
  DELETE_FIELDSTAFF_SUCCESS,
  DELETE_FIELDSTAFF_FAIL,
  DELETE_FIELDSTAFF,
  GET_ALL_FIELDS_COUNT,
  GET_ALL_FIELDS_COUNT_SUCCESS,
  GET_ALL_FIELDS_COUNT_FAIL,
} from "./actionTypes"

// FieldStaff - This line cannot be edited or removed
export const getFieldStaffs = (page, sort, limit, searchText) => ({
  type: GET_FIELDSTAFFS,
  payload: { page, sort, limit, searchText },
})

export const getFieldStaffsSuccess = fieldstaffs => ({
  type: GET_FIELDSTAFFS_SUCCESS,
  payload: fieldstaffs,
})

export const getFieldStaffsFail = error => ({
  type: GET_FIELDSTAFFS_FAIL,
  payload: error,
})

export const getFieldStaffDetails = fieldstaffId => ({
  type: GET_FIELDSTAFF_DETAILS,
  payload: fieldstaffId,
})

export const getFieldStaffDetailsSuccess = fieldstaffDetails => ({
  type: GET_FIELDSTAFF_DETAILS_SUCCESS,
  payload: fieldstaffDetails,
})

export const getFieldStaffDetailsFail = error => ({
  type: GET_FIELDSTAFF_DETAILS_FAIL,
  payload: error,
})

export const createFieldStaff = (fieldstaff, onCloseClick) => ({
  type: CREATE_FIELDSTAFF,
  payload: { fieldstaff, onCloseClick },
})

export const createFieldStaffSuccess = fieldstaff => ({
  type: CREATE_FIELDSTAFF_SUCCESS,
  payload: fieldstaff,
})

export const createFieldStaffFail = error => ({
  type: CREATE_FIELDSTAFF_FAIL,
  payload: error,
})

export const getFieldStaffDetail = fieldstaffId => ({
  type: GET_FIELDSTAFF_DETAILS,
  payload: fieldstaffId,
})

export const updateFieldStaff = (fieldstaff, fieldstaffId, onCloseClick) => ({
  type: UPDATE_FIELDSTAFF,
  payload: { fieldstaff, fieldstaffId, onCloseClick },
})

export const updateFieldStaffSuccess = fieldstaff => ({
  type: UPDATE_FIELDSTAFF_SUCCESS,
  payload: fieldstaff,
})

export const updateFieldStaffFail = error => ({
  type: UPDATE_FIELDSTAFF_FAIL,
  payload: error,
})

export const deleteFieldStaff = (fieldstaffId, onCloseClick, history) => (
  console.log(fieldstaffId),
  {
    type: DELETE_FIELDSTAFF,
    payload: { fieldstaffId, onCloseClick, history },
  }
)

export const deleteFieldStaffSuccess = fieldstaff => ({
  type: DELETE_FIELDSTAFF_SUCCESS,
  payload: fieldstaff,
})

export const deleteFieldStaffFail = error => ({
  type: DELETE_FIELDSTAFF_FAIL,
  payload: error,
})

export const getAllFieldsCount = () => ({
  type: GET_ALL_FIELDS_COUNT,
  payload: "",
})

export const getAllFieldsCountSuccess = fieldsCount => ({
  type: GET_ALL_FIELDS_COUNT_SUCCESS,
  payload: fieldsCount,
})

export const getAllFieldsCountFail = error => ({
  type: GET_ALL_FIELDS_COUNT_FAIL,
  payload: error,
})
