import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { getFieldStaffs, getMapPublicKey } from "store/actions"
import { Col, Label, Row } from "reactstrap"
import MyGoogleMap from "components/Common/Gmap/googleMap"

//actions

const Form = ({ isUpdate, states }) => {
  const dispatch = useDispatch()
  const {
    selectedFieldStaff,
    setSelectedFieldStaff,
    setSelectedFieldStaffId,
    selectedFieldStaffSearch,
    setSelectedFieldStaffSearch,
  } = states

  const { allFieldStaffs, mapKey } = useSelector(state => ({
    allFieldStaffs: state?.FieldStaff?.fieldstaffs,
    mapKey: state.FieldSite.map,
  }))

  useEffect(() => {
    dispatch(getFieldStaffs("", "", "", selectedFieldStaffSearch))
  }, [dispatch, selectedFieldStaffSearch])

  const handleEnters = textEntered => {
    setSelectedFieldStaffSearch(textEntered)
  }

  function handlerFinalValue(event) {
    setSelectedFieldStaff(event.label)
    setSelectedFieldStaffId(event?.value)
  }

  console.log(allFieldStaffs)

  const optionGroup1 = [
    {
      options: allFieldStaffs?.results?.map((result, index) => ({
        key: index,
        label: result?.username,
        value: result?.id,
      })),
    },
  ]
  console.log(selectedFieldStaff)

  useEffect(() => {
    setSelectedFieldStaffId(isUpdate?.field_staff)
    setSelectedFieldStaff(isUpdate?.field_staff)
  }, [isUpdate])

  const MAP_PUBLIC_KEY = mapKey?.google_map_api_key
  const [markers, setMarkers] = useState([])
  const [mapValues, setMapValues] = useState([11.0408, 76.0849])

  const pointVals = [mapValues]
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: point => setMapValues(point),
    },
  }

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setMarkers(current => [
          {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            time: new Date(),
          },
        ])
      },
      () => null
    )
  }, [])

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])

  return (
    <div className="p-3 row">
      <div className="col-4 mb-2">
        <AvField
          name="fieldsite_name"
          className="form-control"
          id="thick-input"
          type="text"
          label="Name"
          validate={{
            required: {
              value: true,
              errorMessage: "name is required",
            },
          }}
          value={isUpdate?.fieldsite_name ?? ""}
        />{" "}
      </div>
      {/* <div className="col-6">
        <label>Field Staff</label>
        <Select
          onInputChange={handleEnters}
          value={selectedFieldStaff}
          placeholder={selectedFieldStaff}
          onChange={handlerFinalValue}
          options={optionGroup1}
          classNamePrefix="select2-selection"
          isLoading={false}
          required={"required"}
        />
      </div> */}
      <div className="col-4 mb-2">
        <AvField
          label="Owner Name"
          name="owner_name"
          type="text"
          className="form-control"
          id="horizontal-location-Input"
          value={isUpdate?.owner_name}
          validate={{
            required: {
              value: true,
              errorMessage: "owner name is required",
            },
          }}
        />
      </div>

      <div className="col-4 mb-2">
        <AvField
          id="horizontal-username-Input"
          name="owner_phone"
          type="text"
          label="Owner Phone"
          value={isUpdate?.owner_phone}
          validate={{
            required: {
              value: false,
              errorMessage: " phone is required",
            },
            pattern: {
              value: "^[0-9]+$",
              errorMessage: "Your text must be composed only numbers",
            },
            minLength: {
              value: 10,
              errorMessage: "Your name must be between 10 characters",
            },
            maxLength: {
              value: 10,
              errorMessage: "Your name must be between 10 characters",
            },
          }}
        />
      </div>
      <div className="col-6 mb-2">
        <AvField
          id="horizontal-username-Input"
          name="address"
          type="textarea"
          label="Address"
          value={isUpdate?.address ?? ""}
          validate={{
            required: {
              value: false,
              errorMessage: "address is required",
            },
          }}
        />
      </div>

      <div className="col-6 mb-2">
        <AvField
          label="Remarks"
          id="horizontal-email-Input"
          name="remark"
          className="form-control"
          type="textarea"
          value={isUpdate?.remark ?? ""}
        />
      </div>
      {/* <div className="col-12 mb-2">
        <AvField
          label="Location"
          id="horizontal-email-Input"
          name="location"
          className="form-control"
          type="number"
          value={isUpdate?.location ?? ""}
          validate={{
            required: {
              value: false,
              errorMessage: "location is required",
            },
          }}
        />
      </div> */}

      <Row>
        <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
          <MyGoogleMap
            MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
            setMarkers={setMarkers}
            markers={markers}
            height={300}
          />
        </Col>
        <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
          <div className="col-12 mt-5 mx-5">
            <Label>
              <i className="bx bx-map"></i> Location( lat / lng )
            </Label>
          </div>
          <div className="col-6 mb-3 mt-2 mx-5">
            <AvField
              name="location.coordinates[0]"
              placeholder="Latitude"
              type="text"
              id="#geolocation"
              errorMessage="Invalid latitude"
              validate={{
                required: { value: true },
              }}
              value={markers[0]?.lat}
            />
          </div>
          <div className="col-6 mb-3 mt-3 mx-5">
            <AvField
              name="location.coordinates[1]"
              placeholder="Longitude"
              type="text"
              errorMessage="Invalid longitude"
              validate={{
                required: { value: true },
              }}
              value={markers[0]?.lng}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Form

Form.propTypes = {
  isUpdate: PropTypes.object,
  states: PropTypes.object,
}
