import React, { useCallback, useEffect, useRef, useState } from "react"
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api"
import PropTypes from "prop-types"
import mapStyle from "./mapStyle"

import { MapSearch } from "./MapSearch"

const libraries = ["places"]

const mapContainerStyle = {
  width: "100%",
  height: "400px",
  borderRadius: "0 0 15px 15px",
}
// const center =

const options = {
  styles: mapStyle,
  disableDefaltUI: true,
  zoomConrol: true,
  maxZoom: 20,
}
const MyGoogleMap = ({
  MAP_PUBLIC_KEY,
  setMarkers,
  markers,
  notMark,
  height,
  isSearch,
  link,
  longitude,
  latitude,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey:
      MAP_PUBLIC_KEY || "AIzaSyAU4EsCpVhv88dQYuWU9enLuWLcQ3-Npo8",
    libraries,
  })

  latitude = latitude ? parseFloat(latitude) : markers[0]?.lat
  longitude = longitude ? parseFloat(longitude) : markers[0]?.lng

  const center = {
    lat: latitude,
    lng: longitude,
  }
  const mapRef = useRef()

  const onMapLoad = useCallback(map => {
    mapRef.current = map
  }, [])

  const zoomTo = ({ lat, lng }) => {
    if (lat && lng) {
      mapRef.current?.panTo({ lat, lng })
      mapRef.current?.setZoom(17)
    }
  }

  if (loadError) return "Error loading map"
  if (!isLoaded)
    return (
      <div className="d-flex align-items-center justify-center">
        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        map is loading
      </div>
    )

  const handleMapMarker = e => {
    if (notMark !== false) {
      setMarkers(current => [
        {
          lat: e.latLng.lat(),
          lng: e.latLng.lng(),
          time: new Date(),
        },
      ])
    }
  }

  console.log(markers)
  console.log("longitude ::")
  console.log(longitude)
  console.log(typeof longitude)
  // useEffect(() => {
  //   setCenter({
  //     lat: latitude,
  //     lng: longitude,
  //   })
  // }, [latitude, longitude])

  return (
    <>
      {MAP_PUBLIC_KEY && (
        <div className="position-relative custom-border">
          {notMark !== false && isSearch === true && (
            <MapSearch zoomTo={zoomTo} setMarkers={setMarkers} />
          )}
          {link === true ? (
            <a
              href={`https://www.google.com/maps?q=${latitude},${longitude}`}
              rel="noreferrer"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "black",
              }}
              className="text-muted"
            >
              <GoogleMap
                mapContainerStyle={{
                  ...mapContainerStyle,
                  height: height || 200,
                }}
                zoom={14}
                center={center}
                options={options}
                onClick={e => handleMapMarker(e)}
                onLoad={onMapLoad}
              >
                {/* {markers?.map(marker => ( */}
                <Marker
                  key={new Date().toISOString()}
                  position={{ lat: latitude, lng: longitude }}
                />
                {/* ))} */}
              </GoogleMap>
            </a>
          ) : (
            <GoogleMap
              mapContainerStyle={{
                ...mapContainerStyle,
                height: height || 200,
              }}
              zoom={14}
              center={center}
              options={options}
              onClick={e => handleMapMarker(e)}
              onLoad={onMapLoad}
            >
              {/* {markers?.map(marker => ( */}
              <Marker
                key={new Date().toISOString()}
                position={{ lat: latitude, lng: longitude }}
              />
              {/* ))} */}
            </GoogleMap>
          )}
        </div>
      )}
    </>
  )
}

export default MyGoogleMap

MyGoogleMap.propTypes = {
  MAP_PUBLIC_KEY: PropTypes.string,
  setMarkers: PropTypes.func,
  markers: PropTypes.any,
  notMark: PropTypes.bool,
  height: PropTypes.any,
  isSearch: PropTypes.bool,
  link: PropTypes.bool,
  longitude: PropTypes.any,
  latitude: PropTypes.any,
}
