import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useRef, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import logo from "../../../assets/images/logo/LOHA.png"



import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { addSheetQuantity, createSheetOrder, getSheets } from "store/actions"

const CreateSheetOrder = ({ history }) => {
  const dispatch = useDispatch()

  const { createSheetOrdererror, sheets, total_weight, loading } = useSelector(state => ({
    createSheetOrdererror: state.Sheet.createSheetOrdererror,
    sheets: state.Sheet.sheets,
    total_weight: state.Sheet.total_weight,
    loading: state.Sheet.loading,
  }))
  const [delivary, setDelivary] = useState(false)
  const [thickness, setThickness] = useState("all")
  const [width, setWidth] = useState("all")
  const [gsm, setGsm] = useState("0")
  const [role, setRole] = useState(localStorage.getItem("role"))

  const [thickTab, setThickTab] = useState("all")
  const [widthTab, setWidthTab] = useState("all")
  const [filterSheets, setFilterSheets] = useState([])
  // const [sheets, setSheets] = useState(sheets)setThickness

  useEffect(() => {
    // dispatch(getSheets())
    dispatch(getSheets("", thickness, width, ""))
    setFilterSheets(sheets)
  }, [dispatch, thickness, width])


  useEffect(() => {
    onSheetFilter()
  }, [sheets])


  useEffect(() => {
    onSheetFilter()
  }, [thickTab, widthTab])


  const onSheetFilter = () => {
    if (thickTab != "all" && widthTab != "all") {
      setFilterSheets(
        sheets.filter(
          i => (i.thick.toString() == parseFloat(thickTab).toFixed(2).toString() || i.qty )
        ).filter(
          i => (i.width.toString() == parseFloat(widthTab).toFixed(2).toString() || i.qty )
        )
      )
    } else if (thickTab == "all" && widthTab != "all") {
      setFilterSheets(
        sheets.filter(
          i => (i.width.toString() == parseFloat(widthTab).toFixed(2).toString() || i.qty )
        )
      )
    } else if (widthTab == "all" && thickTab != "all") {
      setFilterSheets(
        sheets.filter(
          i => (i.thick.toString() == parseFloat(thickTab).toFixed(2).toString() || i.qty )
        )
      )
    } else {
      setFilterSheets(sheets)
    }
  }


  // handleValidSubmit


  const handleValidSubmit = (onSubmitProps, values) => {
    values.total_weight = total_weight
    values.is_delivered = delivary
    values["order_items"] = sheets.filter((e) => {
      if (e.qty > 0) {
        e.quantity = e.qty
        e.sheet = e.id
        return e
      }
    })

    dispatch(createSheetOrder(values, history))
  }

  const onQuantityChange = (e) => {
    let sheetId = e.target.attributes["data-id"]["nodeValue"];
    let quantity = e.target.value
    dispatch(addSheetQuantity(sheetId, quantity))
  }


  const onDeliveryChange = (val) => {
    setDelivary(val)
  }

  var today = new Date().toLocaleDateString("en-CA")

  return (
    <>
      <MetaTags>
        <title>SheetOrder | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="SheetOrders" breadcrumbItem="Create SheetOrder" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={12}>
                <Card>
                  <CardBody className="">
                    <CardTitle className="h4 mb-4 print_only_show text-center">
                      <img src={logo} alt="lohalogo" height="22" className="m-auto d-block" />
                    </CardTitle>
                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      {createSheetOrdererror && (
                        <Alert color="danger">{createSheetOrdererror}</Alert>
                      )}

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-title-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Title

                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-title-Input"
                            name="title"
                            type="text"
                            required
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="date-input"
                          className="col-sm-3 col-form-label"
                        >
                          Date
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="date"
                            className="form-control"
                            id="date-input"
                            type="date"
                            required
                            value={today}
                          />
                        </Col>
                      </div>
                      {role != "sheet_user" ?
                        <div className="row mb-4">
                          <Label
                            htmlFor="order_type-input"
                            className="col-sm-3 col-form-label"
                          >
                            Stock Updation
                          </Label>
                          <Col sm={9}>
                            <AvField
                              name="order_type"
                              className="form-control"
                              id="order_type-input"
                              type="select"
                              required
                              value="0"
                            >
                              <option value="0" disabled>Select</option>
                              <option value="addition">Addition</option>
                              <option value="deduction">Deduction</option>
                            </AvField>
                          </Col>
                        </div>
                        : <input type="hidden" value="deduction" name="order_type" />}

                      <div className="row mb-4">
                        <Label
                          htmlFor="thickness-input"
                          className="col-sm-3 col-form-label"
                        >
                          Thickness
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="thickness"
                            className="form-control"
                            id="thickness-input"
                            type="select"
                            required
                            value="0"
                            onChange={(e) => {
                              setThickness(e.target.value)
                            }}
                          >
                            <option value="0" disabled>Select</option>
                            <option value="1.50">1.5 mm</option>
                            <option value="1.20">1.2 mm</option>
                            <option value="1.00">1 mm</option>
                            <option value="2.00">2 mm</option>
                          </AvField>
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="thickness-input"
                          className="col-sm-3 col-form-label"
                        >
                          Width
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="width"
                            className="form-control"
                            id="width-input"
                            type="select"
                            required
                            value="0"
                            onChange={(e) => {
                              setWidth(e.target.value)
                            }}
                          >
                            <option value="0" disabled>Select</option>
                            <option value="1220.00">1220</option>
                            <option value="1225.00">1225</option>
                            <option value="1250.00">1250</option>
                            <option value="1500.00">1500</option>
                            <option value="900.00">900</option>

                          </AvField>
                        </Col>
                      </div>


                      <div className="row mb-4">
                        <Label
                          htmlFor="thickness-input"
                          className="col-sm-3 col-form-label"
                        >
                          Gsm
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="gsm"
                            className="form-control"
                            id="gsm-input"
                            type="select"
                            required
                            value="0"
                            onChange={(e) => {
                              setGsm(e.target.value)
                            }}
                          >
                            <option value="0" disabled>Select</option>
                            <option value="120">120</option>
                            <option value="80">80</option>
                            <option value="275">275</option>
                          </AvField>
                        </Col>
                      </div>


                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-is_delivered-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Delivered
                        </Label>
                        <Col sm={9} className="d-flex justify-content-around">
                          <label className="d-flex" htmlFor="radio_not_delivered">
                            Not deliverd:<AvField
                              id="radio_not_delivered"
                              name="is_delivered"
                              className="form-control"
                              type="radio"
                              value="false"
                              onClick={(onSubmitProps, v) => {
                                onDeliveryChange(false)
                              }}

                            />
                          </label>
                          <label className="d-flex" htmlFor="radio_delivered">
                            Deliverd:<AvField
                              id="radio_delivered"
                              name="is_delivered"
                              className="form-control"
                              type="radio"
                              value="true"
                              onClick={(onSubmitProps, v) => {
                                onDeliveryChange(true)
                              }}
                            />
                          </label>
                        </Col>



                        <Col sm={12} className="d-flex justify-content-around mt-4 print_hide">
                          <CardTitle className="h4 mb-4 print_hide">
                            <Button color={thickTab == "all" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("all")}>All</Button>
                            <Button color={thickTab == "1.5" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("1.5")}>1.5 mm</Button>
                            <Button color={thickTab == "1.2" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("1.2")}>1.2 mm</Button>
                            <Button color={thickTab == "1" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("1")}>1.0 mm</Button>
                            <Button color={thickTab == "2" ? "info" : "primary"} className={"me-2"} onClick={() => setThickTab("2")}>2.0 mm</Button>
                          </CardTitle>
                          <CardTitle className="h4 mb-4 print_hide">
                            <Button color={widthTab == "all" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("all")}>All</Button>
                            <Button color={widthTab == "1220.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1220.00")}>1220</Button>
                            <Button color={widthTab == "1225.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1225.00")}>1225</Button>
                            <Button color={widthTab == "1250.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1250.00")}>1250</Button>
                            <Button color={widthTab == "1500.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("1500.00")}>1500</Button>
                            <Button color={widthTab == "900.00" ? "warning" : "secondary"} className={"me-2"} onClick={() => setWidthTab("900.00")}>900</Button>
                          </CardTitle>
                        </Col>




                      </div>

                      <Col sm={12}>
                        <table className="table">
                          <thead>
                            <tr >
                              <th>#</th>
                              <th>Name</th>
                              <th>Gsm</th>
                              <th>Thick</th>
                              <th>Width</th>
                              <th>Length</th>
                              <th>Sqft</th>
                              <th>Kg/pc</th>
                              <th>Quantity</th>
                              <th>Weight</th>
                            </tr >
                          </thead>
                          <tbody>

                            {map(filterSheets, (item, index) => (
                              <tr key={index} className="sheet_row" data-id={item.id}>
                                <td>{index + 1}</td>
                                <td>{item.name}</td>
                                <td>{gsm}</td>
                                <td className={`thick_${(item?.thick.replace(".", "_"))}`}>{item.thick}</td>
                                <td className={`width_${(item?.width.replace(".", "_"))}`}>{item.width}</td>
                                <td>{item.height}</td>
                                <td>{item.sqft}</td>
                                <td className="unit_kg" >{item.unit_kg}</td>
                                <td>
                                  <input className="quantity form-control" value={item?.qty? item.qty:""} style={{ "width": "82px" }} type="number" data-id={item.id} onChange={(onSubmitProps, v) => {
                                    onQuantityChange(onSubmitProps, v)
                                  }} />
                                </td>
                                <td className="unit_kg">{item.weight}</td>
                              </tr >
                            ))}
                          </tbody>

                          <tfoot>
                            <tr className="total_weight">
                              <td colSpan={7}></td>
                              <td>Total weight : </td>
                              <td>{total_weight} kg</td>
                            </tr>
                          </tfoot>

                        </table>
                      </Col>


                      <div className="row justify-content-end">
                        <Col>
                          <div className="print_hide">
                            <Button
                              type="submit"
                              color="success"
                              className="w-md print_hide m-auto d-block"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                    <div className="text-left">
                      <Button className="print_hide" onClick={() => {
                        window.print()
                      }} >Print</Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>

        </Container>
      </div>
    </>
  )
}

export default CreateSheetOrder

CreateSheetOrder.propTypes = {
  history: PropTypes.object,
}
