/* SHEET_USERS */
export const GET_SHEET_USERS = "GET_SHEET_USERS"
export const GET_SHEET_USERS_SUCCESS = "GET_SHEET_USERS_SUCCESS"
export const GET_SHEET_USERS_FAIL = "GET_SHEET_USERS_FAIL"

/* SHEET_USERS DETAIL*/
export const GET_SHEET_USER_DETAIL = "GET_SHEET_USER_DETAIL"
export const GET_SHEET_USER_DETAIL_SUCCESS = "GET_SHEET_USER_DETAIL_SUCCESS"
export const GET_SHEET_USER_DETAIL_FAIL = "GET_SHEET_USER_DETAIL_FAIL"

/**
 * add SHEET_USER
 */
export const CREATE_SHEET_USER = "CREATE_SHEET_USER"
export const CREATE_SHEET_USER_SUCCESS = "CREATE_SHEET_USER_SUCCESS"
export const CREATE_SHEET_USER_FAIL = "CREATE_SHEET_USER_FAIL"

/**
 * Edit SHEET_USER
 */
export const UPDATE_SHEET_USER = "UPDATE_SHEET_USER"
export const UPDATE_SHEET_USER_SUCCESS = "UPDATE_SHEET_USER_SUCCESS"
export const UPDATE_SHEET_USER_FAIL = "UPDATE_SHEET_USER_FAIL"

/**
 * Delete SHEET_USER
 */
export const DELETE_SHEET_USER = "DELETE_SHEET_USER"
export const DELETE_SHEET_USER_SUCCESS = "DELETE_SHEET_USER_SUCCESS"
export const DELETE_SHEET_USER_FAIL = "DELETE_SHEET_USER_FAIL"
