import React, { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { map } from "lodash"
import { Link } from "react-router-dom"
import PropTypes from "prop-types"
import { MetaTags } from "react-meta-tags"
import { Button, Container, Input } from "reactstrap"
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  Label,
  CardTitle,
  FormGroup,
  Spinner,
} from "reactstrap"
import Select from "react-select"

//actions
import { createOrder, getAddons, getDealers, getProducts } from "store/actions"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { doneNotification } from "components/Common/Notification"

const CreateOrder = ({ history }) => {
  const dispatch = useDispatch()
  //redux state
  const { products, loading, orderLoading, dealers, allAddons } = useSelector(
    state => ({
      products: state.Products.products,
      loading: state.StoreItems.loading,
      orderLoading: state.Orders.loading,
      dealers: state.Dealers.dealers,
      allAddons: state.Products.addons,
    })
  )

  const [selectedProduct, setSelectedProduct] = useState("Search a product")
  const [selectedDealer, setSelectedDealer] = useState("Search a Dealer")

  const [searchDealerText, setSearchDealerText] = useState("")
  const [searchText, setSearchText] = useState("")

  const [newOrder, setNewOrders] = useState([])
  const [rawData, setRawData] = useState({
    dealer: "",
    orderitem: [],
  })

  const [addonQuantity, setAddonQuantity] = useState()
  const [addonDescription, setAddonDescription] = useState()
  const [addonState, setAddonState] = useState([])
  const [selectedAddons, setSelectedAddons] = useState([])
  const [finalAddon, setFinalAddon] = useState()
  const [showAddonOrNot, setShowAddonOrNot] = useState(false)
  const [loadingAddon, setLoadingAddon] = useState(false)
  const [prodQty, setProdQty] = useState()
  const [material, setMaterial] = useState()

  useEffect(() => {
    if (allAddons?.length >= 1) {
      setShowAddonOrNot(true)
    }
  }, [allAddons])

  useEffect(() => {
    const arr = [...selectedAddons, ...addonState]

    const ids = arr.map(o => o.addon_name)
    const filtered = arr.filter(
      ({ addon_name }, index) => !ids.includes(addon_name, index + 1)
    )

    setFinalAddon([
      ...filtered,
      { addon_name: "Material", description: material, quantity: 1 },
    ])
  }, [selectedAddons, addonState, material])

  const [isPanel, setIsPanel] = useState(false)

  useEffect(() => {
    const isExist = selectedAddons?.map(i => i.addon_name === "Panel")[0]
    if (isExist) {
      setIsPanel(true)
    }
  }, [selectedAddons])

  const handleAddons = () => {
    if (isPanel === true) {
      return allAddons
    } else {
      return (
        allAddons &&
        allAddons?.length > 0 &&
        allAddons?.filter(
          i => i.addon_name === "Manchary" || i.addon_name === "Panel"
        )
      )
    }
  }

  const Role = localStorage.getItem("role")

  useEffect(() => {
    dispatch(getProducts(searchText))
  }, [dispatch, searchText])

  useEffect(() => {
    if (Role !== "dealer") {
      dispatch(getDealers(searchDealerText, ""))
    }
  }, [dispatch, searchDealerText])

  const onAddFormRow = async () => {
    const modifiedRows = [...newOrder]

    modifiedRows.push({
      id: modifiedRows.length + 1,
      orderitemaddon: finalAddon,
      ...rawData.orderitem,
    })

    setNewOrders(modifiedRows)

    setSelectedProduct("Search a product")
    setShowAddonOrNot(false)
    setSelectedAddons([])
    setAddonState([])
    setProdQty(0)
    setIsPanel(false)
  }

  const onDeleteFormRow = id => {
    if (id !== 0) {
      var modifiedRows = [...newOrder]
      modifiedRows = modifiedRows?.filter(x => x["id"] !== id)
      setNewOrders(modifiedRows)
    }
  }
  // orderitem

  const onSubmitOrder = () => {
    console.log({
      dealer: rawData.dealer,
      orderitem: newOrder,
    })
    dispatch(
      createOrder(
        {
          dealer: rawData.dealer,
          orderitem: newOrder,
        },
        history
      )
    )
  }

  //setore item from and search
  function handlerFinalValue(event) {
    setSelectedProduct(event.label)
    setRawData({
      ...rawData,
      orderitem: {
        ["product"]: event.value,
        ["productName"]: event.label,
      },
    })
    dispatch(getAddons(event?.value))
  }
  const disabledBtn = () => {
    if (rawData.orderitem.product && rawData.orderitem.quantity) {
      return true
    } else {
      return false
    }
  }

  const optionGroup1 = [
    {
      options: products?.results?.map((result, index) => ({
        key: index,
        label: `${result?.serial_number} / ${result?.channel_size} / ${result?.guage} / ${result?.perticular} / ${result?.no_of_cols} / ${result?.width}x${result?.height}`,
        value: result.id,
      })),
    },
  ]

  const handleEnters = textEntered => {
    setSearchText(textEntered)
  }

  function handlerUserFinalValue(event) {
    setSelectedDealer(event.label)
    setRawData({
      ...rawData,
      ["dealer"]: event.value,
    })
  }
  const optionGroupDealers = [
    {
      options: dealers?.results?.map((result, index) => ({
        key: index,
        label: result.account.full_name,
        value: result.id,
      })),
    },
  ]

  const handleDealerEnters = textEntered => {
    setSearchDealerText(textEntered)
  }

  const addOrRemoveItem = (arr, setArray, valuen) => {
    const value = { addon_name: valuen, description: "", quantity: 1 }
    const isPresent = arr?.map(i => i?.addon_name).includes(value?.addon_name)

    var newArr = arr

    if (isPresent) {
      newArr = arr.filter(item => {
        return item?.addon_name.toString() !== value?.addon_name.toString()
      })
      if (value?.addon_name === "Panel") {
        setIsPanel(false)
      }
    } else {
      newArr = [...arr, value]
      if (value?.addon_name === "Panel") {
        setIsPanel(true)
      }
    }
    setArray(newArr)
  }

  const handleAddonsVisible = addon => {
    if (addon === "Divider") return true
    if (addon === "Shutter Partition") return true
    if (addon === "Glass Partition") return true
    if (addon === "Check Design") return true
    if (addon === "Arch Design") return true

    if (addon === "Loover") return false
    if (addon === "Open Side") return false
    if (addon === "Manchary") return false
    if (addon === "Panel") return false
    if (addon === "Door Sill") return false
  }

  const [tempItem, setTempItem] = useState()

  async function handleSubmit(item) {
    setLoadingAddon(true)
    setTempItem(item)

    setAddonState([
      ...addonState,
      {
        description: addonDescription?.description,
        quantity: addonQuantity?.quantity,
        addon_name: item,
      },
    ])
    setTimeout(() => {
      setLoadingAddon(false)
      doneNotification()
    }, 1000)
  }
  useEffect(() => {
    if (loadingAddon === true) {
      setTimeout(() => {
        setLoadingAddon(false)
      }, 2000)
    }
  }, [])

  const handleQuantity = value => {
    setAddonQuantity({ quantity: value })
  }
  const handleDescription = value => {
    setAddonDescription({ description: value })
  }

  const handleOpenSide = (v, item) => {
    const isPresent = addonState?.map(
      i => i?.description === "left" || i?.description === "right"
    )[0]

    if (isPresent) {
      const obj = addonState.find(obj => obj.addon_name === item)

      if (v === "left") {
        obj.description = "left"
      } else {
        obj.description = "right"
      }
      setAddonState([...addonState])
    } else {
      setAddonState([
        ...addonState,
        {
          description: v,
          quantity: 0,
          addon_name: item,
        },
      ])
    }
  }

  const handleProductQty = e => {
    setRawData({
      ...rawData,
      orderitem: {
        ...rawData.orderitem,
        ["quantity"]: e.target.value,
      },
    })

    setProdQty(e.target.value)
  }

  const handleMaterial = e => {
    setRawData({
      ...rawData,
      orderitem: {
        ...rawData.orderitem,
        ["material"]: e.target.value,
      },
    })
    setMaterial(e.target.value)
  }

  const handleBtnColor = addon => {
    const found = addonState?.find(x => x.addon_name === addon)
    return found?.addon_name === addon ? "secondary" : "success"
  }

  return (
    <>
      <MetaTags>
        <title>Order | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Orders" breadcrumbItem="Create Order" />

        <Container fluid>
          <div className="container-fluid">
            {/* uploading */}
            <Row>
              <Col lg={"12"}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Add Order</CardTitle>

                    <Form className="repeater" encType="multipart/form-data">
                      <div>
                        <Row>
                          {Role == "dealer" ? (
                            <></>
                          ) : (
                            <Col lg={6} className="mb-3">
                              <FormGroup className="mb-3">
                                <Label>Dealer</Label>

                                <div className="col-md-12"></div>
                                <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                  <Select
                                    onInputChange={handleDealerEnters}
                                    value={selectedDealer}
                                    placeholder={selectedDealer}
                                    onChange={handlerUserFinalValue}
                                    options={optionGroupDealers}
                                    classNamePrefix="select2-selection"
                                    isLoading={false}
                                    required={"required"}
                                  />
                                </div>
                              </FormGroup>
                            </Col>
                          )}

                          <Col lg={6} className="mb-3">
                            <FormGroup className="mb-3">
                              <Label>Products</Label>

                              <div className="col-md-12"></div>
                              <div className="mb-3 ajax-select mt-3 mt-lg-0 select2-container">
                                <Select
                                  onInputChange={handleEnters}
                                  value={selectedProduct}
                                  placeholder={selectedProduct}
                                  onChange={handlerFinalValue}
                                  options={optionGroup1}
                                  classNamePrefix="select2-selection"
                                  isLoading={false}
                                  required={"required"}
                                />
                              </div>
                            </FormGroup>
                          </Col>

                          <Col lg={6} className="mb-3">
                            <label htmlFor="resume">Quantity</label>
                            <input
                              type="number"
                              className="form-control"
                              id="resume"
                              min={0}
                              value={prodQty}
                              required={"required"}
                              onChange={e => handleProductQty(e)}
                            />
                          </Col>
                          <Col lg={6} className="mb-3">
                            <label htmlFor="resume">Material</label>
                            <input
                              type="text"
                              className="form-control"
                              id="resume"
                              min={0}
                              value={material}
                              required={"required"}
                              onChange={e => handleMaterial(e)}
                            />
                          </Col>

                          <Col lg={12}>
                            {showAddonOrNot ? (
                              <div className="mb-3">
                                <Label for="basicpill-servicetax-input8">
                                  Addons
                                </Label>
                                <Row className="d-flex flex-wrap w-50 ">
                                  {map(handleAddons(), (item, indexNew) => (
                                    <Fragment key={indexNew}>
                                      <Col
                                        lg={12}
                                        className="d-flex"
                                        style={{ flexDirection: "column" }}
                                      >
                                        <div
                                          className="d-flex justify-content-space-between"
                                          style={{
                                            justifyContent:
                                              item?.addon_name === "Open Side"
                                                ? ""
                                                : "space-between",
                                          }}
                                        >
                                          <div style={{ width: "fit-content" }}>
                                            <input
                                              type="checkbox"
                                              className="btn-check mx-2"
                                              name={item?.addon_name}
                                              checked={selectedAddons?.find(
                                                i =>
                                                  i.addon_name ===
                                                  item?.addon_name
                                              )}
                                              id={item?.addon_name}
                                              autoComplete="off"
                                              value={item?.addon_name}
                                              onClick={e =>
                                                addOrRemoveItem(
                                                  selectedAddons,
                                                  setSelectedAddons,
                                                  e.target.value
                                                )
                                              }
                                            />
                                            <label
                                              className={`btn ${
                                                item?.addon_name === "Panel" ||
                                                item?.addon_name === "Manchary"
                                                  ? "isSpecial "
                                                  : ""
                                              } ${
                                                selectedAddons?.find(
                                                  i =>
                                                    i.addon_name ===
                                                    item?.addon_name
                                                )
                                                  ? "btn-outline-info"
                                                  : "btn-outline-secondary"
                                              }`}
                                              style={{ whiteSpace: "nowrap" }}
                                              htmlFor={item?.addon_name}
                                            >
                                              {item?.addon_name}
                                            </label>

                                            {selectedAddons?.find(
                                              i => i.addon_name === "Panel"
                                            ) &&
                                              item?.addon_name ===
                                                "Manchary" && (
                                                <hr style={{ color: "#fff" }} />
                                              )}
                                          </div>

                                          {item?.addon_name === "Open Side" &&
                                            selectedAddons?.find(
                                              i => i.addon_name === "Open Side"
                                            ) && (
                                              <div className="d-flex mx-5 align-items-center">
                                                <label>
                                                  <Input
                                                    type="radio"
                                                    value={"right"}
                                                    name="description"
                                                    onChange={e =>
                                                      handleOpenSide(
                                                        e.target.value,
                                                        item?.addon_name
                                                      )
                                                    }
                                                  />
                                                  <span className="mx-2">
                                                    Right
                                                  </span>
                                                </label>
                                                <label>
                                                  <Input
                                                    type="radio"
                                                    value={"left"}
                                                    name="description"
                                                    onChange={e =>
                                                      handleOpenSide(
                                                        e.target.value,
                                                        item?.addon_name
                                                      )
                                                    }
                                                  />
                                                  <span className="mx-2">
                                                    Left
                                                  </span>
                                                </label>
                                              </div>
                                            )}

                                          {handleAddonsVisible(
                                            item?.addon_name
                                          ) === true &&
                                            selectedAddons?.find(
                                              i =>
                                                i.addon_name ===
                                                item?.addon_name
                                            ) && (
                                              <div className="mb-2">
                                                <AvForm className="d-flex flex-wrap justify-content-end align-items-center MyForm">
                                                  <AvField
                                                    className=""
                                                    type={"text"}
                                                    name={"quantity"}
                                                    errorMessage="Invalid Input"
                                                    placeholder={"qty"}
                                                    onChange={e =>
                                                      handleQuantity(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <AvField
                                                    className=""
                                                    type={"text"}
                                                    name={"description"}
                                                    errorMessage="Invalid Input"
                                                    placeholder={"Description"}
                                                    onChange={e =>
                                                      handleDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                  />
                                                  <Button
                                                    type="button"
                                                    color={handleBtnColor(
                                                      item?.addon_name
                                                    )}
                                                    style={{
                                                      width: "fit-content",
                                                      marginLeft: "0.5rem",
                                                    }}
                                                    onClick={() =>
                                                      handleSubmit(
                                                        item?.addon_name
                                                      )
                                                    }
                                                  >
                                                    Add
                                                    {loadingAddon &&
                                                      tempItem ===
                                                        item?.addon_name && (
                                                        <>
                                                          <i className="mx-2 bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                        </>
                                                      )}
                                                  </Button>
                                                </AvForm>
                                              </div>
                                            )}
                                        </div>
                                      </Col>
                                    </Fragment>
                                  ))}
                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            <input
                              type="button"
                              className={`btn  mr-lg-0 ${
                                disabledBtn() == false
                                  ? "disabled btn-dark"
                                  : "btn-success"
                              }`}
                              value="Add to Orders"
                              onClick={() => onAddFormRow()}
                              style={{
                                marginTop: "1rem",
                                pointerEvents:
                                  rawData.product == false && "none",
                              }}
                            />
                          </div>
                        </Row>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              {newOrder.length > 0 && (
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <CardTitle className="h4 mb-4">All Orders </CardTitle>
                      {loading ? (
                        <Spinner type="grow" color="gray" />
                      ) : (
                        <Form
                          className="repeater"
                          encType="multipart/form-data"
                        >
                          <div>
                            {map(newOrder, (item, index) => (
                              <Row key={index}>
                                <Row className="text-muted mt-4">
                                  <Col lg={6} md={5}>
                                    <p>
                                      <i className="mdi mdi-chevron-right text-primary me-1" />
                                      Product : {item?.productName || ""}
                                    </p>
                                  </Col>
                                  <Col lg={4} md={5}>
                                    <p>Quantity : {item?.quantity || ""}</p>
                                  </Col>

                                  <Col
                                    lg={2}
                                    md={2}
                                    className="align-self-center m-auto"
                                  >
                                    <div
                                      className="d-grid "
                                      style={{ maxWidth: "200px" }}
                                    >
                                      <i
                                        className="fa fa-trash mt-1 mr-lg-0 mb-4 text-danger"
                                        onClick={() => onDeleteFormRow(item.id)}
                                      ></i>
                                    </div>
                                  </Col>
                                </Row>
                              </Row>
                            ))}
                          </div>
                          <div>
                            <Col sm="12">
                              <div className="text-sm-end mt-2">
                                <Link
                                  to="#"
                                  className="btn btn-success"
                                  onClick={onSubmitOrder}
                                >
                                  Confirm Order
                                  {orderLoading ? (
                                    <>
                                      <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    </>
                                  ) : (
                                    <i className="mdi mdi-truck-fast mx-2" />
                                  )}
                                </Link>
                              </div>
                            </Col>
                          </div>
                        </Form>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateOrder

CreateOrder.propTypes = {
  history: PropTypes.object,
}
