import React from "react"
import { Container } from "reactstrap"
import MetaTags from "react-meta-tags"
import { useWindowSize } from "components/Common/useWindowSize"
function PrivacyPolicy() {
  const isMobile = useWindowSize()
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <MetaTags>
          <title>Privacy | Loha </title>
        </MetaTags>
        <Container>
          <p className="text-black" style={{ fontSize: "20px" }}>
            Privacy Policy
          </p>
          <p className="fw-bold " style={{ fontSize: "40px" }}>
            PRIVACY NOTICE
          </p>
          <p className="fw-bold text-black " style={{ fontSize: "20px" }}>
            LOHA Teams
          </p>
          <p className="pt-3 pb-5 text-black" style={{ fontSize: "15px" }}>
            Last updated 30 MARCH 2024
          </p>
          <p className=" pb-5 text-black" style={{ fontSize: "16px" }}>
            This document serves as the Privacy Policy for the{" "}
            <span className="fw-bold text-black">LOHA Teams</span> application,
            managed by{" "}
            <span className="fw-bold text-black">
              HI-TECH ENGINEERING INDUSTRIES
            </span>{" "}
            . It details the collection, use, and protection of personal
            information of users and emphasizes our commitment to safeguarding
            privacy
          </p>
          <p
            className="pt-3 pb-5 "
            style={{ fontSize: "16px", color: "#283d4e", fontWeight: "600" }}
          >
            Questions or concerns? Reading this privacy notice will help you
            understand your privacy rights and choices. If you do not agree with
            our policies and practices, please do not use our Services. If you
            still have any questions or concerns, please contact us at{" "}
            <span className="fw-bolder text-black ml-1">
              info@lohasteels.com
            </span>
          </p>
          <p className="text-black fs-5 m-0 pb-3">
            <span className=" text-muted  " style={{ fontSize: "20px" }}>
              SUMMARY OF KEY POINTS
            </span>
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            {" "}
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics by clicking the
            link following each key point or by using our table of contents
            below to find the section you are looking for.
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            {" "}
            What personal information do we process? When you visit, use, or
            navigate our Services, we may process personal information depending
            on how you interact with LOHA Teams app and the Services, the
            choices you make, and the products and features you use.
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            {" "}
            Do we process any sensitive personal information? We do not process
            sensitive personal information
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            Do we receive any information from third parties? We do not receive
            any information from third parties.
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            How do we process your information? We process your information to
            provide, improve, and administer our Services, communicate with you,
            for security and fraud prevention, and to comply with law. We may
            also process your information for other purposes with your consent.
            We process your information only when we have a valid legal reason
            to do so.
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            In what situations and with which parties do we share personal
            information? We may share information in specific situations and
            with specific third parties.
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            How do we keep your information safe? We have organizational and
            technical processes and procedures in place to protect your personal
            information. However, no electronic transmission over the internet
            or information storage technology can be guaranteed to be 100%
            secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able
            to defeat our security and improperly collect, access, steal, or
            modify your information. What are your rights? Depending on where
            you are located geographically, the applicable privacy law may mean
            you have certain rights regarding your personal information.
          </p>
          <p className="text-muted fs-5" style={{ lineHeight: "25px" }}>
            How do you exercise your rights? The easiest way to exercise your
            rights is by filling out our data subject request form available
            here, or by contacting us. We will consider and act upon any request
            in accordance with applicable data protection laws.
          </p>
          <p className="pt-4 fs-5 m-0 pb-3 fw-bolder">
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              TABLE OF CONTENTS
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>1.</span>{" "}
            <span className="">WHAT INFORMATION DO WE COLLECT?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>2.</span>{" "}
            <span className="">HOW DO WE PROCESS YOUR INFORMATION?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>3.</span>{" "}
            <span className="">
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>4.</span>{" "}
            <span className="">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>5.</span>{" "}
            <span className="">HOW LONG DO WE KEEP YOUR INFORMATION?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>6.</span>{" "}
            <span className="">HOW DO WE KEEP YOUR INFORMATION SAFE?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>7.</span>{" "}
            <span className="">DO WE COLLECT INFORMATION FROM MINORS?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>8.</span>{" "}
            <span className="">WHAT ARE YOUR PRIVACY RIGHTS?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>9.</span>{" "}
            <span className="">CONTROLS FOR DO-NOT-TRACK FEATURES</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>10.</span>{" "}
            <span className="">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>11.</span>{" "}
            <span className="">DO WE MAKE UPDATES TO THIS NOTICE? </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>12.</span>{" "}
            <span className="">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-2">
            <span>13.</span>{" "}
            <span className="">
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </span>
          </p>
          <p className="pt-4 fs-5 m-0 pb-3 fw-bolder">
            <span style={{ color: "#283d4e" }}>1.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              WHAT INFORMATION DO WE COLLECT?
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-1 pt-4">
            <span className="">Personal information you disclose to us</span>
          </p>
          <p className="text-muted fs-5 m-0 pb-1">
            <span className="">
              In Short: We collect personal information that you provide to us.
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-1">
            <span className="">
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.
            </span>
          </p>
          <p className="text-muted fs-5 m-0 pb-1">
            <span className="">
              Personal Information Provided by You. The personal information
              that we collect depends on the context of your interactions with
              us and the Services, the choices you make, and the products and
              features you use. The personal information we collect may include
              the following:
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">names</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">phone numbers</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">mailing addresses </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">usernames </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">contact preferences </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">contact or authentication data </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              Sensitive Information. We do not process sensitive information.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Application Data. If you use our application(s), we also may
              collect the following information if you choose to provide us with
              access or permission:{" "}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              {`
              Geolocation Information. We may request access or permission to
              track location-based information from your mobile device, Only
              while you are using our mobile application(s), to automatically
              detect your geo-location when you check in/out at work sites. This
              location information is used solely for the purpose of tracking
              your work activities. If you wish to change our access or
              permissions, you may do so in your device's settings.{" "}`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Mobile Device Data. We automatically collect device information
              (such as your mobile device ID, model, and manufacturer),
              operating system, version information and system configuration
              information, device and application identification numbers,
              browser type and version, hardware model Internet service provider
              and/or mobile carrier, and Internet Protocol (IP) address (or
              proxy server). If you are using our application(s), we may also
              collect information about the phone network associated with your
              mobile device, your mobile device’s operating system or platform,
              the type of mobile device you use, your mobile device’s unique
              device ID, and information about the features of our
              application(s) you accessed.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              This information is primarily needed to maintain the security and
              operation of our application(s), for troubleshooting, and for our
              internal analytics and reporting purposes.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">Information automatically collected</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              Like many businesses, we also collect information through cookies
              and similar technologies. The information we collect includes:
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              {`
              Log and Usage Data. Log and usage data is service-related,
              diagnostic, usage, and performance information our servers
              automatically collect when you access or use our Services and
              which we record in log files. Depending on how you interact with
              us, this log data may include your IP address, device information,
              browser type, and settings and information about your activity in
              the Services (such as the date/time stamps associated with your
              usage, pages and files viewed, searches, and other actions you
              take such as which features you use), device event information
              (such as system activity, error reports (sometimes called 'crash
              dumps'), and hardware settings).`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Device Data. We collect device data such as information about your
              computer, phone, tablet, or other device you use to access the
              Services. Depending on the device used, this device data may
              include information such as your IP address (or proxy server),
              device and application identification numbers, location, browser
              type, hardware model, Internet service provider and/or mobile
              carrier, operating system, and system configuration information.
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>2.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              HOW DO WE PROCESS YOUR INFORMATION?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              To facilitate account creation and authentication and otherwise
              manage user accounts. We may process your information so you can
              create and log in to your account, as well as keep your account in
              working order.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              To deliver and facilitate delivery of services to the user. We may
              process your information to provide you with the requested
              service.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              To comply with our legal obligations. We may process your
              information to comply with our legal obligations, respond to legal
              requests, and exercise, establish, or defend our legal rights.
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>3.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: We may share information in specific situations
              described in this section and/or with the following third parties.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We may need to share your personal information in the following
              situations:
            </span>
          </p>
          <div
            className="ml-3"
            style={{ marginLeft: "15px", marginBottom: "45px" }}
          >
            <p className="text-muted fs-5 m-0  pt-2 ">
              <span className="">
                Admin Access: Authorized administrators have access to the data
                collected through the App for the purpose of real-time
                monitoring and management of field staff activities.
              </span>
            </p>
            <p className="text-muted fs-5 m-0  pt-2 ">
              <span className="">
                Third-party Services: We may utilize third-party service
                providers to assist us in delivering and improving the
                functionality of the App. These service providers are
                contractually obligated to safeguard your personal information
                and may only use it for the specified purposes.
              </span>
            </p>
          </div>
          <p className="text-muted fs-5 m-0  pt-2 ">
            <span className="">
              When we use Google Maps Platform APIs. We may share your
              information with certain Google Maps Platform APIs (e.g. Google
              Maps API, Places API). To find out more about Google’s Privacy
              Policy, please refer to this https://policies.google.com/privacy .
              We use certain Google Maps Platform APIs to retrieve certain
              information when you make location-specific requests.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              {`
              This includes: current location ; and other similar information. A
              full list of what we use information for can be found in this
              section and in the previous section titled 'HOW DO WE PROCESS YOUR
              INFORMATION?'. We obtain and store on your device ('cache') your
              location. You may revoke your consent anytime by contacting us at
              the contact details provided at the end of this document. The
              Google Maps Platform APIs that we use store and access cookies and
              other information on your devices. If you are a user currently in
              the European Economic Area (EU countries, Iceland, Liechtenstein,
              and Norway) or the United Kingdom, please take a look at our
              Cookie Notice.`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              {`
              Offer Wall. Our application(s) may display a third-party hosted
              'offer wall'. Such an offer wall allows third-party advertisers to
              offer virtual currency, gifts, or other items to users in return
              for the acceptance and completion of an advertisement offer. Such
              an offer wall may appear in our application(s) and be displayed to
              you based on certain data, such as your geographic area or
              demographic information. When you click on an offer wall, you will
              be brought to an external website belonging to other persons and
              will leave our application(s). A unique identifier, such as your
              user ID, will be shared with the offer wall provider in order to
              prevent fraud and properly credit your account with the relevant
              reward.
              `}
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>4.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: We may use cookies and other tracking technologies to
              collect and store your information.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>5.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              HOW LONG DO WE KEEP YOUR INFORMATION?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us to keep your personal information for
              longer than the period of time in which users have an account with
              us.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymise such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>6.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              HOW DO WE KEEP YOUR INFORMATION SAFE?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>7.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              DO WE COLLECT INFORMATION FROM MINORS?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: We do not knowingly collect data from or market to
              children under 18 years of age. We do not knowingly solicit data
              from or market to children under 18 years of age. By using the
              Services, you represent that you are at least 18 or that you are
              the parent or guardian of such a minor and consent to such minor
              dependent’s use of the Services. If we learn that personal
              information from users less than 18 years of age has been
              collected, we will deactivate the account and take reasonable
              measures to promptly delete such data from our records. If you
              become aware of any data we may have collected from children under
              age 18, please contact us at info@lohasteels.com
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>8.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              WHAT ARE YOUR PRIVACY RIGHTS?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              In Short: You may review, change, or terminate your account at any
              time.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              If you are located in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.{" "}
              <br /> If you are located in Switzerland, the contact details for
              the data protection authorities are available
              here:https://www.edoeb.admin.ch/edoeb/en/home.html
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span style={{ textDecoration: "underline" }}>
              Withdrawing your consent:{" "}
            </span>
            <span className="">
              {`
              
              If we are relying on your consent to process your personal
information, which may be express and/or implied consent depending on the applicable law, you
have the right to withdraw your consent at any time. You can withdraw your consent at any time
by contacting us by using the contact details provided in the section 'HOW CAN YOU
CONTACT US ABOUT THIS NOTICE?' below.
`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">Account Information</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Log in to your account settings and update your user account.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Contact us using the contact information provided.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our legal terms and/or comply with applicable legal
              requirements. <br />
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              <span style={{ textDecoration: "underline" }}>
                Cookies and similar technologies:
              </span>{" "}
              Most Web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove
              cookies and to reject cookies. If you choose to remove cookies or
              reject cookies, this could affect certain features or services of
              our Services. To opt out of interest-based advertising by
              advertisers on our Services visit
              http://www.aboutads.info/choices/.
              <br />
              If you have questions or comments about your privacy rights, you
              may email us at info@lohasteels.com
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              If you have questions or comments about your privacy rights, you
              may email us at info@lohasteels.com
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>9.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              CONTROLS FOR DO-NOT-TRACK FEATURES
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {`
              
            Most web browsers and some mobile operating systems and mobile applications include a
Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to
have data about your online browsing activities monitored and collected. At this stage no
uniform technology standard for recognising and implementing DNT signals has been finalized.
As such, we do not currently respond to DNT browser signals or any other mechanism that
automatically communicates your choice not to be tracked online. If a standard for online
tracking is adopted that we must follow in the future, we will inform you about that practice in a
revised version of this privacy notice
              `}
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>10.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            In Short: Yes, if you are a resident of California, you are granted
            specific rights regarding access to your personal information.
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {`
              
              California Civil Code Section 1798.83, also known as the 'Shine
              The Light' law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
              `}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              If you are under 18 years of age, reside in California, and have a
              registered account with Services, you have the right to request
              removal of unwanted data that you publicly post on the Services.
              To request removal of such data, please contact us using the
              contact information provided below and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the Services, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">CCPA Privacy Notice</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {` The California Code of Regulations defines a 'resident' as:`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              (1) every individual who is in the State of California for other
              than a temporary or transitory purpose and
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              (2) every individual who is domiciled in the State of California
              who is outside the State of California for a temporary or
              transitory purpose
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {`All other individuals are defined as 'non-residents'.`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {`   If this definition of 'resident' applies to you, we must adhere to
              certain rights and obligations regarding your personal
              information.`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              What categories of personal information do we collect?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We have collected the following categories of personal information
              in the past twelve (12) months:
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">Category</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">Examples</span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">Collected</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">A. Identifiers</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  Contact details, such as real name, alias, postal address,
                  telephone or mobile contact number, unique personal
                  identifier, online identifier, Internet Protocol address,
                  email address, and account name
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  B. Personal information categories listed in the California
                  Customer Records statute Name, contact information, education,
                  employment, employment history, and financial information
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">YES</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  C. Protected classification characteristics under California
                  or federal law
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">Gender and date of birth</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">D. Commercial information</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  Transaction information, purchase history, financial details,
                  and payment information
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">YES</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">E. Biometric information</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">Fingerprints and voiceprints</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  F. Internet or other similar network activity
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  Browsing history, search history, online behavior, interest
                  data, and interactions with our and other websites,
                  applications, systems, and advertisements
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">G. Geolocation data</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">Device location</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">YES</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  H. Audio, electronic, visual, thermal, olfactory, or similar
                  information
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  Images and audio, video or call recordings created in
                  connection with our business activities
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  I. Professional or employment-related information
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  Business contact details in order to provide you our Services
                  at a business level or job title, work history, and
                  professional qualifications if you apply for a job with us
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">J. Education Information</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  Student records and directory information
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  K. Inferences drawn from other personal information
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  {`Inferences drawn from any of the collected personal
                  information listed above to create a profile or summary about,
                  for example, an individual’s preferences and characteristics`}
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">NO</span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              {" "}
              <p className="text-muted fs-5 m-0  pt-2">
                <span className="">
                  We may also collect other personal information outside of
                  these categories through instances where you interact with us
                  in person, online, or by phone or mail in the context of:
                </span>
              </p>
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Receiving help through our customer support channels;
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Participation in customer surveys or contests; and
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-4">
            <span className="">
              Facilitation in the delivery of our Services and to respond to
              your inquiries.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              How do we use and share your personal information?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              More information about our data collection and sharing practices
              can be found in this privacy notice.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              You may contact us by email at info@lohasteels.com, or by
              referring to the contact details at the bottom of this document.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              If you are using an authorized agent to exercise your right to opt
              out we may deny a request if the authorized agent does not submit
              proof that they have been validly authorized to act on your
              behalf.{" "}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              Will your information be shared with anyone else?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              We may disclose your personal information with our service
              providers pursuant to a written contract between us and each
              service provider. Each service provider is a for-profit entity
              that processes the information on our behalf.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              {` We may use your personal information for our own business purposes, such as for undertaking
internal research for technological development and demonstration. This is not considered to be
'selling' your personal information.`}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="">
              LOHA Teams has not disclosed or sold any personal information to
              third parties for a business or commercial purpose in the
              preceding twelve (12) months. LOHA Teams App will not sell
              personal information in the future belonging to website visitors,
              users, and other consumers. Your rights with respect to your
              personal data{" "}
            </span>
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span
              className="  "
              style={{
                fontSize: "20px",
                color: "#283d4e",
                textDecoration: "underline",
              }}
            >
              Right to request deletion of the data — Request to delete
            </span>
          </p>
          <p className="text-muted fs-5 m-0  ">
            <span className="">
              You can ask for the deletion of your personal information. If you
              ask us to delete your personal information, we will respect your
              request and delete your personal information, subject to certain
              exceptions provided by law, such as (but not limited to) the
              exercise by another consumer of his or her right to free speech,
              our compliance requirements resulting from a legal obligation, or
              any processing that may be required to protect against illegal
              activities.
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            <span className="" style={{ textDecoration: "underline" }}>
              Right to be informed — Request to know
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            Depending on the circumstances, you have a right to know:
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            whether we collect and use your personal information;
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            the categories of personal information that we collect;
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            the purposes for which the collected personal information is used;
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            whether we sell your personal information to third parties;
          </p>{" "}
          <p className="text-muted fs-5 m-0  pt-2">
            the categories of personal information that we sold or disclosed for
            a business purpose;
          </p>{" "}
          <p className="text-muted fs-5 m-0  pt-2">
            the categories of third parties to whom the personal information was
            sold or disclosed for a business purpose; and
          </p>{" "}
          <p className="text-muted fs-5 m-0  pt-2">
            the business or commercial purpose for collecting or selling
            personal information. In accordance with applicable law, we are not
            obligated to provide or delete consumer information that is
            de-identified in response to a consumer request or to re-identify
            individual data to verify a consumer request.
          </p>{" "}
          <p
            className="text-muted fs-5 m-0  pt-2"
            style={{ textDecoration: "underline" }}
          >
            {`Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights`}
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            We will not discriminate against you if you exercise your privacy
            rights.
          </p>
          <p
            className="text-muted fs-5 m-0  pt-2"
            style={{ textDecoration: "underline" }}
          >
            Verification process
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            Upon receiving your request, we will need to verify your identity to
            determine you are the same person about whom we have the information
            in our system. These verification efforts require us to ask you to
            provide information so that we can match it with information you
            have previously provided us. For instance, depending on the type of
            request you submit, we may ask you to provide certain information so
            that we can match the information you provide with the information
            we already have on file, or we may contact you through a
            communication method (e.g. phone or email) that you have previously
            provided to us. We may also use other verification methods as the
            circumstances dictate.
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            We will only use personal information provided in your request to
            verify your identity or authority to make the request. To the extent
            possible, we will avoid requesting additional information from you
            for the purposes of verification. However, if we cannot verify your
            identity from the information already maintained by us, we may
            request that you provide additional information for the purposes of
            verifying your identity and for security or fraud-prevention
            purposes. We will delete such additionally provided information as
            soon as we finish verifying you.
          </p>
          <p
            className="text-muted fs-5 m-0  pt-2"
            style={{ textDecoration: "underline" }}
          >
            Other privacy rights
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            You may object to the processing of your personal information.
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            You may request correction of your personal data if it is incorrect
            or no longer relevant, or ask to restrict the processing of the
            information.
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            You can designate an authorized agent to make a request under the
            CCPA on your behalf. We may deny a request from an authorized agent
            that does not submit proof that they have been validly authorized to
            act on your behalf in accordance with the CCPA.
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            You may request to opt out from future selling of your personal
            information to third parties. Upon receiving an opt-out request, we
            will act upon the request as soon as feasibly possible, but no later
            than fifteen (15) days from the date of the request submission.{" "}
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            To exercise these rights, you can contact us by email at
            info@lohasteels.com, or by referring to the contact details at the
            bottom of this document. If you have a complaint about how we handle
            your data, we would like to hear from you.
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>11.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              DO WE MAKE UPDATES TO THIS NOTICE?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            In Short: Yes, we will update this notice as necessary to stay
            compliant with relevant laws.
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            {` We may update this privacy notice from time to time. The updated version will be indicated by
an updated 'Revised' date and the updated version will be effective as soon as it is accessible. If
we make material changes to this privacy notice, we may notify you either by prominently
posting a notice of such changes or by directly sending you a notification. We encourage you to
review this privacy notice frequently to be informed of how we are protecting your information.`}
          </p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>12.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            If you have questions or comments about this notice, you may email
            us at info@lohasteels.com or by post to:
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            {" "}
            HI -TECH ENGINEERING INDUSTRIES
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            Puthanparambu, Iringallur (PO)
          </p>
          <p className="text-muted fs-5 m-0  pt-2">Kottakkal Road, Vengara ,</p>
          <p className="text-muted fs-5 m-0  pt-2">Malappuram , 676304</p>
          <p className="text-muted fs-5 m-0  pt-2">Kerala, India</p>
          <p className="pt-5 fs-5 m-0 pb-3  fw-bolder">
            <span style={{ color: "#283d4e" }}>13.</span>{" "}
            <span className="  " style={{ fontSize: "20px", color: "#283d4e" }}>
              HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?{" "}
            </span>
          </p>
          <p className="text-muted fs-5 m-0  pt-2">
            Based on the applicable laws of your country, you may have the right
            to request access to the personal information we collect from you,
            change that information, or delete it. To request to review, update,
            or delete your personal information, please contact
            info@lohasteels.com
          </p>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PrivacyPolicy
