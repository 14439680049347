import {
  GET_VISITENTRYIMAGES,
  GET_VISITENTRYIMAGES_SUCCESS,
  GET_VISITENTRYIMAGES_FAIL,
  GET_VISITENTRYIMAGE_DETAILS,
  GET_VISITENTRYIMAGE_DETAILS_SUCCESS,
  GET_VISITENTRYIMAGE_DETAILS_FAIL,
  CREATE_VISITENTRYIMAGE,
  CREATE_VISITENTRYIMAGE_SUCCESS,
  CREATE_VISITENTRYIMAGE_FAIL,
  UPDATE_VISITENTRYIMAGE,
  UPDATE_VISITENTRYIMAGE_SUCCESS,
  UPDATE_VISITENTRYIMAGE_FAIL,
  DELETE_VISITENTRYIMAGE_SUCCESS,
  DELETE_VISITENTRYIMAGE_FAIL,
  DELETE_VISITENTRYIMAGE,
} from "./actionTypes"

// VisitEntryImage - This line cannot be edited or removed
export const getVisitEntryImages = (page, sort, limit, searchText) => ({
  type: GET_VISITENTRYIMAGES,
  payload: { page, sort, limit, searchText },
})

export const getVisitEntryImagesSuccess = visitentryimages => ({
  type: GET_VISITENTRYIMAGES_SUCCESS,
  payload: visitentryimages,
})

export const getVisitEntryImagesFail = error => ({
  type: GET_VISITENTRYIMAGES_FAIL,
  payload: error,
})

export const getVisitEntryImageDetails = visitentryimageId => ({
  type: GET_VISITENTRYIMAGE_DETAILS,
  payload: visitentryimageId,
})

export const getVisitEntryImageDetailsSuccess = visitentryimageDetails => ({
  type: GET_VISITENTRYIMAGE_DETAILS_SUCCESS,
  payload: visitentryimageDetails,
})

export const getVisitEntryImageDetailsFail = error => ({
  type: GET_VISITENTRYIMAGE_DETAILS_FAIL,
  payload: error,
})

export const createVisitEntryImage = (
  visitentryimage,
  onCloseClick,
  handleImageState
) => ({
  type: CREATE_VISITENTRYIMAGE,
  payload: { visitentryimage, onCloseClick, handleImageState },
})

export const createVisitEntryImageSuccess = visitentryimage => ({
  type: CREATE_VISITENTRYIMAGE_SUCCESS,
  payload: visitentryimage,
})

export const createVisitEntryImageFail = error => ({
  type: CREATE_VISITENTRYIMAGE_FAIL,
  payload: error,
})

export const getVisitEntryImageDetail = visitentryimageId => ({
  type: GET_VISITENTRYIMAGE_DETAILS,
  payload: visitentryimageId,
})

export const updateVisitEntryImage = (
  visitentryimage,
  visitentryimageId,
  history
) => ({
  type: UPDATE_VISITENTRYIMAGE,
  payload: { visitentryimage, visitentryimageId, history },
})

export const updateVisitEntryImageSuccess = visitentryimage => ({
  type: UPDATE_VISITENTRYIMAGE_SUCCESS,
  payload: visitentryimage,
})

export const updateVisitEntryImageFail = error => ({
  type: UPDATE_VISITENTRYIMAGE_FAIL,
  payload: error,
})

export const deleteVisitEntryImage = (visitentryimageId, history) => ({
  type: DELETE_VISITENTRYIMAGE,
  payload: { visitentryimageId, history },
})

export const deleteVisitEntryImageSuccess = visitentryimage => (
  console.log(visitentryimage),
  {
    type: DELETE_VISITENTRYIMAGE_SUCCESS,
    payload: visitentryimage,
  }
)

export const deleteVisitEntryImageFail = error => ({
  type: DELETE_VISITENTRYIMAGE_FAIL,
  payload: error,
})
