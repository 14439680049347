import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect, useRef, useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"
import logo from "../../../assets/images/logo/LOHA.png"

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { updateSheetOrder, getSheetOrderDetail, updateOrderItemQuantity, onOrderDeliveryChange } from "store/actions"
import { useParams } from "react-router-dom"

const UpdateSheetOrder = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const [printQuantity, setPrintQuantity] = useState(true)
  const [printWeight, setPrintWeight] = useState(false)

  const { updateSheetOrdererror, sheetOrderDetail, sheets, loading } = useSelector(state => ({
    updateSheetOrdererror: state.Sheet.updateSheetOrdererror,
    sheetOrderDetail: state.Sheet.sheetOrderDetail,
    sheets: state.Sheet.sheets,

    loading: state.Sheet.loading,
  }))

  useEffect(() => {
    // dispatch(getSheets())
    dispatch(getSheetOrderDetail(params.id))
  }, [dispatch])

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {
    values.is_delivered = sheetOrderDetail?.is_delivered
    values.total_weight = sheetOrderDetail?.total_weight
    values["order_items"] = sheetOrderDetail?.order_items.filter((e) => {
      if (e.quantity > 0) {
        return e
      }
    })
    dispatch(updateSheetOrder(sheetOrderDetail.id, values, history))
  }

  const onQuantityChange = (e) => {
    let id = e.target.attributes["data-id"]["nodeValue"];
    let quantity = e.target.value
    dispatch(updateOrderItemQuantity(id, quantity))
  }


  const onDeliveryChange = (val) => {
    dispatch(onOrderDeliveryChange(val))
  }

  return (
    <>
      <MetaTags>
        <title>SheetOrder | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="SheetOrders" breadcrumbItem="Update SheetOrder" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={12}>
                <Card>
                  <CardBody className="">
                    <CardTitle className="h4 mb-4 print_only_show">
                      <img src={logo} alt="lohalogo" height="22" className="m-auto d-block" />
                    </CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      {updateSheetOrdererror && (
                        <Alert color="danger">{updateSheetOrdererror}</Alert>
                      )}

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-title-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Title

                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-title-Input"
                            name="title"
                            type="text"
                            required
                            value={sheetOrderDetail?.title}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="date-input"
                          className="col-sm-3 col-form-label"
                        >
                          Date
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="date"
                            className="form-control"
                            id="date-input"
                            type="text"
                            required
                            value={sheetOrderDetail?.date}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="order_type-input"
                          className="col-sm-3 col-form-label"
                        >
                          Stock Updation
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="order_type"
                            className="form-control"
                            id="order_type-input"
                            type="text"
                            required
                            readOnly
                            value={sheetOrderDetail?.order_type}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-is_delivered-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Delivered
                        </Label>
                        <Col sm={9} className="d-flex justify-content-around">
                          <label className="d-flex" htmlFor="radio_not_delivered">
                            Not deliverd:<AvField
                              id="radio_not_delivered"
                              name="is_delivered"
                              className="form-control"
                              type="radio"
                              value="false"
                              checked={sheetOrderDetail?.is_delivered == false ? true : false}
                              onClick={(onSubmitProps, v) => {
                                onDeliveryChange(false)
                              }}
                            />
                          </label>
                          <label className="d-flex" htmlFor="radio_delivered">
                            Deliverd:<AvField
                              id="radio_delivered"
                              name="is_delivered"
                              className="form-control"
                              type="radio"
                              value="true"
                              checked={sheetOrderDetail?.is_delivered == true ? true : false}
                              onClick={(onSubmitProps, v) => {
                                onDeliveryChange(true)
                              }}
                            />
                          </label>
                        </Col>
                      </div>

                      <Col sm={12}>

                        <table className="table">
                          <thead>
                            <tr >
                              <th>#</th>
                              <th>Name</th>
                              <th>Gsm</th>
                              <th>Thick</th>
                              <th>Width</th>
                              <th>Length</th>
                              <th>Sqft</th>
                              <th>Kg/pc</th>
                              <th>Quantity</th>
                              <th>Weight</th>
                            </tr >
                          </thead>
                          <tbody>

                            {map(sheetOrderDetail?.order_items, (item, index) => (
                              <tr key={index} className={`sheet_row is_deleted_${item?.sheet_is_deleted}`} data-id="{item.id}">
                                <td>{index + 1}</td>
                                <td>{item?.name}</td>
                                <td>{sheetOrderDetail?.gsm}</td>
                                <td className={`thick_${(item?.thick?.replace(".", "_"))}`}>{item?.thick}</td>
                                <td className={`width_${(item?.width?.replace(".", "_"))}`}>{item?.width}</td>
                                <td>{item?.height}</td>
                                <td>{item?.sqft}</td>
                                <td className="unit_kg" >{item?.unit_kg}</td>
                                <td>
                                  <AvField
                                    id="horizontal-title-Input"
                                    className="quantity"
                                    style={{ "width": "82px" }}
                                    name="x"
                                    type="number"
                                    required
                                    value={item?.quantity}
                                    data-id={item?.id}
                                    onChange={(onSubmitProps, v) => {
                                      onQuantityChange(onSubmitProps, v)
                                    }}
                                  />

                                </td>
                                <td className="unit_kg">{item?.weight}</td>
                              </tr >
                            ))}
                          </tbody>

                          <tfoot>
                            <tr className="total_weight">
                              <td colSpan={6}></td>
                              <td colSpan={2}>Total : </td>
                              <td><span className={printQuantity ? "" : "print_hide"}>{sheetOrderDetail?.total_quantity}</span></td>
                              <td><span className={printWeight ? "" : "print_hide"}>{sheetOrderDetail?.total_weight} kg</span></td>
                            </tr>
                            <tr className="print_hide">
                              <td colSpan={8}></td>
                              <td><input type="checkbox" checked={printQuantity} onChange={(e) => {
                                setPrintQuantity(e.target.checked)
                              }} /></td>
                              <td><input type="checkbox" checked={printWeight} onChange={(e) => {
                                setPrintWeight(e.target.checked)
                              }} /></td>
                            </tr>
                          </tfoot>

                        </table>
                      </Col>


                      <div className="row justify-content-end">
                        <Col sm={12}>
                          <div className="print_hide">
                            <Button
                              type="submit"
                              color="success"
                              className="w-md print_hide m-auto d-block"
                            >
                              {loading && (
                                <>
                                  <i className="w-md print_hide btn btn-success"></i>
                                </>
                              )}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                    <div className="d-flex justify-content-between">
                      <Button className="print_hide" onClick={() => {
                        window.print()
                      }} >Print</Button>
                      {sheetOrderDetail?.creator_name}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>

        </Container>
      </div>
    </>
  )
}

export default UpdateSheetOrder

UpdateSheetOrder.propTypes = {
  history: PropTypes.object,
}
