import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { createDealer } from "store/actions"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { map } from "lodash"

const CreateDealer = ({ history }) => {
  const dispatch = useDispatch()

  const { error, loading } = useSelector(state => ({
    error: state.Dealers.error,
    loading: state.Dealers.loading,
  }))

  const [selectedUserType, setSelectedUserType] = useState("is_dealer")

  // handleValidSubmit
  const handleValidSubmit = (onSubmitProps, values) => {

    const dealerCreatedData = {
      ...values,
      is_dealer: selectedUserType === "is_dealer" ? true : false,
      is_customer: selectedUserType === "is_customer" ? true : false,
      username: values?.username ? values?.username : null,
      profit: values?.profit ? values?.profit : 1,
    }

    dispatch(createDealer(dealerCreatedData, history))
  }
  const usernameError = error?.username && error?.username[0]

  const mailError = error?.email && error?.email[0]

  return (
    <>
      <MetaTags>
        <title>Dealer | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Dealers" breadcrumbItem="Create Dealer" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Fill this form</CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(onSubmitProps, v) => {
                        handleValidSubmit(onSubmitProps, v)
                      }}
                    >
                      <div className="d-flex">
                        {map(inputFeildDoor, (item, key) => (
                          <div key={key} className="m-1">
                            <input
                              type="radio"
                              className="btn-check"
                              name={item.value}
                              checked={selectedUserType === item.value}
                              id={item.id}
                              autoComplete="off"
                              value={item.value}
                              onClick={e => setSelectedUserType(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-info"
                              htmlFor={item.id}
                            >
                              {item?.title}
                            </label>
                          </div>
                        ))}
                      </div>
                      {error?.error_message && (
                        <Alert color="danger" className="mt-2">
                          {error?.error_message}
                        </Alert>
                      )}
                      {selectedUserType === "is_dealer" ? (
                        <div className="row mb-4">
                          <Label
                            htmlFor="horizontal-username-Input"
                            className="col-sm-3 col-form-label"
                          >
                            User name
                          </Label>
                          <Col sm={9}>
                            <AvField
                              id="horizontal-username-Input"
                              name="username"
                              type="text"
                              validate={{
                                required: {
                                  value:
                                    selectedUserType === "is_dealer"
                                      ? true
                                      : false,
                                },
                              }}
                            />
                          </Col>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-firstname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Full Name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-firstname-Input"
                            name="full_name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                          />
                        </Col>
                      </div>
                      {/* <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-lastname-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Last name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-lastname-Input"
                            name="last_name"
                            type="text"
                            validate={{
                              required: {
                                value:
                                  selectedUserType === "is_dealer"
                                    ? true
                                    : false,
                              },
                            }}
                          />
                        </Col>
                      </div> */}
                      <div className="row mb-4">
                        <Label
                          htmlFor="tel-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="phone"
                            className="form-control"
                            id="tel-input"
                            type="mobile"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please enter your phone number",
                              },
                              minLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                              maxLength: {
                                value: 10,
                                errorMessage:
                                  "Your number must be 10 characters",
                              },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-email-Input"
                            name="email"
                            className="form-control"
                            type="email"
                            validate={{
                              required: {
                                value:
                                  selectedUserType === "is_dealer"
                                    ? true
                                    : false,
                              },
                            }}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-location-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Address
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="location"
                            type="textarea"
                            className="form-control"
                            id="horizontal-location-Input"
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-profit-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Profit
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="profit"
                            type="number"
                            min={0}
                            className="form-control"
                            id="horizontal-profit-Input"
                          />
                        </Col>
                      </div>

                      <div className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="success"
                              className="w-md"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                </>
                              )}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default CreateDealer

CreateDealer.propTypes = {
  history: PropTypes.object,
}

const inputFeildDoor = [
  {
    id: 2,
    title: "Dealer",
    isRequire: true,
    value: "is_dealer",
  },
  {
    id: 1,
    title: "Customer",
    isRequire: true,
    value: "is_customer",
  },
]
