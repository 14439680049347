import React, { useState } from "react"
import { MetaTags } from "react-meta-tags"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { AvForm } from "availity-reactstrap-validation"
import { useDispatch, useSelector } from "react-redux"
import { createFieldSite } from "store/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import Form from "./Form"

function CreateFieldSites() {
  const dispatch = useDispatch()
  const history = useHistory()

  const [selectedFieldStaff, setSelectedFieldStaff] = useState(
    "Search a Filed Staff"
  )
  const [selectedFieldStaffId, setSelectedFieldStaffId] =
    useState("Search a Staff")
  const [selectedFieldStaffSearch, setSelectedFieldStaffSearch] = useState("")

  const { loading } = useSelector(state => ({
    loading: state.FieldSite.loading,
  }))

  const handleValidSubmit = v => {
    const coord1 = v?.location.coordinates[0]
    const coord2 = v?.location.coordinates[1]

    const formattedLocation = `(${coord1},${coord2})`

    const createValues = {
      ...v,
      field_staff: selectedFieldStaffId,
      location: formattedLocation,
    }

    console.log(v)

    console.log(formattedLocation)

    dispatch(createFieldSite(createValues, history))
  }

  const stateData = {
    selectedFieldStaff,
    setSelectedFieldStaff,
    selectedFieldStaffId,
    setSelectedFieldStaffId,
    selectedFieldStaffSearch,
    setSelectedFieldStaffSearch,
  }

  return (
    <div>
      <MetaTags>
        <title>Field Site | Loha </title>
      </MetaTags>
      <div className="page-content">
        <Breadcrumbs title="Field Site" breadcrumbItem="Create" />
        <Container fluid>
          <div className="container-fluid">
            <AvForm
              onValidSubmit={(e, v) => {
                handleValidSubmit(v)
              }}
            >
              <Row>
                <Col md="8" lg="8">
                  <Card>
                    <CardBody>
                      <Form states={stateData} />
                      <div className="d-flex align-items-center justify-content-end">
                        <Button
                          type="submit"
                          color="success"
                          className="w-md mx-1"
                        >
                          {loading && (
                            <>
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            </>
                          )}
                          Create
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default CreateFieldSites
