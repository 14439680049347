import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import { useDispatch } from "react-redux"

//action
import {
  getDashboardData,
  getDashboardDataFields,
  getFinishedProduct,
  getOrders,
  getProducts,
} from "store/actions"

//components
import OutOfStockItems from "./OutOfStockItems"
import FinishedProductList from "./FinishedProduct"
import Transaction from "../Common/Transation"
import MiniWidget from "./mini-widget"
import PendingOrder from "./PendingOrder"
import MiniCards from "./mini-card"
import MonthlyChart from "./MonthlyChart"
import YearlyChart from "./YearlyChart"
import SheetOrder from "./SheetOrder"

function AdminDashboard() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFinishedProduct("", ""))
    dispatch(getOrders("", ""))
    dispatch(getProducts())
    dispatch(getDashboardData())
    dispatch(getDashboardDataFields())
  }, [dispatch])

  return (
    <Row>
      <Col lx="4" lg="4">
        <PendingOrder />
        <OutOfStockItems />
        <FinishedProductList />
      </Col>
      <Col lx="8" lg="8">
        <Row>
          <MiniWidget />
        </Row>
        <Row>
          <MiniCards />
        </Row>
        <SheetOrder />
        <MonthlyChart />
        <YearlyChart />
      </Col>
      <Transaction />
    </Row>
  )
}

export default AdminDashboard
