import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, CardTitle, Button } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { deleteSheetOrder, getSheetOrders, updateSheetOrder } from "store/actions"

import DeleteModal from "components/Common/DeleteModal"
import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"

const SheetOrders = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [orderType, setOrderType] = useState("all")
  const [isOpen, setIsOpen] = useState({ state: false, sheetOrderId: "" })
  const [reaload, setReload] = useState(false)

  const { sheetOrders, loading } = useSelector(state => ({
    sheetOrders: state.Sheet.sheetOrders,
    loading: state.Sheet.loading,
  }))

  const totalPages = Math.ceil(sheetOrders?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getSheetOrders(searchText, orderType, pageSend()))
  }, [dispatch, page, searchText, orderType])

  const handleReload = () => {
    dispatch(getSheetOrders(searchText, orderType, pageSend()))
    setReload(false)
  }

  const handleDelete = sheetOrderId => {
    setIsOpen({ ...isOpen, ["state"]: true, ["sheetOrderId"]: sheetOrderId })
  }

  const handleDeleteEvent = () => {
    dispatch(deleteSheetOrder(isOpen.sheetOrderId))
    setIsOpen({ ...isOpen, ["state"]: false })
    setReload(true)
  }

  const columns = [
    {
      dataField: "auto_id",
      text: "#",
      sort: true,
    },
    {
      dataField: "title",
      text: "Tilte",
    },
    {
      dataField: "gsm",
      text: "Gsm",
    },
    {
      dataField: "date",
      text: "Date",
    },
    {
      dataField: "is_delivered",
      text: "Delivered",
    },
    {
      dataField: "total_weight",
      text: "Weight",
    },
    {
      dataField: "order_type",
      text: "Type",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  // const handleValidSubmit = (onSubmitProps, values, sheetId) => {
  //   dispatch(updateSheetOrder(values, sheetId, "", "isUpdate"))
  // }

  const sheetOrderData = map(sheetOrders?.results, (item, index) => ({
    ...item,
    key: index,
    update: (
      <AvForm
        className="form-horizontal "
        onValidSubmit={(onSubmitProps, v) => {
          handleValidSubmit(onSubmitProps, v, item.id)
        }}
      >
        <Row style={{ alignItems: "center" }} className="d-flex">
          <Col sm={2} lg={2} style={{ width: "100px" }}>
            <AvField
              id="horizontal-price-Input"
              name="stock"
              className="form-control"
              min={0}
              type="number"
              placeholder="Stock"
              required
            />
          </Col>
          <Col sm={2} lg={2} style={{ width: "100px" }}>
            <AvField
              id="horizontal-price-Input"
              name="price"
              min={0}
              className="form-control"
              placeholder="Price"
              type="number"
              required
            />
          </Col>
          <Col sm={2} lg={3} style={{ width: "100px" }}>
            <button
              type="submit"
              className="btn btn-sm btn-success btn-lg ms-2"
            >
              Save
            </button>
          </Col>
        </Row>
      </AvForm>
    ),
    action: (
      <div className="d-flex">

        <div>
          <Link
            to={`/sheets/sheetorders/update/${item?.id}`}
            className="btn btn-sm"
            title="Update"
          >
            <i className="bx bx-pen text-success font-size-15"></i>
          </Link>
        </div>
        <div title="Remove">
          <i
            style={{ cursor: "pointer" }}
            className="bx bx-trash text-danger font-size-15 px-4"
            onClick={() => handleDelete(item?.id)}
          ></i>
        </div>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen.state}
        onCloseClick={() => setIsOpen({ ...isOpen, ["state"]: false })}
        onDeleteClick={handleDeleteEvent}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
            <CardTitle className="h4 mb-4 d-flex justify-content-between">
                <div>
                  <Button color={orderType=="all" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("all")}>All</Button>
                  <Button color={orderType=="addition" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("addition")}>Addition</Button>
                  <Button color={orderType=="deduction" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("deduction")}>Deduction</Button>
                </div>
                <Link to="/sheets/sheetorders/total-weight" className="">
                  <Button color="danger" className={"me-2"} >Total weight </Button>
                </Link>
              </CardTitle>
              <CardTitle className="mb-4">
              

                {/* <link to="">
                <Button color={orderType=="deduction" ? "info" : "primary"} className={"me-2"} onClick={() => setOrderType("deduction")}>Deduction</Button>
                // </link> */}
              </CardTitle>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={sheetOrderData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="8">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>{" "}
                          </div>
                        </div>
                      </Col>
                      {reaload == true && (
                        <Col
                          md="4 d-flex"
                          style={{
                            alignItems: "center",
                            justifyContent: "right",
                          }}
                        >
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <div className="text-sm-end">
                                <a
                                  href="#dailywork"
                                  type="button"
                                  className="btn-light btn btn-rounded m-0"
                                  onClick={() => handleReload()}
                                >
                                  Refresh
                                </a>
                              </div>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SheetOrders
