/* STOREMNGRS */
export const GET_STOREMNGRS = "GET_STOREMNGRS"
export const GET_STOREMNGRS_SUCCESS = "GET_STOREMNGRS_SUCCESS"
export const GET_STOREMNGRS_FAIL = "GET_STOREMNGRS_FAIL"

/* STOREMNGRS DETAIL*/
export const GET_STOREMNGR_DETAIL = "GET_STOREMNGR_DETAIL"
export const GET_STOREMNGR_DETAIL_SUCCESS = "GET_STOREMNGR_DETAIL_SUCCESS"
export const GET_STOREMNGR_DETAIL_FAIL = "GET_STOREMNGR_DETAIL_FAIL"

/**
 * add STOREMNGR
 */
export const CREATE_STOREMNGR = "CREATE_STOREMNGR"
export const CREATE_STOREMNGR_SUCCESS = "CREATE_STOREMNGR_SUCCESS"
export const CREATE_STOREMNGR_FAIL = "CREATE_STOREMNGR_FAIL"

/**
 * Edit STOREMNGR
 */
export const UPDATE_STOREMNGR = "UPDATE_STOREMNGR"
export const UPDATE_STOREMNGR_SUCCESS = "UPDATE_STOREMNGR_SUCCESS"
export const UPDATE_STOREMNGR_FAIL = "UPDATE_STOREMNGR_FAIL"

/**
 * Delete STOREMNGR
 */
export const DELETE_STOREMNGR = "DELETE_STOREMNGR"
export const DELETE_STOREMNGR_SUCCESS = "DELETE_STOREMNGR_SUCCESS"
export const DELETE_STOREMNGR_FAIL = "DELETE_STOREMNGR_FAIL"
