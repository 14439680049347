/* FieldStaffs - This line cannot be edited or removed */
export const GET_FIELDSTAFFS = "GET_FIELDSTAFFS"
export const GET_FIELDSTAFFS_SUCCESS = "GET_FIELDSTAFFS_SUCCESS"
export const GET_FIELDSTAFFS_FAIL = "GET_FIELDSTAFFS_FAIL"

/* FieldStaff_DETAILS */
export const GET_FIELDSTAFF_DETAILS = "GET_FIELDSTAFF_DETAILS"
export const GET_FIELDSTAFF_DETAILS_SUCCESS = "GET_FIELDSTAFF_DETAILS_SUCCESS"
export const GET_FIELDSTAFF_DETAILS_FAIL = "GET_FIELDSTAFF_DETAILS_FAIL"

/* CREATE FieldStaff */
export const CREATE_FIELDSTAFF = "CREATE_FIELDSTAFF"
export const CREATE_FIELDSTAFF_SUCCESS = "CREATE_FIELDSTAFF_SUCCESS"
export const CREATE_FIELDSTAFF_FAIL = "CREATE_FIELDSTAFF_FAIL"

/* Edit FieldStaff */
export const UPDATE_FIELDSTAFF = "UPDATE_FIELDSTAFF"
export const UPDATE_FIELDSTAFF_SUCCESS = "UPDATE_FIELDSTAFF_SUCCESS"
export const UPDATE_FIELDSTAFF_FAIL = "UPDATE_FIELDSTAFF_FAIL"

/* Delete FieldStaff */
export const DELETE_FIELDSTAFF = "DELETE_FIELDSTAFF"
export const DELETE_FIELDSTAFF_SUCCESS = "DELETE_FIELDSTAFF_SUCCESS"
export const DELETE_FIELDSTAFF_FAIL = "DELETE_FIELDSTAFF_FAIL"

export const GET_ALL_FIELDS_COUNT = "GET_ALL_FIELDS_COUNT"
export const GET_ALL_FIELDS_COUNT_SUCCESS = "GET_ALL_FIELDS_COUNT_SUCCESS"
export const GET_ALL_FIELDS_COUNT_FAIL = "GET_ALL_FIELDS_COUNT_FAIL"
