
/* VisitEntrys - This line cannot be edited or removed */
export const GET_VISITENTRYS = 'GET_VISITENTRYS';
export const GET_VISITENTRYS_SUCCESS = 'GET_VISITENTRYS_SUCCESS';
export const GET_VISITENTRYS_FAIL = 'GET_VISITENTRYS_FAIL';

/* VisitEntry_DETAILS */
export const GET_VISITENTRY_DETAILS = 'GET_VISITENTRY_DETAILS';
export const GET_VISITENTRY_DETAILS_SUCCESS = 'GET_VISITENTRY_DETAILS_SUCCESS';
export const GET_VISITENTRY_DETAILS_FAIL = 'GET_VISITENTRY_DETAILS_FAIL';

/* CREATE VisitEntry */
export const CREATE_VISITENTRY = 'CREATE_VISITENTRY';
export const CREATE_VISITENTRY_SUCCESS = 'CREATE_VISITENTRY_SUCCESS';
export const CREATE_VISITENTRY_FAIL = 'CREATE_VISITENTRY_FAIL';

/* Edit VisitEntry */
export const UPDATE_VISITENTRY = 'UPDATE_VISITENTRY';
export const UPDATE_VISITENTRY_SUCCESS = 'UPDATE_VISITENTRY_SUCCESS';
export const UPDATE_VISITENTRY_FAIL = 'UPDATE_VISITENTRY_FAIL';

/* Delete VisitEntry */
export const DELETE_VISITENTRY = 'DELETE_VISITENTRY';
export const DELETE_VISITENTRY_SUCCESS = 'DELETE_VISITENTRY_SUCCESS';
export const DELETE_VISITENTRY_FAIL = 'DELETE_VISITENTRY_FAIL';
