/* ORDERS */
export const GET_ORDERS = "GET_ORDERS"
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS"
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL"

/* ORDERS DETAIL*/
export const GET_ORDER_DETAIL = "GET_ORDER_DETAIL"
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS"
export const GET_ORDER_DETAIL_FAIL = "GET_ORDER_DETAIL_FAIL"

/**
 * add ORDER
 */
export const CREATE_ORDER = "CREATE_ORDER"
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS"
export const CREATE_ORDER_FAIL = "CREATE_ORDER_FAIL"

/**
 * Edit ORDER
 */
export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS"
export const UPDATE_ORDER_FAIL = "UPDATE_ORDER_FAIL"

export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM"
export const UPDATE_ORDER_ITEM_SUCCESS = "UPDATE_ORDER_ITEM_SUCCESS"
export const UPDATE_ORDER_ITEM_FAIL = "UPDATE_ORDER_ITEM_FAIL"

/**
 * Delete ORDER
 */
export const DELETE_ORDER = "DELETE_ORDER"
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS"
export const DELETE_ORDER_FAIL = "DELETE_ORDER_FAIL"
