import { map } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { Col, Card, CardBody, Spinner } from "reactstrap"

const MiniWidget = () => {
  const { dashboardLoading, dashboardData, dashboardData2 } = useSelector(
    state => ({
      dashboardLoading: state.Dashboard.dashboardLoading,
      dashboardData: state.Dashboard.dashboardData,
      dashboardData2: state.Dashboard.dashboardData2,
    })
  )

  const analatics = [
    {
      icon: "bx bxs-notepad",
      title: "LOHA Field App",
      value: dashboardData2?.total_visits,
      // badgeValue: dashboardData2?.total_visits,
      color: "light",
      desc: "",
      background: "rgba(231 231 253)",
      text: "Visit Entries Today",
      warningMsg: "",
      msgColor: "success",
      loading: dashboardLoading,
      linkPath: "/fields",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Chartered Orders",
      value: dashboardData?.chartered_orders,
      badgeValue: dashboardData?.chartered_orders,
      color: "light",
      desc: "Chartered Works Today",
      warningMsg: "No Chartered Work Today",
      msgColor: "info",
      loading: dashboardLoading,
      linkPath: "/orders",
    },
    {
      icon: "bx bx-copy-alt",
      title: "Wage Paid Today",
      value: (
        <>
          <i className="fas fa-rupee-sign mx-1 font-size-17 "></i>
          {dashboardData?.wage_paid_per_day}
        </>
      ),
      badgeValue: dashboardData?.no_of_transactions,
      color: "success",
      desc: "Transaction",
      warningMsg: "No Transaction Today",
      msgColor: "info",
      loading: dashboardLoading,
      linkPath: "",
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Out Of Stock",
      value: dashboardData?.out_of_stock_items?.length,
      badgeValue: dashboardData?.out_of_stock_items?.length,
      color: "light",
      desc: "",
      warningMsg: "Stocks Available",
      msgColor: "success",
      loading: dashboardLoading,
      linkPath: "/sttore",
    },

    // {
    //   icon: "bx bx-copy-alt",
    //   title: "Pending Orders",
    //   value: dashboardData?.pending_orders,
    //   badgeValue: dashboardData?.total_orders,
    //   color: "success",
    //   desc: "Totel Orders",
    //   warningMsg: "",
    //   msgColor: "info",
    //   loading: dashboardLoading,
    //   linkPath: "/orders",
    // },
    // {
    //   icon: "bx bx-archive-in",
    //   title: "Finished Products",
    //   value: dashboardData?.finished_product,
    //   badgeValue: "",
    //   color: "success",
    //   desc: "Finished Orders",
    //   warningMsg: `${new Date().getFullYear()}- ${
    //     new Date().getFullYear() + 1
    //   }`,
    //   msgColor: "info",
    //   loading: dashboardLoading,
    //   linkPath: "/products",
    // },
    // {
    //   icon: "bx bx-purchase-tag-alt",
    //   title: "Store",
    //   value: dashboardData?.total_store_item,
    //   badgeValue: dashboardData?.out_of_stock_items?.length,
    //   color: "warning",
    //   desc: "Out Of Stock Item",
    //   warningMsg: "Stocks Available",
    //   msgColor: "success",
    //   loading: dashboardLoading,
    //   linkPath: "/sttore",
    // },
  ]
  return (
    <React.Fragment>
      {map(analatics, (report, index) => (
        <Col sm="6" key={index}>
          <Link to={report.linkPath}>
            <Card
              style={{ minHeight: 148, backgroundColor: report?.background }}
            >
              <CardBody>
                <Link
                  to={report.linkPath}
                  className="d-flex align-items-center mb-3"
                >
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className={report.icon} />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </Link>
                {report.loading ? (
                  <Spinner type="grow" color="info" />
                ) : (
                  <div className="text-muted mt-4">
                    <h4>
                      {report.value}
                      <i className="mdi mdi-chevron-up ms-1 text-success" />
                    </h4>
                    <div className="d-flex">
                      {report.badgeValue > 0 ? (
                        <span
                          className={
                            "badge badge-soft-" +
                            `${
                              report.badgeValue > 0
                                ? report.color
                                : report.msgColor
                            }` +
                            " font-size-12"
                          }
                        >
                          {report.badgeValue}
                        </span>
                      ) : (
                        <span className="text-info">
                          {report.warningMsg
                            ? report?.warningMsg
                            : report?.text}
                        </span>
                      )}
                      {report.badgeValue > 0 && (
                        <span className="ms-2 text-truncate">
                          {report.desc}
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Link>
        </Col>
      ))}
    </React.Fragment>
  )
}

MiniWidget.propTypes = {
  analatics: PropTypes.array,
}

export default MiniWidget
