import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

//actions
import { createFieldStaff, updateFieldStaff } from "store/actions"

const Form = ({ show, onCloseClick, data }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { loading, createSheeterror } = useSelector(state => ({
    createSheeterror: state.Sheet.createSheeterror,
    loading: state.Sheet.loading,
  }))
  function handleValidSubmit(values) {
    console.log(values)
    if (data?.id) {
      const updateValues = {
        ...values,
      }
      dispatch(updateFieldStaff(updateValues, data?.id, onCloseClick))
    } else {
      const createValues = {
        ...values,
      }
      dispatch(createFieldStaff(createValues, onCloseClick))
    }
  }

  console.log(data)

  return (
    <>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="">
          <Row>
            <ModalHeader>
              <p>{data?.id ? "Update Field Staff" : "Create Field Staff"}</p>
            </ModalHeader>
            <div className="p-3">
              <AvForm
                className="form-horizontal "
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
              >
                {createSheeterror && (
                  <Alert color="danger">{createSheeterror}</Alert>
                )}
                <div className="row mb-2">
                  <AvField
                    name="full_name"
                    className="form-control"
                    id="thick-input"
                    type="text"
                    label="Full Name"
                    value={data?.full_name}
                  />{" "}
                </div>
                <div className="row mb-2">
                  <AvField
                    label="Email"
                    name="email"
                    type="email"
                    className="form-control"
                    id="horizontal-location-Input"
                    value={data?.email}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "email is required",
                      },
                    }}
                  />
                </div>

                <div className="row mb-2">
                  <AvField
                    id="horizontal-username-Input"
                    name="username"
                    type="text"
                    label="UserName"
                    value={data?.username}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: "username is required",
                      },
                    }}
                  />
                </div>

                <div className="row mb-2">
                  <AvField
                    label="Phone"
                    id="horizontal-email-Input"
                    name="phone"
                    className="form-control"
                    type="number"
                    value={data?.phone}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: " phone is required",
                      },
                      pattern: {
                        value: "^[0-9]+$",
                        errorMessage: "Your name must be composed only numbers",
                      },
                      minLength: {
                        value: 10,
                        errorMessage: "Your name must be between 10 characters",
                      },
                      maxLength: {
                        value: 10,
                        errorMessage: "Your name must be between 10 characters",
                      },
                    }}
                  />
                </div>
                {/* <div className="row mb-2">
                  <AvField
                    id="horizontal-username-Input"
                    name="address"
                    type="textarea"
                    label="Address"
                    value={data?.address ?? ""}
                    validate={{
                      required: {
                        value: false,
                        errorMessage: "username is required",
                      },
                    }}
                  />
                </div> */}

                <div className="d-flex align-items-center justify-content-end">
                  <Button
                    color="danger"
                    className="w-md"
                    onClick={onCloseClick}
                  >
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    Cancel
                  </Button>
                  <Button type="submit" color="success" className="w-md mx-1">
                    {loading && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    {data?.id ? "Update" : " Create"}
                  </Button>
                </div>
              </AvForm>
            </div>
          </Row>
        </ModalBody>
      </Modal>{" "}
    </>
  )
}

export default Form

Form.propTypes = {
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
  data: PropTypes.object,
}
