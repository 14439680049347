import React, { useEffect, useState } from "react"
import { MetaTags } from "react-meta-tags"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"
import { getVisitEntryDetails, updateVisitEntry } from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import Form from "./Form"
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min"
import moment from "moment"

function Update() {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [selectedFieldStaff, setSelectedFieldStaff] = useState(
    "Select FieldStaff..."
  )
  const [selectedFieldStaffId, setSelectedFieldStaffId] = useState("")
  const [selectedFieldStaffSearch, setSelectedFieldStaffSearch] = useState("")
  const [statusValue, setStatusValue] = useState()

  const { visitEntryDetail, loading } = useSelector(state => ({
    visitEntryDetail: state.VisitEntry.visitentryDetails,
    loading: state?.VisitEntry?.loading,
  }))

  const stateData = {
    selectedFieldStaff,
    setSelectedFieldStaff,
    selectedFieldStaffId,
    setSelectedFieldStaffId,
    selectedFieldStaffSearch,
    setSelectedFieldStaffSearch,
    statusValue,
    setStatusValue,
  }
  const fieldSiteId = localStorage.getItem("fieldSiteId")

  useEffect(() => {
    if (params?.id) {
      dispatch(getVisitEntryDetails(params?.id))
    }
  }, [dispatch, params?.id])

  const handleValidSubmit = v => {
    const checkIn1 = v?.location.check_in_location[0]
    const checkIn2 = v?.location.check_in_location[1]

    const checkOut1 = v?.location.check_out_location[0]
    const checkOut2 = v?.location.check_out_location[1]
    const updateVisitEntryData = {
      ...v,
      field_staff: selectedFieldStaffId,
      fieldsite: fieldSiteId ? fieldSiteId : visitEntryDetail?.fieldsite,
      check_in_date: moment(v?.check_in_date).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
      ),
      check_out_date: moment(v?.check_out_date).format(
        "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
      ),
      status: statusValue,
      duration: v?.duration,
      check_in_location: `(${checkIn1},${checkIn2})`,
      check_out_location: `(${checkOut1},${checkOut2})`,
    }

    console.log(updateVisitEntryData)
    dispatch(
      updateVisitEntry(updateVisitEntryData, visitEntryDetail?.id, history)
    )
  }

  return (
    <div>
      <div>
        <MetaTags>
          <title>Visit Entry | Loha </title>
        </MetaTags>
        <div className="page-content">
          <Breadcrumbs title="Visit Entry" breadcrumbItem="Update" />
          <Container fluid>
            <div className="container-fluid">
              <AvForm
                onValidSubmit={(e, v) => {
                  handleValidSubmit(v)
                }}
              >
                <Row>
                  <Col md="8" lg="8">
                    <Card>
                      <CardBody>
                        <Form states={stateData} isUpdate={visitEntryDetail} />
                        <div className="d-flex align-items-center justify-content-end">
                          <Button
                            type="submit"
                            color="success"
                            className="w-md mx-1"
                          >
                            {loading && (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            )}
                            Update
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Container>
        </div>
      </div>
    </div>
  )
}

export default Update
