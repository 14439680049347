/* SUPERVISORS */
export const GET_SUPERVISORS = "GET_SUPERVISORS"
export const GET_SUPERVISORS_SUCCESS = "GET_SUPERVISORS_SUCCESS"
export const GET_SUPERVISORS_FAIL = "GET_SUPERVISORS_FAIL"

/* SUPERVISORS DETAIL*/
export const GET_SUPERVISOR_DETAIL = "GET_SUPERVISOR_DETAIL"
export const GET_SUPERVISOR_DETAIL_SUCCESS = "GET_SUPERVISOR_DETAIL_SUCCESS"
export const GET_SUPERVISOR_DETAIL_FAIL = "GET_SUPERVISOR_DETAIL_FAIL"

/**
 * add SUPERVISOR
 */
export const CREATE_SUPERVISOR = "CREATE_SUPERVISOR"
export const CREATE_SUPERVISOR_SUCCESS = "CREATE_SUPERVISOR_SUCCESS"
export const CREATE_SUPERVISOR_FAIL = "CREATE_SUPERVISOR_FAIL"

/**
 * Edit SUPERVISOR
 */
export const UPDATE_SUPERVISOR = "UPDATE_SUPERVISOR"
export const UPDATE_SUPERVISOR_SUCCESS = "UPDATE_SUPERVISOR_SUCCESS"
export const UPDATE_SUPERVISOR_FAIL = "UPDATE_SUPERVISOR_FAIL"

/**
 * Delete SUPERVISOR
 */
export const DELETE_SUPERVISOR = "DELETE_SUPERVISOR"
export const DELETE_SUPERVISOR_SUCCESS = "DELETE_SUPERVISOR_SUCCESS"
export const DELETE_SUPERVISOR_FAIL = "DELETE_SUPERVISOR_FAIL"

/* SUPERVISORS DETAIL*/
export const GET_BALANCETRANSACTIONS = "GET_BALANCETRANSACTIONS"
export const GET_BALANCETRANSACTIONS_SUCCESS = "GET_BALANCETRANSACTIONS_SUCCESS"
export const GET_BALANCETRANSACTIONS_FAIL = "GET_BALANCETRANSACTIONS_FAIL"

export const BALANCEWITHDRAW = "BALANCEWITHDRAW"
export const BALANCEWITHDRAW_SUCCESS = "BALANCEWITHDRAW_SUCCESS"
export const BALANCEWITHDRAW_FAIL = "BALANCEWITHDRAW_FAIL"

export const GET_SCHEDULEEVENTS = "GET_SCHEDULEEVENTS"
export const GET_SCHEDULEEVENTS_SUCCESS = "GET_SCHEDULEEVENTS_SUCCESS"
export const GET_SCHEDULEEVENTS_FAIL = "GET_SCHEDULEEVENTS_FAIL"

export const GET_DAILY_WORK = "GET_DAILY_WORK"
export const GET_DAILY_WORK_SUCCESS = "GET_DAILY_WORK_SUCCESS"
export const GET_DAILY_WORK_FAIL = "GET_DAILY_WORK_FAIL"

export const UPDATE_DAILY_WORK = "UPDATE_DAILY_WORK"
export const UPDATE_DAILY_WORK_SUCCESS = "UPDATE_DAILY_WORK_SUCCESS"
export const UPDATE_DAILY_WORK_FAIL = "UPDATE_DAILY_WORK_FAIL"

export const CREATE_DAILY_WORK = "CREATE_DAILY_WORK"
export const CREATE_DAILY_WORK_SUCCESS = "CREATE_DAILY_WORK_SUCCESS"
export const CREATE_DAILY_WORK_FAIL = "CREATE_DAILY_WORK_FAIL"

export const DELETE_DAILY_WORK = "DELETE_DAILY_WORK"
export const DELETE_DAILY_WORK_SUCCESS = "DELETE_DAILY_WORK_SUCCESS"
export const DELETE_DAILY_WORK_FAIL = "DELETE_DAILY_WORK_FAIL"
