import { call, put, takeEvery } from "redux-saga/effects"

import {
  CREATE_SHEET,
  DELETE_SHEET,
  GET_SHEETS,
  GET_SHEET_DETAIL,
  UPDATE_SHEET,

  CREATE_SHEET_ORDER,
  DELETE_SHEET_ORDER,
  GET_SHEET_ORDERS,
  GET_SHEET_ORDER_DETAIL,
  UPDATE_SHEET_ORDER,
  DELETE_SHEET_ORDER_FAIL,
  ADD_SHEET_QUANTITY,
  GET_SHEET_TOTAL_WEIGHT,
  GET_SHEET_ORDERS_WEIGHT

} from "./actionTypes"
import {
  getSheetsSuccess,
  getSheetsFail,
  updateSheetFail,
  getSheetDetailSuccess,
  getSheetDetailFail,
  createSheetFail,
  createSheetSuccess,
  updateSheetSuccess,
  deleteSheetSuccess,
  deleteSheetFail,
  getSheetTotalWeight,

  getSheetOrdersSuccess,
  getSheetOrdersFail,
  updateSheetOrderFail,
  getSheetOrderDetailSuccess,
  getSheetOrderDetailFail,
  createSheetOrderFail,
  createSheetOrderSuccess,
  updateSheetOrderSuccess,
  deleteSheetOrderSuccess,
  deleteSheetOrderFail,
  addSheetQuantity,
  getSheetTotalWeightSuccess,
  getSheetTotalWeightFail,
  getSheetOrdersWeightSuccess,
  getSheetOrdersWeightFail,

} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
} from "components/Common/Notification"



function getSheetsAPi({ searchText,thick,width, page }) {
    return get(`/sheet/sheet/?search=${searchText && searchText}&thick=${thick && thick}&width=${width && width}`)
}

// function getSheetOrdersAPi({ searchText,orderType, page }) {
//   if (searchText) {
//     return get(`/sheet/sheet_order/?search=${searchText && searchText}&order_type=${orderType && orderType}`)
//   } else {
//     return get(`/sheet/sheet_order/?page=${page ? page : 1}&order_type=${orderType && orderType}`)
//   }
// }

const getSheetDetailsAPi = id => {
  return get(`/sheet/sheet/${id}/`)
}
const createSheetApi = ({ data }) => {
  console.log("data", data);
  return post("/sheet/sheet/", data)
}
const updateSheetApi = ({ id, data, isUpdate }) => {
  console.log(id);
  if (isUpdate === "isUpdate") {
    return patch(`/sheet/sheet/${id}/`, data)
  } else {
    return ApiPut(`/sheet/sheet/${id}/`, data)
  }
}
const deleteSheetApi = ({ id }) => {
  return del(`/sheet/sheet/${id}/`)
}
const getSheetTotalWeightApi = ({ thick,width })  => {
  return get(`/sheet/sheet_total_weight/?thick=${thick && thick}&width=${width && width}`)
}




function* fetchSheets({ payload }) {
  console.log("fetchSheets : ",payload);
  try {
    const response = yield call(getSheetsAPi, payload)
    yield put(getSheetsSuccess(response,payload["thick"]))
  } catch (error) {
    yield put(getSheetsFail(error))
  }
}

function* fetchSheetDetail({ id }) {
  try {
    const response = yield call(getSheetDetailsAPi, id)
    yield put(getSheetDetailSuccess(response))
  } catch (error) {
    yield put(getSheetDetailFail(error))
  }
}
function* onCreateSheet({ payload }) {
  console.log(payload);
  try {
    const response = yield call(createSheetApi, payload)
    console.log(response);
    if (response?.error_message) {
      yield put(createSheetFail(response?.error_message))
    } else {
      yield put(createSheetSuccess(response))
      payload.history.push("/sheets")
      doneNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(createSheetFail(error))
  }
}

function* onUpdateSheet({ payload }) {
  console.log(payload);
  try {
    const response = yield call(updateSheetApi, payload)
    yield put(updateSheetSuccess(response))
    if (payload.history) {
      payload.history.push("/sheets")
    }
    doneNotification()
  } catch (error) {
    yield put(updateSheetFail(error))
    errorNotification()
  }
}


function* onDeleteSheet({ payload }) {
  try {
    const response = yield call(deleteSheetApi, payload)
    yield put(deleteSheetSuccess({ ...response, id: payload.id }))
    doneNotification()
  } catch (error) {
    yield put(deleteSheetFail(error))
    errorNotification()
  }
}

function* onGetSheetTotalWeight({ payload }) {
  try {
    const response = yield call(getSheetTotalWeightApi,payload)
    console.log(response);
    yield put(getSheetTotalWeightSuccess(response ))
    doneNotification()
  } catch (error) {
    yield put(getSheetTotalWeightFail(error))
    errorNotification()
  }
}





function getSheetOrdersAPi({ searchText,orderType, page }) {
  if (searchText) {
    return get(`/sheet/sheet_order/?search=${searchText && searchText}&order_type=${orderType && orderType}`)
  } else {
    return get(`/sheet/sheet_order/?page=${page ? page : 1}&order_type=${orderType && orderType}`)
  }
}


const getSheetOrderDetailsAPi = id => {
  return get(`/sheet/sheet_order/${id}/`)
}
const createSheetOrderApi = ({ data }) => {
  console.log("data", data);
  return post("/sheet/sheet_order/", data)
}
const updateSheetOrderApi = ({ id, data, isUpdate }) => {
  console.log(id);
  if (isUpdate === "isUpdate") {
    return patch(`/sheet/sheet_order/${id}/`, data)
  } else {
    return ApiPut(`/sheet/sheet_order/${id}/`, data)
  }
}
const deleteSheetOrderApi = ({ id }) => {
  return del(`/sheet/sheet_order/${id}/`)
}


function getSheetOrdersWeightAPi({ from,to,orderType }) {
  return get(`/sheet/sheet_orders_total_weight/?from=${from && from}&to=${to&&to}&orderType=${orderType&&orderType}`)
}




function* fetchSheetOrders({ payload }) {
  try {
    const response = yield call(getSheetOrdersAPi, payload)
    yield put(getSheetOrdersSuccess(response))
  } catch (error) {
    yield put(getSheetOrdersFail(error))
  }
}

function* fetchSheetOrderDetail({ id }) {
  try {
    const response = yield call(getSheetOrderDetailsAPi, id)
    yield put(getSheetOrderDetailSuccess(response))
  } catch (error) {
    yield put(getSheetOrderDetailFail(error))
  }
}
function* onCreateSheetOrder({ payload }) {
  console.log(payload);
  try {
    const response = yield call(createSheetOrderApi, payload)
    console.log(response);
    if (response?.error_message) {
      yield put(createSheetOrderFail(response?.error_message))
    } else {
      yield put(createSheetOrderSuccess(response))
      payload.history.push("/sheets/sheetorders")
      doneNotification()
    }
  } catch (error) {
    errorNotification()
    yield put(createSheetOrderFail(error))
  }
}

function* onUpdateSheetOrder({ payload }) {
  console.log(payload);
  try {
    const response = yield call(updateSheetOrderApi, payload)
    yield put(updateSheetOrderSuccess(response))
    if (payload.history) {
      payload.history.push("/sheets")
    }
    doneNotification()
  } catch (error) {
    yield put(updateSheetOrderFail(error))
    errorNotification()
  }
}


function* onDeleteSheetOrder({ payload }) {
  try {
    const response = yield call(deleteSheetOrderApi, payload)
    yield put(deleteSheetOrderSuccess({ ...response, id: payload.id }))
    doneNotification()
  } catch (error) {
    yield put(deleteSheetOrderFail(error))
    errorNotification()
  }
}


function* fetchSheetOrdersWeight({ payload }) {
  console.log(payload);
  try {
    const response = yield call(getSheetOrdersWeightAPi, payload)
    yield put(getSheetOrdersWeightSuccess(response))
  } catch (error) {
    yield put(getSheetOrdersWeightFail(error))
  }
}


// function* onAddSheetQuantity({ payload }) {
//   console.log(payload);
//   yield put(addSheetQuantity(payload))
// }




function* sheetSaga() {
  yield takeEvery(GET_SHEETS, fetchSheets)
  yield takeEvery(GET_SHEET_DETAIL, fetchSheetDetail)
  yield takeEvery(CREATE_SHEET, onCreateSheet)
  yield takeEvery(UPDATE_SHEET, onUpdateSheet)
  yield takeEvery(DELETE_SHEET, onDeleteSheet)
  yield takeEvery(GET_SHEET_ORDERS, fetchSheetOrders)
  yield takeEvery(GET_SHEET_ORDER_DETAIL, fetchSheetOrderDetail)
  yield takeEvery(CREATE_SHEET_ORDER, onCreateSheetOrder)
  yield takeEvery(UPDATE_SHEET_ORDER, onUpdateSheetOrder)
  yield takeEvery(DELETE_SHEET_ORDER, onDeleteSheetOrder)
  yield takeEvery(GET_SHEET_ORDERS_WEIGHT, fetchSheetOrdersWeight)
  yield takeEvery(GET_SHEET_TOTAL_WEIGHT, onGetSheetTotalWeight)

}

export default sheetSaga
