import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_SHEET_USERS,
  GET_SHEET_USER_DETAIL,
  CREATE_SHEET_USER,
  UPDATE_SHEET_USER,
  DELETE_SHEET_USER,
} from "./actionTypes"
import {
  getSheetUsersSuccess,
  getSheetUsersFail,
  getSheetUserDetailSuccess,
  getSheetUserDetailFail,
  createSheetUserSuccess,
  createSheetUserFail,
  updateSheetUserSuccess,
  updateSheetUserFail,
  deleteSheetUserSuccess,
  deleteSheetUserFail,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import {
  doneNotification,
  errorNotification,
  Notification,
} from "components/Common/Notification"

const getSheetUsersAPi = ({ searchText, page }) => {
  if (searchText) {
    return get(`/account/sheetuser/?search=${searchText && searchText}`)
  } else {
    return get(`/account/sheetuser/?page=${page ? page : 1}`)
  }
}
const getSheetUserDetailsAPi = sheetuserId => {
  return get(`/account/sheetuser/${sheetuserId}/`)
}
const createSheetUserApi = ({ sheetuser }) => {
  return post("/account/sheetuser/", sheetuser)
}
const updateSheetUserApi = ({ sheetuserId, sheetuser }) => {
  return ApiPut(`/account/sheetuser/${sheetuserId}/`, sheetuser)
}
const deleteSheetUserApi = ({ sheetuserId }) => {
  return del(`/account/sheetuser/${sheetuserId}/`)
}

function* fetchSheetUsers({ payload }) {
  try {
    const response = yield call(getSheetUsersAPi, payload)
    yield put(getSheetUsersSuccess(response))
  } catch (error) {
    // yield put(getSheetUsersFail(error))
  }
}

function* fetchSheetUserDetail({ sheetuserId }) {
  try {
    const response = yield call(getSheetUserDetailsAPi, sheetuserId)
    yield put(getSheetUserDetailSuccess(response))
  } catch (error) {
    yield put(getSheetUserDetailFail(error))
  }
}
function* onCreateSheetUser({ payload }) {
  try {
    const response = yield call(createSheetUserApi, payload)
    if (response?.error_message) {
      yield put(createSheetUserFail(response))
    } else {
      yield put(createSheetUserSuccess(response))
      payload.history.push("/sheetusers")
      Notification({
        type: "success",
        message: "Successfully Created SheetUser",
        title: "Created!",
      })
    }
  } catch (error) {
    yield put(createSheetUserFail(error))
    errorNotification()
  }
}

function* onUpdateSheetUser({ payload }) {
  try {
    const response = yield call(updateSheetUserApi, payload)
    if (response?.error_message) {
      yield put(updateSheetUserFail(response))
    } else {
      yield put(updateSheetUserSuccess(response))
      doneNotification()
    }
  } catch (error) {
    console.log(error)
    yield put(updateSheetUserFail(error))
    errorNotification()
  }
}

function* onDeleteSheetUser({ payload }) {
  try {
    const response = yield call(deleteSheetUserApi, payload)
    yield put(deleteSheetUserSuccess({ ...response, id: payload.sheetuserId }))
    payload.history.push("/sheetusers")
    doneNotification()
  } catch (error) {
    yield put(deleteSheetUserFail(error))
    errorNotification()
  }
}

function* sheetusersSaga() {
  yield takeEvery(GET_SHEET_USERS, fetchSheetUsers)
  yield takeEvery(GET_SHEET_USER_DETAIL, fetchSheetUserDetail)
  yield takeEvery(CREATE_SHEET_USER, onCreateSheetUser)
  yield takeEvery(UPDATE_SHEET_USER, onUpdateSheetUser)
  yield takeEvery(DELETE_SHEET_USER, onDeleteSheetUser)
}

export default sheetusersSaga
