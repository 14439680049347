import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FIELDSITES,
  GET_FIELDSITE_DETAILS,
  CREATE_FIELDSITE,
  UPDATE_FIELDSITE,
  DELETE_FIELDSITE,
  GET_GMAP_PKEY,
  SEARCH_LOCATION,
} from "./actionTypes"
import {
  getFieldSitesFail,
  getFieldSitesSuccess,
  getFieldSiteDetailsSuccess,
  getFieldSiteDetailsFail,
  createFieldSiteFail,
  createFieldSiteSuccess,
  updateFieldSiteSuccess,
  updateFieldSiteFail,
  deleteFieldSiteSuccess,
  deleteFieldSiteFail,
  getMapPublicKeySuccess,
  getMapPublicKeyFail,
  searchPlacesFail,
  searchPlacesSuccess,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"

// FieldSite - This line cannot be edited or removed
function getFieldSitesAPi({ page, sort, limit, searchText }) {
  return get(
    `/fieldsite/fieldsite?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const getFieldSiteDetailsAPi = fieldsiteId => {
  return get(`/fieldsite/fieldsite/${fieldsiteId}/`)
}

const createFieldSiteApi = ({ fieldsite }) => {
  return post("/fieldsite/fieldsite/", fieldsite)
}

const updateFieldSiteApi = ({ fieldsite, fieldsiteId }) => {
  return ApiPut(`/fieldsite/fieldsite/${fieldsiteId}/`, fieldsite)
}

const deleteFieldSiteApi = fieldsiteId => {
  return del(`/fieldsite/fieldsite/${fieldsiteId}/`)
}

function getGmapKeyAPi() {
  return get("/fieldsite/google-map")
}
function getSearchLocationAPi(query) {
  return get(`/fieldsite/google-map-search?query=${query}`)
}

function* fetchFieldSites({ payload }) {
  try {
    const response = yield call(getFieldSitesAPi, payload)
    yield put(getFieldSitesSuccess(response))
  } catch (error) {
    yield put(getFieldSitesFail(error))
  }
}

function* fetchFieldSiteDetails({ payload: fieldsiteId }) {
  try {
    const response = yield call(getFieldSiteDetailsAPi, fieldsiteId)
    yield put(getFieldSiteDetailsSuccess(response))
  } catch (error) {
    yield put(getFieldSiteDetailsFail(error))
  }
}

function* onCreateFieldSite({ payload }) {
  try {
    const response = yield call(createFieldSiteApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createFieldSiteSuccess(response))
      payload?.history?.push("/fieldsite")
      doneNotification("FieldSite Created Successfully!")
    }
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createFieldSiteFail(error))
  }
}

function* onUpdateFieldSite({ payload }) {
  try {
    const response = yield call(updateFieldSiteApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "FieldSite Updated Successfully!",
        title: "",
      })
      yield put(updateFieldSiteSuccess(response))
      if (payload.history) {
        payload?.history.goBack()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateFieldSiteFail(error.response))
  }
}

function* onDeleteFieldSite({ payload: { fieldsiteId, history } }) {
  try {
    const response = yield call(deleteFieldSiteApi, fieldsiteId)
    yield put(deleteFieldSiteSuccess(fieldsiteId))
    if (history) {
      history.goBack()
    }

    Notification({
      type: "success",
      message: "FieldSite Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteFieldSiteFail(error?.response?.data))
  }
}

function* fetchGmapKey() {
  try {
    const response = yield call(getGmapKeyAPi)
    yield put(getMapPublicKeySuccess(response))
  } catch (error) {
    yield put(getMapPublicKeyFail(error))
  }
}
function* searchPlaces({ payload }) {
  try {
    const response = yield call(getSearchLocationAPi, payload)
    yield put(searchPlacesSuccess(response))
  } catch (error) {
    yield put(searchPlacesFail(error))
  }
}

function* fieldsiteSaga() {
  yield takeEvery(GET_FIELDSITES, fetchFieldSites)
  yield takeEvery(GET_FIELDSITE_DETAILS, fetchFieldSiteDetails)
  yield takeEvery(CREATE_FIELDSITE, onCreateFieldSite)
  yield takeEvery(UPDATE_FIELDSITE, onUpdateFieldSite)
  yield takeEvery(DELETE_FIELDSITE, onDeleteFieldSite)

  yield takeEvery(GET_GMAP_PKEY, fetchGmapKey)
  yield takeEvery(SEARCH_LOCATION, searchPlaces)
}

export default fieldsiteSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
