import {
  GET_VISITENTRYS,
  GET_VISITENTRYS_SUCCESS,
  GET_VISITENTRYS_FAIL,
  GET_VISITENTRY_DETAILS,
  GET_VISITENTRY_DETAILS_SUCCESS,
  GET_VISITENTRY_DETAILS_FAIL,
  CREATE_VISITENTRY,
  CREATE_VISITENTRY_SUCCESS,
  CREATE_VISITENTRY_FAIL,
  UPDATE_VISITENTRY,
  UPDATE_VISITENTRY_SUCCESS,
  UPDATE_VISITENTRY_FAIL,
  DELETE_VISITENTRY,
  DELETE_VISITENTRY_SUCCESS,
  DELETE_VISITENTRY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  visitentrys: {
    visitentrys: [],
    total: "",
    page: "",
  },
  visitentryDetails: {},
  error: {},
  loading: false,
}

const VisitEntry = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_VISITENTRYS:
    case GET_VISITENTRY_DETAILS:
    case CREATE_VISITENTRY:
    case UPDATE_VISITENTRY:
    case DELETE_VISITENTRY:
      return {
        ...state,
        loading: true,
      }

    case DELETE_VISITENTRY_FAIL:
    case GET_VISITENTRYS_FAIL:
    case GET_VISITENTRY_DETAILS_FAIL:
    case UPDATE_VISITENTRY_FAIL:
    case CREATE_VISITENTRY_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VISITENTRYS_SUCCESS:
      return {
        ...state,
        visitentrys: action.payload,
        error: {},
        loading: false,
      }

    case GET_VISITENTRY_DETAILS_SUCCESS:
      return {
        ...state,
        visitentryDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_VISITENTRY_SUCCESS:
      console.log(state)
      return {
        ...state,
        visitentrys: {
          ...state.visitentrys,
          results: [...state?.visitentrys?.results, action.payload],
          total: state.visitentrys.total + 1,
        },
        visitentryDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_VISITENTRY_SUCCESS:
      return {
        ...state,
        visitentrys: {
          ...state.visitentrys,
          results: state.visitentrys.results?.map(visitentry =>
            visitentry._id === action.payload._id
              ? { ...visitentry, ...action.payload }
              : visitentry
          ),
        },
        visitentryDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_VISITENTRY_SUCCESS:
      console.log(state)
      return {
        ...state,
        visitentrys: action.payload,
        ...state.visitentrys,
        results: state?.visitentrys?.results?.filter(
          visitentry => visitentry.visitentryId !== action.payload.visitentryId
        ),

        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default VisitEntry
