import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Spinner,
  Button,
  CardTitle,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range, set } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import {
  deleteFieldStaff,
  deleteSheet,
  getFieldStaffs,
  getSheets,
  getSheetTotalWeight,
  updateSheet,
} from "store/actions"

import DeleteModal from "components/Common/DeleteModal"
import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import AvForm from "availity-reactstrap-validation/lib/AvForm"
import AvField from "availity-reactstrap-validation/lib/AvField"
import Form from "./Crud/Form"

const FieldStaffs = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [isOpen, setIsOpen] = useState({ state: false, id: "" })
  const [isOpenForm, setIsOpenForm] = useState({ status: false, data: "" })

  const { allFieldStaffs, loading } = useSelector(state => ({
    allFieldStaffs: state?.FieldStaff?.fieldstaffs,
    loading: state?.FieldStaff?.loading,
  }))

  console.log(allFieldStaffs)

  const totalPages = Math.ceil(allFieldStaffs?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getFieldStaffs(pageSend(), "", "", searchText))
  }, [dispatch, page, searchText])

  // const handleReload = () => {
  //   dispatch(getSheets(searchText, pageSend()))
  //   setReload(false)
  // }

  const handleDelete = id => {
    setIsOpen({ ...isOpen, ["state"]: true, id: id })
  }
  const onCloseClick = () => {
    setIsOpen({ state: false })
  }

  const handleDeleteEvent = () => {
    dispatch(deleteFieldStaff(isOpen.id, onCloseClick))
    setIsOpen({ ...isOpen, status: false })
  }
  console.log(isOpen?.id)

  const columns = [
    {
      dataField: "no",
      text: "#",
      sort: true,
    },
    {
      dataField: "username",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "full_name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "email",
      text: "E-mail",
      sort: true,
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
  ]
  const handleShowUpdateForm = item => {
    console.log(item)
    setIsOpenForm({ status: true, data: item })
  }

  // const handleValidSubmit = (onSubmitProps, values, sheetId) => {
  //   dispatch(updateSheet(values, sheetId, "", "isUpdate"))
  // }

  const fieldStaffData = map(allFieldStaffs?.results, (item, index) => ({
    ...item,
    key: index,
    no: <span>{index + 1}</span>,
    action: (
      <div className="d-flex">
        <Link to={`/fieldstaff/${item?.id}`}>
          <div>
            <i className="bx bx-pen text-success font-size-15"></i>
          </div>
        </Link>
        <div title="Remove">
          <i
            style={{ cursor: "pointer" }}
            className="bx bx-trash text-danger font-size-15 px-4"
            onClick={() => handleDelete(item?.id)}
          ></i>
        </div>
      </div>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  const handleShowCreateForm = () => {
    setIsOpenForm({ status: true, data: "" })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen.state}
        onCloseClick={() => setIsOpen({ ...isOpen, ["state"]: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <Form
        show={isOpenForm?.status}
        onCloseClick={() => setIsOpenForm({ ...isOpenForm, status: false })}
        data={isOpenForm?.data}
      />

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={fieldStaffData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="8">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>{" "}
                          </div>
                        </div>
                      </Col>
                      <Col
                        md="4 d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "right",
                        }}
                      >
                        <div>
                          <Button
                            type="button"
                            color="success"
                            onClick={() => handleShowCreateForm()}
                          >
                            Create Field Staff
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : allFieldStaffs?.results?.length > 0 ? (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    ) : (
                      <p>No Data</p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default FieldStaffs
