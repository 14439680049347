import React, { useEffect, useState } from "react"
import { Table } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { map } from "lodash"

import logo from "../../assets/images/logo/LOHA.png"
import { updateOrderItem } from "store/actions"
import { useHistory } from "react-router-dom"

const PDFGenerator = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { orderDetail, orderitem, loading, orderitemLoading } = useSelector(
    state => ({
      orderDetail: state.Orders.orderDetail,
      orderitem: state.Orders.orderDetail.orderitem,
      loading: state.Orders.loading,
      orderitemLoading: state.Orders.orderitemLoading,
    })
  )

  const [showEditInput, setShowEditInput] = useState("")
  const [itemQty, setItemQty] = useState("")

  //   useEffect(() => {
  //     setTimeout(() => {
  //       window.print()
  //     }, 2000)
  //   }, [])

  const download = () => {
    window.print()
  }

  function userExists(arr, name) {
    const isExist = arr.some(function (el) {
      return el.addon_name === name
    })
    return { isExist: isExist, data: arr.filter(i => i.addon_name === name)[0] }
  }

  const handleOrderItemAddon = arr => {
    const modifiedArr = arr?.filter(
      i => i?.addon_name !== "Manchary" && i?.addon_name !== "Panel"
    )
    return modifiedArr
  }

  const handleAddonTd = item => {
    if (item?.addon_name === "Manchary") {
      return <td>9</td>
    }
  }

  useEffect(() => {
    const tableWidth = document.querySelector("#myTable").offsetWidth

    // document.body.style.width = "1500px"
  }, [])

  const handleChangeQty = (e, id) => {
    setItemQty({
      ...itemQty,
      qty: e.target.value,
      itemId: id,
    })
  }

  const onSubmitQty = e => {
    e.preventDefault()
    dispatch(updateOrderItem("", itemQty?.itemId, itemQty?.qty))
    setShowEditInput("")
  }

  const handleShowInput = (id, qty) => {
    setItemQty({
      ...itemQty,
      qty: qty,
      itemId: id,
    })
    setShowEditInput(id)
  }

  return (
    <>
      {loading ? (
        <>
          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
        </>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-end">
            <div
              type="button"
              className="btn btn-outline-info d-flex display-none me-3"
              style={{
                alignItems: "center",
                width: "fit-content",
                border: "1px solid #cccc",
              }}
              onClick={() => history.push(`/order/update/${orderDetail?.id}`)}
            >
              Edit Order items
              <i className="mdi mdi-pen d-block font-size-16 mx-1"></i>
            </div>

            <div
              type="button"
              className="btn btn-outline-info d-flex display-none"
              style={{
                alignItems: "center",
                width: "fit-content",
                border: "1px solid #cccc",
              }}
              onClick={download}
            >
              PDF
              <i className="mdi mdi-download d-block font-size-16 mx-1"></i>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center py-4">
            <div className="w-33">&nbsp;</div>
            <div
              className="d-flex align-items-center w-33"
              style={{ flexDirection: "column" }}
            >
              <img
                src={logo}
                alt="image"
                // className="display-block d-none"
                height={50}
                width={200}
              />
              <p className="pt-1 mb-0 pb-0">Vengara, Malappuram</p>
              {/* <p className="m-0 p-0">DT. Kerala(32)-676304</p> */}
              <a href="tel:+919288022780">PH: +91 9288 022 780</a>
              <a href="mailto:lohasteelsales@gmail.com">
                Lohasteelsales@gmail.com
              </a>
            </div>
            <div className="w-33 d-flex align-items-center justify-content-end">
              <div className="w-fit">
                <p className="d-flex order_border mb-0 ">
                  Order No :{" "}
                  <h5 className="mx-2 mb-0">{orderDetail?.auto_id}</h5>
                </p>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-end">
            <div className="table-responsive ">
              <Table className="table table-bordered mb-0 w-50">
                <thead>
                  <tr>
                    <th>DEALER</th>
                    <th>{orderDetail?.dealer_name}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">MOB</th>
                    <td>{orderDetail?.dealer_phone}</td>
                  </tr>
                  <tr>
                    <th scope="row">ORDER DATE</th>
                    <td>
                      <span className="font-bold-500">
                        {moment(orderDetail?.date_added)?.format("DD-MM-yyyy")}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">CUSTOMER NAME</th>
                    <td>{orderDetail?.customer_name ?? "Not Entered!"}</td>
                  </tr>
                  <tr>
                    <th scope="row">DELIVERY DATE</th>
                    <td>
                      {" "}
                      {orderDetail?.end_date ? (
                        <span className="end_date_text">
                          {moment(orderDetail?.end_date)?.format("DD-MM-yyyy")}
                        </span>
                      ) : (
                        "Not Entered!"
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Table className="table table-bordered mb-0 w-75">
                <thead></thead>
                <tbody>
                  <tr>
                    <th scope="row" style={{ whiteSpace: "nowrap" }}>
                      DELIVERY SITE
                    </th>
                    <td>{orderDetail?.delivery_site ?? "Not Entered!"}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="table-responsive ">
              <Table className="table table-bordered mb-0">
                <thead>
                  <tr>
                    <th>CUTTING</th>
                    <th>BENDING</th>
                    <th>PRODUCT</th>
                    <th>PAINTING</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: "89px" }}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>

          <div className="">
            <div className="table-responsive" id="res-tab">
              <Table
                id="myTable"
                className="table table-bordered mb-0 text-center"
              >
                <thead>
                  <tr>
                    <th id="1">Sl No:</th>
                    <th id="2">Chanel Size</th>
                    <th id="2.5">Gauge</th>
                    <th id="3">Particulars</th>
                    <th id="4">WIDTH</th>
                    <th id="5">HIGHT</th>
                    <th id="6" className="wrap-nowrap">
                      <p className="mb-0 pb-0 mx-4 "> Material</p>
                    </th>
                    <th id="7">Manchary</th>
                    <th id="8">Normal Panel</th>
                    <th id="9">Arch Design</th>
                    <th id="10">Check Design</th>
                    <th id="11">Shutter Partition</th>
                    <th id="12">Glass Partition</th>
                    <th id="13">Divider</th>
                    <th id="14">Loover</th>
                    <th id="15">OpenSide</th>
                    <th id="16">Door SILL (Adipadi)</th>
                    <th id="17">Quantity</th>
                    <th id="18">✓</th>
                  </tr>
                </thead>
                <tbody>
                  {map(orderitem, (item, key) => (
                    <tr style={{ height: "89px" }} key={key}>
                      <td id="1">{key + 1}</td>
                      <td id="2" className="text-red">
                        {item?.product_details?.channel_size}
                      </td>
                      <th id="2.5">{item?.product_details?.guage}</th>

                      <td id="3">
                        {item?.product_details?.perticular}
                        {item?.product_details?.no_of_cols}
                      </td>
                      <td id="4">{item?.product_details?.width}</td>
                      <td id="5"> {item?.product_details?.height}</td>
                      <td id="6">
                        {userExists(item?.orderitemaddon, "Material")
                          ?.isExist && (
                          <p className="pb-0 mb-0 text-black font-size-10">
                            {
                              userExists(item?.orderitemaddon, "Material")?.data
                                ?.description
                            }
                          </p>
                        )}
                      </td>

                      <td id="7">
                        {userExists(item?.orderitemaddon, "Manchary")
                          ?.isExist && <span className="text-red">Yes</span>}
                      </td>
                      <td id="8">
                        {userExists(item?.orderitemaddon, "Panel")?.isExist && (
                          <span className="text-red">Yes</span>
                        )}
                      </td>
                      <td id="9">
                        {userExists(item?.orderitemaddon, "Arch Design")
                          ?.isExist && <span className="text-red">Yes</span>}
                      </td>
                      <td id="10">
                        {userExists(item?.orderitemaddon, "Check Design")
                          ?.isExist && <span className="text-red">Yes</span>}
                      </td>
                      <td id="11">
                        {userExists(item?.orderitemaddon, "Shutter Partition")
                          ?.isExist && <span className="text-red">Yes</span>}
                      </td>
                      <td id="12">
                        {userExists(item?.orderitemaddon, "Glass Partition")
                          ?.isExist && <span className="text-red">Yes</span>}
                      </td>

                      <td id="13">
                        {userExists(item?.orderitemaddon, "Divider")
                          ?.isExist && (
                          <span className="text-red">
                            Yes
                            <p className="pb-0 mb-0 text-black font-size-10">
                              {
                                userExists(item?.orderitemaddon, "Divider")
                                  ?.data?.description
                              }
                            </p>
                          </span>
                        )}
                      </td>
                      <td id="14">
                        {userExists(item?.orderitemaddon, "Loover")
                          ?.isExist && (
                          <span className="text-red">
                            Yes
                            <p className="pb-0 mb-0 text-black font-size-10">
                              {
                                userExists(item?.orderitemaddon, "Loover")?.data
                                  ?.description
                              }
                            </p>
                          </span>
                        )}
                      </td>
                      <td id="15">
                        {userExists(item?.orderitemaddon, "Open Side")
                          ?.isExist && (
                          <span className="text-red text-uppercase">
                            {
                              userExists(item?.orderitemaddon, "Open Side")
                                ?.data?.description
                            }
                          </span>
                        )}
                      </td>
                      <td id="16">
                        {userExists(item?.orderitemaddon, "Door Sill")
                          ?.isExist && (
                          <span className="text-red">
                            Yes
                            <p className="pb-0 mb-0 text-black font-size-10">
                              {
                                userExists(item?.orderitemaddon, "Door Sill")
                                  ?.data?.description
                              }
                            </p>
                          </span>
                        )}
                      </td>
                      <td
                        id="17"
                        className="d-flex h-100 align-items-center justify-content-between border-none"
                      >
                        {orderDetail?.status === "Pending" ? (
                          <>
                            {orderitemLoading ? (
                              <>
                                <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                              </>
                            ) : (
                              <>
                                {showEditInput === item?.id ? (
                                  <>
                                    <form onSubmit={onSubmitQty}>
                                      <input
                                        type="text"
                                        className="form-control mx-3"
                                        style={{ width: "100px" }}
                                        placeholder=""
                                        value={itemQty?.qty}
                                        onChange={e =>
                                          handleChangeQty(e, item.id)
                                        }
                                      />
                                    </form>
                                  </>
                                ) : (
                                  <>
                                    {item?.quantity}
                                    <i
                                      className="bx bx-pen text-success font-size-18 display-none "
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleShowInput(item.id, item?.quantity)
                                      }
                                    ></i>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          item?.quantity
                        )}
                      </td>
                      <td id="18"></td>
                    </tr>
                  ))}
                  <tr>
                    <td>&nbsp;</td>
                    <td style={{ height: "100px" }} className="wrap-nowrap">
                      COMMENT IF ANY :
                    </td>
                    <td colSpan={17} className="text-start">{orderDetail?.note}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="table-responsive d-flex align-items-center justify-content-end ">
              <Table className="table table-bordered mb-0 w-50 ">
                <thead>
                  <tr>
                    <th className="bg-light">ORDER</th>
                    <th className="bg-light">PROGRAMME</th>
                    <th className="bg-light">CUTTING</th>
                    <th className="bg-light">SUPERVISOR</th>
                    <th className="bg-light">QC</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ height: "60px" }}>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            {/* <CardSubtitle className="mb-2 mt-0">
              <div
                style={{
                  alignItems: "end",
                  justifyContent: "space-between",
                }}
                className="d-flex"
              >
                <div className="width-45">
                  <p className="text-muted mb-0 pb-0">
                    Dealer :{" "}
                    <CardTitle className="mb-0 pb-0 text-bol text-black">
                      {orderDetail?.dealer_name}
                    </CardTitle>
                  </p>
                  <p className="m-0 p-0">{orderDetail?.dealer_location}</p>
                  <p style={{ fontWeight: "bold" }}>
                    <i className="bx bx-phone-call"></i>{" "}
                    {orderDetail?.dealer_phone}
                  </p>
                </div>

                <div style={{ width: "fit-content" }}>
                  <p className="mb-0 ">
                    Order date :{" "}
                    <span className="font-bold-500">
                      {moment(orderDetail?.date_added)?.format("DD-MM-yyyy")}
                    </span>
                  </p>
                  <p>
                    Delivery Date :{" "}
                    <span className="end_date_text">
                      {moment(orderDetail?.end_date)?.format("DD-MM-yyyy")}
                    </span>
                  </p>
                </div>
              </div>
            </CardSubtitle> */}

            {/* {map(orderitem, (item, key) => (
              <div className="pt-2 pb-2 mt-0" key={key}>
                <div
                  style={{
                    border: "1px solid #0000001f",
                    padding: "1rem",
                  }}
                >
                  <div
                    className="d-flex "
                    style={{ justifyContent: "space-between" }}
                  >
                    <div className="d-flex">
                      <p className="mx-2">{key + 1}</p>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Serial Number</p>
                        <CardTitle className="color-blue">
                          {item?.product_details?.name}{" "}
                          {item?.product_details?.serial_number}
                        </CardTitle>
                      </div>

                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Channel Size </p>
                        <CardTitle className="mb-0 color-blue">
                          {item?.product_details?.channel_size}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Guage</p>
                        <CardTitle className="mb-0 color-blue">
                          {item?.product_details?.guage}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Column</p>
                        <CardTitle className="color-blue">
                          {item?.product_details?.perticular}{" "}
                          {item?.product_details?.no_of_cols}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Height</p>
                        <CardTitle className="mb-0 color-blue">
                          {item?.product_details?.height}
                        </CardTitle>
                      </div>
                      <div className="mx-3">
                        <p className="m-0 p-0 print-text-sm">Width</p>
                        <CardTitle className="mb-0 color-blue">
                          {" "}
                          {item?.product_details?.width}
                        </CardTitle>
                      </div>
                    </div>
                    <div className="text-center custom_border">
                      quantity
                      <h4>{item?.quantity} </h4>
                    </div>
                  </div>

                  <div className="d-flex mx-4 px-2 mt-0 mb-0">
                    <div className="d-flex font-size-14">
                      Panel :{" "}
                      <CardTitle className="mx-2 color-blue">
                        {userExists(item?.orderitemaddon, "Panel").isExist
                          ? "Yes"
                          : "No"}
                      </CardTitle>
                    </div>
                    <div className="mx-4 d-flex font-size-14">
                      Manchary :{" "}
                      <CardTitle className="mx-2 color-blue">
                        {userExists(item?.orderitemaddon, "Manchary").isExist
                          ? "Yes"
                          : "No"}
                      </CardTitle>
                    </div>
                  </div>

                  <div className={`mx-3 d-flex flex-wrap`}>
                    {map(handleOrderItemAddon(item?.orderitemaddon), addon => (
                      <div className="mt-1 mx-1 w-33 mb-1" key={addon?.id}>
                        <div className="bg-light bg-info-new  p-3 pb-1">
                          <CardTitle className="color-blue">
                            {addon?.addon_name}-
                            {addon?.quantity ? (
                              <span> {addon?.quantity}</span>
                            ) : (
                              ""
                            )}
                          </CardTitle>

                          <p
                            style={{ fontSize: "12px" }}
                            className="text-muted"
                          >
                            {addon?.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </>
      )}
    </>
  )
}

export default PDFGenerator
