import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VISITENTRYIMAGES,
  GET_VISITENTRYIMAGE_DETAILS,
  CREATE_VISITENTRYIMAGE,
  UPDATE_VISITENTRYIMAGE,
  DELETE_VISITENTRYIMAGE,
} from "./actionTypes"
import {
  getVisitEntryImagesFail,
  getVisitEntryImagesSuccess,
  getVisitEntryImageDetailsSuccess,
  getVisitEntryImageDetailsFail,
  createVisitEntryImageFail,
  createVisitEntryImageSuccess,
  updateVisitEntryImageSuccess,
  updateVisitEntryImageFail,
  deleteVisitEntryImageSuccess,
  deleteVisitEntryImageFail,
} from "./actions"

import { get, post, ApiPut, del, API_URL } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"
import axios from "axios"

// VisitEntryImage - This line cannot be edited or removed
function getVisitEntryImagesAPi({ page, sort, limit, searchText }) {
  return get(
    `/fieldsite/visit_image?visit_entry=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText}`
  )
}

const getVisitEntryImageDetailsAPi = visitentryimageId => {
  return get(`/fieldsite/visit_image/${visitentryimageId}`)
}

// const createVisitEntryImageApi = ({ visitentryimage }) => {
//   return post("/fieldsite/visit_image/", visitentryimage)
// }

const createVisitEntryImageApi = async datas => {
  console.log(datas)

  const formData = new FormData()
  {
    if (datas?.visitentryimage?.image) {
      formData?.append(
        "image",
        datas?.visitentryimage?.image?.image,
        datas?.visitentryimage?.image?.image?.name
      )
    }
    if (datas?.visitentryimage?.title) {
      formData?.append("title", datas?.visitentryimage?.title)
    }
    if (datas?.visitentryimage?.message) {
      formData?.append("message", datas?.visitentryimage?.message)
    }
    formData?.append("visit_entry", datas?.visitentryimage?.visit_entry)
  }

  try {
    let response = await axios({
      method: "post",
      url: `${API_URL}/fieldsite/visit_image/`,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "token " + localStorage.getItem("token"),
      },
      data: formData,
    })
    return response.data
  } catch (err) {
    console.log(err)
    return err?.response?.data
  }
}

const updateVisitEntryImageApi = ({ visitentryimage, visitentryimageId }) => {
  return ApiPut(`/fieldsite/visit_image/${visitentryimageId}`, visitentryimage)
}

const deleteVisitEntryImageApi = ({ visitentryimageId }) => {
  return del(`/fieldsite/visit_image/${visitentryimageId}`)
}

function* fetchVisitEntryImages({ payload }) {
  try {
    const response = yield call(getVisitEntryImagesAPi, payload)
    yield put(getVisitEntryImagesSuccess(response))
  } catch (error) {
    yield put(getVisitEntryImagesFail(error))
  }
}

function* fetchVisitEntryImageDetails({ payload: visitentryimageId }) {
  try {
    const response = yield call(getVisitEntryImageDetailsAPi, visitentryimageId)
    yield put(getVisitEntryImageDetailsSuccess(response))
  } catch (error) {
    yield put(getVisitEntryImageDetailsFail(error))
  }
}

function* onCreateVisitEntryImage({ payload }) {
  console.log(payload)
  try {
    const response = yield call(createVisitEntryImageApi, payload)
    yield put(createVisitEntryImageSuccess(response))
    payload?.onCloseClick()
    payload?.handleImageState()
    doneNotification("VisitEntryImage Created Successfully!")
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createVisitEntryImageFail(error))
  }
}

function* onUpdateVisitEntryImage({ payload }) {
  try {
    const response = yield call(updateVisitEntryImageApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "VisitEntryImage Updated Successfully!",
        title: "",
      })
      yield put(updateVisitEntryImageSuccess(response))
      if (payload.history) {
        payload.history.push("/visitentryimages")
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateVisitEntryImageFail(error.response.data))
  }
}

function* onDeleteVisitEntryImage({ payload }) {
  try {
    const response = yield call(deleteVisitEntryImageApi, payload)
    yield put(deleteVisitEntryImageSuccess(payload?.visitentryimageId))
    Notification({
      type: "success",
      message: "VisitEntryImage Deleted Successfully!",
      title: "",
    })
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteVisitEntryImageFail(error?.response?.data))
  }
}

function* visitentryimageSaga() {
  yield takeEvery(GET_VISITENTRYIMAGES, fetchVisitEntryImages)
  yield takeEvery(GET_VISITENTRYIMAGE_DETAILS, fetchVisitEntryImageDetails)
  yield takeEvery(CREATE_VISITENTRYIMAGE, onCreateVisitEntryImage)
  yield takeEvery(UPDATE_VISITENTRYIMAGE, onUpdateVisitEntryImage)
  yield takeEvery(DELETE_VISITENTRYIMAGE, onDeleteVisitEntryImage)
}

export default visitentryimageSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
