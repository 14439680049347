import axios from "axios"
import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { Fragment, useEffect, useState } from "react"
import PropTypes from "prop-types"

import { useDispatch, useSelector } from "react-redux"
import { useParams, useHistory } from "react-router"
import { Alert, Button, Card, CardBody, Col, Label, Row } from "reactstrap"
//actions
import {
  getProductDetail,
  updateProductFail,
  updateProductSuccess,
} from "store/actions"
import { API_URL } from "helpers/api_methods"
import { Notification } from "components/Common/Notification"
import { map } from "lodash"

function ProductCard() {
  const dispatch = useDispatch()
  const history = useHistory()

  const params = useParams()
  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    image: null,
  })

  const [imagePreview, setImagePreview] = useState()

  const { productDetail, createProducterror } = useSelector(state => ({
    createProducterror: state.Products.createProducterror,
    productDetail: state.Products.productDetail,
  }))

  function handleValidSubmit(e, v) {
    setLoading(true)
    e.preventDefault()

    const form_data = new FormData()
    {
      state?.image?.name &&
        form_data.append("image", state?.image, state?.image?.name)
    }
    form_data.append("serial_number", v.serial_number)
    form_data.append("channel_size", v.channel_size)
    form_data.append("guage", v.guage)
    form_data.append("perticular", v.perticular)
    form_data.append("no_of_cols", v.no_of_cols)
    form_data.append("width", v.width)
    form_data.append("height", v.height)
    form_data.append("profit", v.profit)
    form_data.append("is_door", v.is_door)
    form_data.append("is_security_door", v.is_security_door)
    form_data.append("is_window", v.is_window)
    form_data.append("description", v.description)

    let url = `${API_URL}/store/product/${productDetail.id}/`
    axios
      .put(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
          Authorization: "token " + localStorage.getItem("token"),
        },
      })
      .then(res => {
        dispatch(updateProductSuccess(res.data))
        setLoading(false)

        Notification({
          type: "success",
          message: "Updated Successfully",
          title: "",
        })
        history.goBack()
      })
      .catch(err => {
        updateProductFail(err)
        setLoading(false)

        Notification({
          type: "error",
          message: "Update Faild",
          title: "",
        })
      })
  }

  const handleImageChange = e => {
    setState({
      ...state,
      image: e.target.files[0],
    })

    const [file] = e.target.files
    setImagePreview(URL.createObjectURL(file))
  }

  useEffect(() => {
    dispatch(getProductDetail(params.id))
  }, [])

  const inputData = [
    {
      id: 1,
      title: "Serial Number",
      name: "serial_number",
      type: "text",
      isRequire: true,
      width: 6,
      value: productDetail?.serial_number,
    },
    {
      id: 2,
      title: "Channel Size",
      name: "channel_size",
      type: "text",
      isRequire: true,
      width: 6,
      value: productDetail?.channel_size,
    },
    {
      id: 3,
      title: "Guage",
      name: "guage",
      type: "number",
      isRequire: true,
      width: 4, // checkbox
      value: productDetail?.guage,
    },
    {
      id: 4,
      title: "Perticular (W/V/FW/KW/D)",
      name: "perticular",
      type: "text",
      isRequire: true,
      width: 4,
      value: productDetail?.perticular,
    },
    {
      id: 5,
      title: "No.of Columns",
      name: "no_of_cols",
      type: "number",
      isRequire: true,
      width: 4,
      value: productDetail?.no_of_cols,
    },
     {
      id: 6,
      title: "Width (cm)",
      name: "width",
      type: "number",
      isRequire: true,
      width: 3,
      value: productDetail?.width,
    },
    {
      id: 7,
      title: "Height (cm)",
      name: "height",
      type: "number",
      isRequire: true,
      width: 3,
      value: productDetail?.height,
    },
   
    {
      id: 8,
      title: "Profit",
      name: "profit",
      type: "number",
      isRequire: false,
      width: 6,
      value: productDetail?.profit,
    },
    {
      id: 10,
      title: "Description",
      name: "description",
      type: "textarea",
      isRequire: false,
      width: 12,
      value: productDetail?.description,
    },
    {
      id: 9,
      title: "Door",
      name: "is_door",
      type: "checkbox",
      isRequire: false,
      width: 4,
      value: false,
      value: productDetail?.is_door,
    },
    {
      id: 10,
      title: "Window",
      name: "is_window",
      type: "checkbox",
      isRequire: false,
      width: 4,
      value: false,
      value: productDetail?.is_window,
    },
    {
      id: 11,
      title: "Security Door",
      name: "is_security_door",
      type: "checkbox",
      isRequire: false,
      width: 4,
      value: false,
      value: productDetail?.is_security_door,
    },
  ]

  // const inputData = [
  //   {
  //     id: 1,
  //     title: "Serial Number",
  //     name: "serial_number",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.serial_number,
  //   },
  //   {
  //     id: 2,
  //     title: "Channel Size",
  //     name: "channel_size",
  //     type: "text",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.channel_size,
  //   },
  //   {
  //     id: 3,
  //     title: "Guage",
  //     name: "guage",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.guage,
  //   },
  //   {
  //     id: 4,
  //     title: "Perticular",
  //     name: "perticular",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.perticular,
  //   },
  //   {
  //     id: 5,
  //     title: "Number of Columns",
  //     name: "no_of_cols",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.no_of_cols,
  //   },
  //   {
  //     id: 6,
  //     title: "Width",
  //     name: "width",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.width,
  //   },
  //   {
  //     id: 7,
  //     title: "Height",
  //     name: "height",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.height,
  //   },
  //   {
  //     id: 8,
  //     title: "Profit",
  //     name: "profit",
  //     type: "number",
  //     isRequire: false,
  //     width: 6,
  //     value: productDetail?.profit,
  //   },
  //   {
  //     id: 10,
  //     title: "Description",
  //     name: "description",
  //     type: "textarea",
  //     isRequire: false,
  //     width: 12,
  //     value: productDetail?.description,
  //   },
  //   {
  //     id: 9,
  //     title: "Is Door",
  //     name: "is_door",
  //     type: "checkbox",
  //     isRequire: false,
  //     width: 4,
  //     value: false,
  //     value: productDetail?.is_door,
  //   },
  //   {
  //     id: 10,
  //     title: "Is Window",
  //     name: "is_window",
  //     type: "checkbox",
  //     isRequire: false,
  //     width: 4,
  //     value: false,
  //     value: productDetail?.is_window,
  //   },
  // ]

  return (
    <>
      <Card>
        <CardBody>
          <AvForm
            className="form-horizontal "
            onValidSubmit={(e, v) => {
              handleValidSubmit(e, v)
            }}
          >
            {createProducterror && (
              <Alert color="danger">{createProducterror}</Alert>
            )}
            <Row>
              {map(inputData, (item, key) => (
                <Fragment key={key}>
                  <Col sm={9} lg={parseInt(item?.width)} className="mb-4">
                    <AvField
                      name={item?.name}
                      className=""
                      label={item?.title}
                      type={item?.type}
                      value={item?.value}
                      errorMessage="Invalid Input"
                      validate={{
                        required: { value: item?.isRequire },
                      }}
                    />
                  </Col>
                </Fragment>
              ))}
            </Row>

            <div className="row mb-4">
              <Label htmlFor="image" className="col-sm-3 col-form-label">
                Product Images
              </Label>
              <Col sm={9}>
                <input
                  name="image"
                  type="file"
                  id="image"
                  accept="image/png, image/jpeg"
                  className="form-control"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <div
                    className="h-25 w-25 mt-4 p-3 sm:p-0"
                    style={{ marginLeft: "auto" }}
                  >
                    <img
                      src={imagePreview}
                      alt="product"
                      className="h-100 w-100"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                )}
              </Col>
            </div>

            <div className="row justify-content-end">
              <Col sm={9}>
                <div>
                  <Button type="submit" color="success" className="w-md">
                    {loading == true && (
                      <>
                        <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                      </>
                    )}
                    Submit
                  </Button>
                </div>
              </Col>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </>
  )
}

export default ProductCard

ProductCard.propTypes = {
  history: PropTypes.object,
}
