import React, { useEffect } from "react"

import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap"
// import { getSheetOrder } from "store/actions"

import "react-datepicker/dist/react-datepicker.css"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { getSheetTotalWeight } from "store/actions"


const SheetOrder = () => {
  const { dashboardData } = useSelector(state => ({
    dashboardData: state.Dashboard.dashboardData,
  }))
  return (
    <>
      <Col>
        <Card>


          <Row>
            <Col lx="6" lg="6">
              <CardBody>
                <CardTitle
                  className="mb-4"
                  style={{ justifyContent: "space-between" }}
                >
                  Sheet Orders
                  <div
                    className="mt-2 clearfix w-fit-content"
                    style={{ width: "fit-content" }}
                  >
                    <div className="">
                      <div
                        className="input-group input-group-sm d-flex"
                      >

                        <Link to="/sheets/sheetorders/create" className="mb-4 me-2">
                          <Button color="primary">Create sheet order</Button>
                        </Link><br />
                        <Link to="/sheets/sheetorders" className="mb-4">
                          <Button color="primary">View sheet orders</Button>
                        </Link>

                      </div>
                    </div>
                  </div>
                </CardTitle>


              </CardBody>
            </Col>
            <Col lx="6" lg="6">
              <CardBody>
                <CardTitle
                  className="mb-4"
                  style={{ justifyContent: "space-between" }}
                >
                  Total sheets weight

                  <div
                    className="mt-2 clearfix w-fit-content"
                    style={{ width: "fit-content" }}
                  >
                    <div className="">
                      <div
                        className="input-group input-group-sm d-flex"
                      >
                        <h4 className="text-primary">{dashboardData.sheets_total_weight} kg</h4>
                      </div>
                    </div>
                  </div>
                </CardTitle>


              </CardBody>
            </Col>
          </Row>

        </Card>
      </Col>

    </>
  )
}

export default SheetOrder
