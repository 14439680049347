import MyPagination from "components/Common/MyPagination"
import { map, range } from "lodash"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom/cjs/react-router-dom"
import { Badge, Card, CardBody, Col, Media, Row, Spinner } from "reactstrap"
import {
  getAllFieldsCount,
  getFieldStaffs,
  getVisitEntrys,
} from "store/actions"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import moment from "moment"
import Select from "react-select"
import { AvField, AvForm } from "availity-reactstrap-validation"

function Fields() {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const [staff, setStaff] = useState("Select staff...")
  const [staffId, setStaffId] = useState("")
  const [staffSearch, setsStaffSearch] = useState("")
  const [finalDateFormated, setFinalDateFormated] = useState({
    from: "",
    to: "",
  })

  const { allVisitEntries, loading, allStaffs, count } = useSelector(state => ({
    allVisitEntries: state.VisitEntry.visitentrys,
    loading: state?.VisitEntry?.loading,
    allStaffs: state?.FieldStaff?.fieldstaffs?.results,
    count: state?.FieldStaff?.fieldsCount,
  }))

  const handlerStaffFinalValue = event => {
    setStaff(event?.label)
    setStaffId(event?.value)
  }

  const staffOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: allStaffs?.map((result, index) => ({
        key: index,
        label: result?.full_name,
        value: result?.id,
      })),
    },
  ]

  const handleEntersStaffs = text => {
    setsStaffSearch(text)
  }

  useEffect(() => {
    dispatch(getFieldStaffs("", "", "", staffSearch))
  }, [dispatch, staffSearch])

  const handleDateSearch = () => {
    if (
      finalDateFormated?.from?.length >= 1 &&
      finalDateFormated?.to?.length >= 1
    ) {
      return finalDateFormated
    } else {
      return {
        from: "",
        to: "",
      }
    }
  }
  console.log(handleDateSearch())

  useEffect(() => {
    dispatch(getAllFieldsCount())
  }, [dispatch])

  const cardData = [
    {
      link: "/fieldstaff",
      name: "Field Staffs",
      count: count?.fieldstaff_count ?? 0,
      iconClass: "bx bx-user",
    },
    {
      link: "/fieldsite",
      name: "Field Sites",
      count: count?.fieldsite_count ?? 0,
      iconClass: "bx-archive-in",
    },
    {
      link: "/fields",
      name: "Visit Entries",
      count: count?.visitentry_count ?? 0,
      iconClass: "bx bx-detail",
    },
  ]

  const totalPages = Math.ceil(allVisitEntries?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(
      getVisitEntrys(
        pageSend(),
        "",
        10,
        searchText,
        staffId,
        handleDateSearch()
      )
    )
  }, [dispatch, page, searchText, staffId, finalDateFormated])

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  const handleStatusColor = color => {
    switch (color) {
      case "Pending":
        return "warning"
      case "Completed":
        return "success"
      case "In Progess":
        return "primary"
    }
  }
  const columns = [
    {
      dataField: "checkIn",
      text: "Check In",
      sort: true,
    },
    {
      dataField: "fieldstaff_name",
      text: "Staff Name",
      sort: true,
    },
    {
      dataField: "fieldsite_name",
      text: "Site Name",
      sort: true,
    },
    {
      dataField: "owner_name",
      text: "Owner Name",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
  ]

  const visitEntryData = map(allVisitEntries?.results, (item, index) => ({
    ...item,
    key: index,
    checkIn:
      item?.check_in_date && moment(item?.check_in_date).format("DD/MM/YYYY"),
    checkOut:
      item?.check_out_date && moment(item?.check_out_date).format("DD/MM/YYYY"),
    fieldstaff_name: (
      <span>
        <Link to={`/fieldstaff/${item?.field_staff}`}>
          {item?.fieldstaff_name}
        </Link>
      </span>
    ),
    fieldsite_name: (
      <span>
        <Link to={`/fieldsite/${item?.fieldsite}`}>{item?.fieldsite_name}</Link>
      </span>
    ),
    action: (
      <Link
        to={{
          pathname: `/visit-entry/${item?.id}`,
          state: "visitEntry",
        }}
      >
        View
      </Link>
    ),
  }))

  return (
    <div>
      <div className="">
        <Row>
          {cardData?.map((item, key) => (
            <Col md="4" key={"_col_" + key}>
              <Link to={item?.link}>
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Media>
                      <Media body>
                        <p className="text-muted fw-medium">{item.name}</p>
                        <h4 className="mb-0">{item.count}</h4>
                      </Media>
                      <div className="avatar-sm rounded-circle bg-soft-primary align-self-center mini-stat-icon">
                        <span className="avatar-title rounded-circle bg-danger">
                          <i
                            className={"bx " + item.iconClass + " font-size-24"}
                          ></i>
                        </span>
                      </div>
                    </Media>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <Row>
          <p className="text-muted h6">All Visit Entries</p>
          <Col className="col-12">
            <Card>
              <CardBody>
                <ToolkitProvider
                  keyField="id"
                  columns={columns}
                  data={visitEntryData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="3" style={{ marginTop: 12 }}>
                          <div className="search-box me-2 mb-0 d-inline-block">
                            <div className="position-relative">
                              <form
                                className="app-search d-lg-block"
                                onChange={e => handleSearch(e)}
                              >
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Search"
                                    defaultValue={searchText}
                                  />
                                  <span className="bx bx-search-alt" />
                                </div>
                              </form>{" "}
                            </div>
                          </div>
                        </Col>
                        <Col md="3">
                          <div>
                            <label>filter by staff</label>
                            <Select
                              onInputChange={handleEntersStaffs}
                              value={staff}
                              placeholder={staff}
                              onChange={handlerStaffFinalValue}
                              options={staffOptions}
                              classNamePrefix="select2-selection"
                              isLoading={loading}
                            />
                          </div>
                        </Col>
                        <Col md="6">
                          {" "}
                          <AvForm style={{ marginTop: 8 }}>
                            <span className="mt-1">filter By Date</span>
                            <div className={`d-flex date_wrapper `}>
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  style={{ width: "260px" }}
                                  name="dateRange"
                                  type="date"
                                  validate={{
                                    dateRange: {
                                      start: { value: -1, units: "years" },
                                      end: { value: 1, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      from: e.target.value,
                                    })
                                  }
                                  value={finalDateFormated?.from}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      from: "",
                                    })
                                  }
                                  style={{
                                    position: "absolute",
                                    left: 202,
                                    bottom: 3,
                                  }}
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                              <div className="d-flex align-items-start position-relative">
                                <AvField
                                  name="dateRange"
                                  className="mx-1"
                                  type="date"
                                  style={{ width: "240px" }}
                                  validate={{
                                    dateRange: {
                                      start: { value: -1, units: "years" },
                                      end: { value: 1, units: "years" },
                                    },
                                  }}
                                  onChange={e =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      to: e.target.value,
                                    })
                                  }
                                  value={finalDateFormated?.to}
                                />
                                <button
                                  onClick={() =>
                                    setFinalDateFormated({
                                      ...finalDateFormated,
                                      to: "",
                                    })
                                  }
                                  style={{
                                    position: "absolute",
                                    left: 190,
                                    bottom: 3,
                                  }}
                                  type="button"
                                  className="btn btn-white editable-cancel date_close btn-sm"
                                >
                                  <i className="mdi mdi-close text-danger "></i>
                                </button>
                              </div>
                            </div>
                          </AvForm>
                        </Col>
                      </Row>
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : allVisitEntries?.count > 0 ? (
                        <>
                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  // defaultSorted={defaultSorted}
                                  // selectRow={selectRow}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                />
                              </div>
                            </Col>
                          </Row>
                          <MyPagination
                            pages={pages}
                            clcickedPage={page}
                            onNunClick={item => setPage(item)}
                            onNextClick={() => setPage(page + 1)}
                            onPrevClick={() => setPage(page - 1)}
                            onFastNextClick={() => setPage(pages.length)}
                            onFastPrevClick={() => setPage(1)}
                            apiPage={pageSend}
                          />
                        </>
                      ) : (
                        <p>No Data</p>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default Fields
