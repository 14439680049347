import {
  GET_VISITENTRYS,
  GET_VISITENTRYS_SUCCESS,
  GET_VISITENTRYS_FAIL,
  GET_VISITENTRY_DETAILS,
  GET_VISITENTRY_DETAILS_SUCCESS,
  GET_VISITENTRY_DETAILS_FAIL,
  CREATE_VISITENTRY,
  CREATE_VISITENTRY_SUCCESS,
  CREATE_VISITENTRY_FAIL,
  UPDATE_VISITENTRY,
  UPDATE_VISITENTRY_SUCCESS,
  UPDATE_VISITENTRY_FAIL,
  DELETE_VISITENTRY_SUCCESS,
  DELETE_VISITENTRY_FAIL,
  DELETE_VISITENTRY,
} from "./actionTypes"

// VisitEntry - This line cannot be edited or removed
export const getVisitEntrys = (
  page,
  sort,
  limit,
  searchText,
  staffId,
  handleDateSearch
) => ({
  type: GET_VISITENTRYS,
  payload: { page, sort, limit, searchText, staffId, handleDateSearch },
})

export const getVisitEntrysSuccess = visitentrys => ({
  type: GET_VISITENTRYS_SUCCESS,
  payload: visitentrys,
})

export const getVisitEntrysFail = error => ({
  type: GET_VISITENTRYS_FAIL,
  payload: error,
})

export const getVisitEntryDetails = visitentryId => ({
  type: GET_VISITENTRY_DETAILS,
  payload: visitentryId,
})

export const getVisitEntryDetailsSuccess = visitentryDetails => ({
  type: GET_VISITENTRY_DETAILS_SUCCESS,
  payload: visitentryDetails,
})

export const getVisitEntryDetailsFail = error => ({
  type: GET_VISITENTRY_DETAILS_FAIL,
  payload: error,
})

export const createVisitEntry = (visitentry, history) => ({
  type: CREATE_VISITENTRY,
  payload: { visitentry, history },
})

export const createVisitEntrySuccess = visitentry => ({
  type: CREATE_VISITENTRY_SUCCESS,
  payload: visitentry,
})

export const createVisitEntryFail = error => ({
  type: CREATE_VISITENTRY_FAIL,
  payload: error,
})

export const getVisitEntryDetail = visitentryId => ({
  type: GET_VISITENTRY_DETAILS,
  payload: visitentryId,
})

export const updateVisitEntry = (visitentry, visitentryId, history) => ({
  type: UPDATE_VISITENTRY,
  payload: { visitentry, visitentryId, history },
})

export const updateVisitEntrySuccess = visitentry => ({
  type: UPDATE_VISITENTRY_SUCCESS,
  payload: visitentry,
})

export const updateVisitEntryFail = error => ({
  type: UPDATE_VISITENTRY_FAIL,
  payload: error,
})

export const deleteVisitEntry = (visitentryId, history) => (
  console.log(visitentryId),
  {
    type: DELETE_VISITENTRY,
    payload: { visitentryId, history },
  }
)

export const deleteVisitEntrySuccess = visitentry => ({
  type: DELETE_VISITENTRY_SUCCESS,
  payload: visitentry,
})

export const deleteVisitEntryFail = error => ({
  type: DELETE_VISITENTRY_FAIL,
  payload: error,
})
