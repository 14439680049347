import {
  GET_SHEET_USERS_SUCCESS,
  GET_SHEET_USERS_FAIL,
  GET_SHEET_USER_DETAIL_SUCCESS,
  GET_SHEET_USER_DETAIL_FAIL,
  CREATE_SHEET_USER_SUCCESS,
  CREATE_SHEET_USER_FAIL,
  UPDATE_SHEET_USER_SUCCESS,
  UPDATE_SHEET_USER_FAIL,
  DELETE_SHEET_USER_SUCCESS,
  DELETE_SHEET_USER_FAIL,
  GET_SHEET_USERS,
  GET_SHEET_USER_DETAIL,
  UPDATE_SHEET_USER,
  CREATE_SHEET_USER,
  DELETE_SHEET_USER,
} from "./actionTypes"

const INIT_STATE = {
  sheetusers: {
    count: "",
    next: "",
    previous: "",
    results: [],
  },
  sheetuserDetail: {
    location: "",
    profit: "",
    account: {
      email: "",
      username: "",
      phone: "",
      full_name: "",
    },
    id: "",
  },
  error: {},
  loading: false,
  detailLoading: false,
  // createsheetusererror: "",
}

const Sheetusers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SHEET_USERS:
    case UPDATE_SHEET_USER:
    case CREATE_SHEET_USER:
    case DELETE_SHEET_USER:
      return {
        ...state,
        loading: true,
      }
    case GET_SHEET_USER_DETAIL:
      return {
        ...state,
        loading: true,
        detailLoading: true,
      }
    case GET_SHEET_USERS_SUCCESS:
      return {
        ...state,
        sheetusers: action.payload,
        loading: false,
      }

    case GET_SHEET_USERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CREATE_SHEET_USER_SUCCESS:
      return {
        ...state,
        sheetusers: action.payload,
        loading: false,
        error: {},
      }

    case CREATE_SHEET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_SHEET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        sheetuserDetail: action.payload,
        loading: false,
        detailLoading: false,
      }

    case GET_SHEET_USER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        detailLoading: false,
      }

    case UPDATE_SHEET_USER_SUCCESS:
      return {
        ...state,
        sheetuserDetail: action.payload,
        loading: false,
        error: {},
      }

    case UPDATE_SHEET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case DELETE_SHEET_USER_SUCCESS:
      return {
        ...state,
        sheetusers: state.sheetusers.results.filter(
          sheetuser => sheetuser.id !== action.payload.id
        ),
        loading: false,
      }

    case DELETE_SHEET_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default Sheetusers
