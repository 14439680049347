/* DEALERS */
export const GET_DEALERS = "GET_DEALERS"
export const GET_DEALERS_SUCCESS = "GET_DEALERS_SUCCESS"
export const GET_DEALERS_FAIL = "GET_DEALERS_FAIL"

/* DEALERS DETAIL*/
export const GET_DEALER_DETAIL = "GET_DEALER_DETAIL"
export const GET_DEALER_DETAIL_SUCCESS = "GET_DEALER_DETAIL_SUCCESS"
export const GET_DEALER_DETAIL_FAIL = "GET_DEALER_DETAIL_FAIL"

/**
 * add dealer
 */
export const CREATE_DEALER = "CREATE_DEALER"
export const CREATE_DEALER_SUCCESS = "CREATE_DEALER_SUCCESS"
export const CREATE_DEALER_FAIL = "CREATE_DEALER_FAIL"

/**
 * Edit dealer
 */
export const UPDATE_DEALER = "UPDATE_DEALER"
export const UPDATE_DEALER_SUCCESS = "UPDATE_DEALER_SUCCESS"
export const UPDATE_DEALER_FAIL = "UPDATE_DEALER_FAIL"

/**
 * Delete dealer
 */
export const DELETE_DEALER = "DELETE_DEALER"
export const DELETE_DEALER_SUCCESS = "DELETE_DEALER_SUCCESS"
export const DELETE_DEALER_FAIL = "DELETE_DEALER_FAIL"
