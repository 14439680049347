import {
  GET_SHEET_USERS,
  GET_SHEET_USERS_SUCCESS,
  GET_SHEET_USERS_FAIL,
  GET_SHEET_USER_DETAIL,
  GET_SHEET_USER_DETAIL_SUCCESS,
  GET_SHEET_USER_DETAIL_FAIL,
  CREATE_SHEET_USER,
  CREATE_SHEET_USER_SUCCESS,
  CREATE_SHEET_USER_FAIL,
  UPDATE_SHEET_USER,
  UPDATE_SHEET_USER_SUCCESS,
  UPDATE_SHEET_USER_FAIL,
  DELETE_SHEET_USER,
  DELETE_SHEET_USER_SUCCESS,
  DELETE_SHEET_USER_FAIL,
} from "./actionTypes"

export const getSheetUsers = (searchText, page) => ({
  type: GET_SHEET_USERS,
  payload: { searchText, page },
})

export const getSheetUsersSuccess = sheetusers => ({
  type: GET_SHEET_USERS_SUCCESS,
  payload: sheetusers,
})
export const getSheetUsersFail = error => ({
  type: GET_SHEET_USERS_FAIL,
  payload: error,
})

export const getSheetUserDetail = sheetuserId => ({
  type: GET_SHEET_USER_DETAIL,
  sheetuserId,
})

export const getSheetUserDetailSuccess = sheetuserDetail => ({
  type: GET_SHEET_USER_DETAIL_SUCCESS,
  payload: sheetuserDetail,
})

export const getSheetUserDetailFail = error => ({
  type: GET_SHEET_USER_DETAIL_FAIL,
  payload: error,
})

export const createSheetUser = (sheetuser, history) => ({
  type: CREATE_SHEET_USER,
  payload: { sheetuser, history },
})

export const createSheetUserSuccess = sheetuser => ({
  type: CREATE_SHEET_USER_SUCCESS,
  payload: sheetuser,
})

export const createSheetUserFail = error => ({
  type: CREATE_SHEET_USER_FAIL,
  payload: error,
})

export const updateSheetUser = (sheetuser, sheetuserId, history) => ({
  type: UPDATE_SHEET_USER,
  payload: { sheetuser, sheetuserId, history },
})

export const updateSheetUserSuccess = sheetuser => ({
  type: UPDATE_SHEET_USER_SUCCESS,
  payload: sheetuser,
})

export const updateSheetUserFail = error => ({
  type: UPDATE_SHEET_USER_FAIL,
  payload: error,
})

export const deleteSheetUser = (sheetuserId, history) => ({
  type: DELETE_SHEET_USER,
  payload: { sheetuserId, history },
})

export const deleteSheetUserSuccess = sheetuser => ({
  type: DELETE_SHEET_USER_SUCCESS,
  payload: sheetuser,
})

export const deleteSheetUserFail = error => ({
  type: DELETE_SHEET_USER_FAIL,
  payload: error,
})
