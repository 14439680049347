import {
  GET_FIELDSITES,
  GET_FIELDSITES_SUCCESS,
  GET_FIELDSITES_FAIL,
  GET_FIELDSITE_DETAILS,
  GET_FIELDSITE_DETAILS_SUCCESS,
  GET_FIELDSITE_DETAILS_FAIL,
  CREATE_FIELDSITE,
  CREATE_FIELDSITE_SUCCESS,
  CREATE_FIELDSITE_FAIL,
  UPDATE_FIELDSITE,
  UPDATE_FIELDSITE_SUCCESS,
  UPDATE_FIELDSITE_FAIL,
  DELETE_FIELDSITE,
  DELETE_FIELDSITE_SUCCESS,
  DELETE_FIELDSITE_FAIL,
  GET_GMAP_PKEY,
  GET_GMAP_PKEY_SUCCESS,
  GET_GMAP_PKEY_FAIL,
  SEARCH_LOCATION,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  fieldsites: {
    fieldsites: [],
    total: "",
    page: "",
  },
  fieldsiteDetails: {},
  error: {},
  loading: false,
  map: "",
  mapLoading: false,
  places: {
    places: [],
  },
  placesLoading: false,
}

const FieldSite = (state = INIT_STATE, action) => {
  switch (action.type) {
    // FieldSite - This line cannot be edited or removed
    case GET_FIELDSITES:
    case GET_FIELDSITE_DETAILS:
    case CREATE_FIELDSITE:
    case UPDATE_FIELDSITE:
    case DELETE_FIELDSITE:
      return {
        ...state,
        loading: true,
      }

    case DELETE_FIELDSITE_FAIL:
    case GET_FIELDSITES_FAIL:
    case GET_FIELDSITE_DETAILS_FAIL:
    case UPDATE_FIELDSITE_FAIL:
    case CREATE_FIELDSITE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_FIELDSITES_SUCCESS:
      return {
        ...state,
        fieldsites: action.payload,
        error: {},
        loading: false,
      }

    case GET_FIELDSITE_DETAILS_SUCCESS:
      return {
        ...state,
        fieldsiteDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_FIELDSITE_SUCCESS:
      console.log(state)
      return {
        ...state,
        fieldsites: action.payload,
        // {
        //   ...state.fieldsites,
        //   fieldsites: [...state.fieldsites.results, action.payload],
        //   total: state.fieldsites.total + 1,
        // },
        // fieldsiteDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_FIELDSITE_SUCCESS:
      return {
        ...state,
        fieldsites: {
          ...state.fieldsites,
          fieldsites: state.fieldsites?.results?.map(fieldsite =>
            fieldsite.id === action.payload.id
              ? { ...fieldsite, ...action.payload }
              : fieldsite
          ),
        },
        fieldsiteDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_FIELDSITE_SUCCESS:
      console.log(action?.payload)
      return {
        ...state,
        fieldsites: {
          ...state?.fieldsites,
          results: state.fieldsites?.results?.filter(
            fieldsite => fieldsite.id !== action.payload
          ),
        },
        error: {},
        loading: false,
      }

    case GET_GMAP_PKEY:
      return {
        ...state,
        mapLoading: true,
      }

    case GET_GMAP_PKEY_SUCCESS:
      return {
        ...state,
        map: action.payload,
        error: {},
        mapLoading: false,
      }

    case GET_GMAP_PKEY_FAIL:
      return {
        ...state,
        error: action.payload,
        mapLoading: false,
      }

    case SEARCH_LOCATION:
      return {
        ...state,
        placesLoading: true,
      }

    case SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        places: action.payload,
        error: {},
        placesLoading: false,
      }

    case SEARCH_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
        placesLoading: false,
      }
    default:
      return state
  }
}

export default FieldSite
