/* FINISHEDPRODCHART */
export const GET_MONTHLY_CHART = "GET_MONTHLY_CHART"
export const GET_MONTHLY_CHART_SUCCESS = "GET_MONTHLY_CHART_SUCCESS"
export const GET_MONTHLY_CHART_FAIL = "GET_MONTHLY_CHART_FAIL"

export const GET_MONTHLY_YEAR_CHART = "GET_MONTHLY_YEAR_CHART"
export const GET_MONTHLY_YEAR_CHART_SUCCESS = "GET_MONTHLY_YEAR_CHART_SUCCESS"
export const GET_MONTHLY_YEAR_CHART_FAIL = "GET_MONTHLY_YEAR_CHART_FAIL"

export const GET_YEARLY_CHART = "GET_YEARLY_CHART"
export const GET_YEARLY_CHART_SUCCESS = "GET_YEARLY_CHART_SUCCESS"
export const GET_YEARLY_CHART_FAIL = "GET_YEARLY_CHART_FAIL"

export const GET_DASHBOARDDATA = "GET_DASHBOARDDATA"
export const GET_DASHBOARDDATA_SUCCESS = "GET_DASHBOARDDATA_SUCCESS"
export const GET_DASHBOARDDATA_FAIL = "GET_DASHBOARDDATA_FAIL"

export const GET_DASHBOARDDATA_FELIDS = "GET_DASHBOARDDATA_FELIDS"
export const GET_DASHBOARDDATA_FELIDS_SUCCESS =
  "GET_DASHBOARDDATA_FELIDS_SUCCESS"
export const GET_DASHBOARDDATA_FELIDS_FAIL = "GET_DASHBOARDDATA_FELIDS_FAIL"
