/* PRODUCTIONMNGRS */
export const GET_PRODUCTIONMNGRS = "GET_PRODUCTIONMNGRS"
export const GET_PRODUCTIONMNGRS_SUCCESS = "GET_PRODUCTIONMNGRS_SUCCESS"
export const GET_PRODUCTIONMNGRS_FAIL = "GET_PRODUCTIONMNGRS_FAIL"

/* PRODUCTIONMNGRS DETAIL*/
export const GET_PRODUCTIONMNGR_DETAIL = "GET_PRODUCTIONMNGR_DETAIL"
export const GET_PRODUCTIONMNGR_DETAIL_SUCCESS =
  "GET_PRODUCTIONMNGR_DETAIL_SUCCESS"
export const GET_PRODUCTIONMNGR_DETAIL_FAIL = "GET_PRODUCTIONMNGR_DETAIL_FAIL"

/**
 * add PRODUCTIONMNGR
 */
export const CREATE_PRODUCTIONMNGR = "CREATE_PRODUCTIONMNGR"
export const CREATE_PRODUCTIONMNGR_SUCCESS = "CREATE_PRODUCTIONMNGR_SUCCESS"
export const CREATE_PRODUCTIONMNGR_FAIL = "CREATE_PRODUCTIONMNGR_FAIL"

/**
 * Edit PRODUCTIONMNGR
 */
export const UPDATE_PRODUCTIONMNGR = "UPDATE_PRODUCTIONMNGR"
export const UPDATE_PRODUCTIONMNGR_SUCCESS = "UPDATE_PRODUCTIONMNGR_SUCCESS"
export const UPDATE_PRODUCTIONMNGR_FAIL = "UPDATE_PRODUCTIONMNGR_FAIL"

/**
 * Delete PRODUCTIONMNGR
 */
export const DELETE_PRODUCTIONMNGR = "DELETE_PRODUCTIONMNGR"
export const DELETE_PRODUCTIONMNGR_SUCCESS = "DELETE_PRODUCTIONMNGR_SUCCESS"
export const DELETE_PRODUCTIONMNGR_FAIL = "DELETE_PRODUCTIONMNGR_FAIL"
