import { AvField, AvForm } from "availity-reactstrap-validation"
import React, { useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import { getSheetDetail, updateSheet } from "store/actions"

const UpdateSheet = ({ history }) => {
  const dispatch = useDispatch()
  const params = useParams()

  const { sheetDetail, loading, createSheeterror } = useSelector(
    state => ({
      createSheeterror: state.Sheet.createSheeterror,
      sheetDetail: state.Sheet.sheetDetail,
      loading: state.Sheet.loading,
    })
  )
  function handleValidSubmit(values) {
    console.log(values);
    dispatch(updateSheet(sheetDetail.id,values, "history"))
  }

  useEffect(() => {
    dispatch(getSheetDetail(params.id))
  }, [])

  return (
    <>
      <MetaTags>
        <title>Store | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Sheets" breadcrumbItem="Update Sheet" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="3"></Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">
                      {/* Fill this form */}
                    </CardTitle>

                    <AvForm
                      className="form-horizontal "
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(v)
                      }}
                    >
                      {createSheeterror && (
                        <Alert color="danger">{createSheeterror}</Alert>
                      )}

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-username-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-username-Input"
                            name="name"
                            type="text"
                            value={sheetDetail?.name}
                          />
                        </Col>
                      </div>

                      <div className="row mb-4">
                        <Label
                          htmlFor="tel-input"
                          className="col-sm-3 col-form-label"
                        >
                          Thickness
                        </Label>
                        <Col sm={9}>
                        <AvField
                            name="thick"
                            className="form-control"
                            id="thick-input"
                            type="select"
                            required
                            value={sheetDetail?.thick}
                          >
                            <option value="0" disabled>Select</option>
                            <option value="1.50">1.5 mm</option>
                            <option value="1.20">1.2 mm</option>
                            <option value="1.00">1 mm</option>
                            <option value="2.00">2 mm</option>
                          </AvField>
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-email-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Width
                        </Label>
                        <Col sm={9}>
                          <AvField
                            id="horizontal-email-Input"
                            name="width"
                            className="form-control"
                            type="number" min={0}
                            value={sheetDetail?.width}
                          />
                        </Col>
                      </div>
                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-location-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Length
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="height"
                            type="number" min={0}
                            className="form-control"
                            id="horizontal-location-Input"
                            value={sheetDetail?.height}
                          />
                        </Col>
                      </div>
                      {/* <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-sqft-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Sqft
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="sqft"
                            type="number" min={0}
                            className="form-control"
                            id="horizontal-sqft-Input"
                            value={sheetDetail?.sqft}
                          />
                        </Col>
                      </div> */}

                      {/* <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-unit_kg-Input"
                          className="col-sm-3 col-form-label"
                        >Kg/pc
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="unit_kg"
                            type="number" min={0}
                            className="form-control"
                            id="horizontal-unit_kg-Input"
                            value={sheetDetail?.unit_kg}
                          />
                        </Col>
                      </div> */}

                      <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-quantity-Input"
                          className="col-sm-3 col-form-label"
                        >
                          Stock
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="quantity"
                            type="number" min={0}
                            className="form-control"
                            id="horizontal-quantity-Input"
                            value={sheetDetail?.quantity}
                          />
                        </Col>
                      </div>

                      {/* <div className="row mb-4">
                        <Label
                          htmlFor="horizontal-unit_price-Input"
                          className="col-sm-3 col-form-label"
                        >
                          unit_price
                        </Label>
                        <Col sm={9}>
                          <AvField
                            name="unit_price"
                            type="number" min={0}
                            className="form-control"
                            id="horizontal-unit_price-Input"
                            value={sheetDetail?.unit_price}
                          />
                        </Col>
                      </div> */}

                     
                      <div className="row justify-content-end">
                        <Col sm={9}>
                          <div>
                            <Button
                              type="submit"
                              color="success"
                              className="w-md"
                            >
                              {loading && (
                                <>
                                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                  {/* <Spinner color="white" /> */}
                                </>
                              )}
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </div>
                    </AvForm>
                  </CardBody>
                </Card>
              </Col>
              <Col xl="3"></Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default UpdateSheet

UpdateSheet.propTypes = {
  history: PropTypes.object,
}
