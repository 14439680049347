import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"

//actions
import {
  getFinishedProduct,
  getProducts,
  getStoreItems,
  getStoreSupply,
} from "store/actions"
import SheetOrder from "../AdminDashboard/SheetOrder"

//componets
import MiniWidget from "./mini-widget"
import StoreSupplyList from "./tasks"

function StoreManagerDashboard() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getFinishedProduct("", ""))
    dispatch(getProducts())
    dispatch(getStoreItems())
    dispatch(getStoreSupply())
  }, [dispatch])

  return (
    <div>
      <Row>
        <Col lx="8" lg="8">
          <StoreSupplyList />
        </Col>
        <Col lx="4" lg="4">
          <SheetOrder />
          <MiniWidget />
          <Link to="/store">
            <Card color="success" className="text-white-50">
              <CardBody>
                <CardTitle className="mb-4 text-white">
                  <i className="mdi mdi-check-all me-3 bx-fade-right bx-sm" />{" "}
                  Provide Store Item
                </CardTitle>
              </CardBody>
            </Card>
          </Link>
        </Col>
      </Row>
      <Row></Row>
    </div>
  )
}

export default StoreManagerDashboard
