import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Spinner, Badge } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { map, range } from "lodash"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions
import { getVisitEntrys } from "store/actions"

import "../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import moment from "moment"

const VisitEntries = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")

  const { allVisitEntries, loading } = useSelector(state => ({
    allVisitEntries: state.VisitEntry.visitentrys,
    loading: state?.VisitEntry?.loading,
  }))

  const totalPages = Math.ceil(allVisitEntries?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  useEffect(() => {
    dispatch(getVisitEntrys(pageSend(), "", 10, searchText))
  }, [dispatch, page, searchText])

  const handleStatusColor = color => {
    switch (color) {
      case "Pending":
        return "warning"
      case "Completed":
        return "success"
      case "In Progess":
        return "primary"
    }
  }

  const columns = [
    {
      dataField: "checkIn",
      text: "Check In",
      sort: true,
    },
    {
      dataField: "check_in_location",
      text: "Check In Location",
      sort: true,
    },
    {
      dataField: "checkOut",
      text: "Name",
      sort: true,
    },

    {
      dataField: "check_out_location",
      text: "Check Out Location",
      sort: true,
    },
    {
      dataField: "duration",
      text: "Duration",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
    },
  ]

  const visitEntryData = map(allVisitEntries?.results, (item, index) => ({
    ...item,
    key: index,
    checkIn:
      item?.check_in_date && moment(item?.check_in_date).format("DD/MM/YYYY"),
    checkOut:
      item?.check_out_date && moment(item?.check_out_date).format("DD/MM/YYYY"),
    status: (
      <Badge
        className={
          "font-size-12 badge-soft-" + `${handleStatusColor(item?.status)}`
        }
        pill
      >
        {item?.status}
      </Badge>
    ),
    action: (
      <Link
        to={{
          pathname: `/visit-entry/${item?.id}`,
          state: "visitEntry",
        }}
      >
        View Details
      </Link>
    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const handleSearch = e => {
    e.preventDefault()
    setSearchText(e.target.value)
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <ToolkitProvider
                keyField="id"
                columns={columns}
                data={visitEntryData}
                search
              >
                {toolkitProps => (
                  <React.Fragment>
                    <Row className="mb-2">
                      <Col md="8">
                        <div className="search-box me-2 mb-2 d-inline-block">
                          <div className="position-relative">
                            <form
                              className="app-search d-lg-block"
                              onChange={e => handleSearch(e)}
                            >
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  defaultValue={searchText}
                                />
                                <span className="bx bx-search-alt" />
                              </div>
                            </form>{" "}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    {loading ? (
                      <Spinner color="secondary" className="d-block m-auto" />
                    ) : allVisitEntries?.count > 0 ? (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                // selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>
                    ) : (
                      <p>No Data</p>
                    )}
                  </React.Fragment>
                )}
              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default VisitEntries
