import React, { useState, useEffect } from "react"
import { MetaTags } from "react-meta-tags"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Button,
  Label,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"

//componetns
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//actions
import {
  deleteFieldSite,
  getFieldSiteDetails,
  getMapPublicKey,
  getVisitEntryImages,
  getVisitEntrys,
} from "store/actions"
import DeleteModal from "components/Common/DeleteModal"
import moment from "moment"
import VisitEntryImageForm from "../VisitEntryImage/VisitEntryImageForm"
import VisitEntryForm from "../VisitEntry/Crud/Form"
import MyPagination from "components/Common/MyPagination"
import { range } from "lodash"
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min"
import Gmap from "components/Common/Gmap/useGmap"
import MyGoogleMap from "components/Common/Gmap/googleMap"
import { AvField, AvForm } from "availity-reactstrap-validation"

const FieldSiteDetails = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const location = useLocation()

  console.log(location?.state)

  const {
    allVisitEntryImage,
    loading,
    fieldSiteDetails,
    allVisitEntries,
    mapKey,
  } = useSelector(state => ({
    loading: state.FieldSite.loading,
    fieldSiteDetails: state.FieldSite.fieldsiteDetails,
    allVisitEntryImage: state.VisitEntryImage.visitentryimages,
    allVisitEntries: state.VisitEntry.visitentrys,
    mapKey: state?.FieldSite.map?.google_map_api_key,
  }))

  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const [visitEntryImageOpen, setVisitEntryImageOpen] = useState({
    status: false,
    data: "",
  })
  const [visitEntryOpen, setVisitEntryOpen] = useState({
    status: false,
    data: "",
  })

  const totalPages = Math.ceil(allVisitEntries?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }

  console.log(isOpen)

  const handleDelete = id => {
    if (id) {
      setIsOpen({ ...isOpen, status: true, id: id })
    }
  }
  const onCloseClick = () => {
    setIsOpen({ status: false })
  }

  const handleDeleteEvent = () => {
    dispatch(deleteFieldSite(params?.id, history, ""))
    setIsOpen({ ...isOpen, status: false })
  }

  useEffect(() => {
    dispatch(getFieldSiteDetails(params.id))
  }, [dispatch, params?.id])

  console.log(allVisitEntryImage)

  useEffect(() => {
    dispatch(getVisitEntrys(pageSend(), params?.id, "", ""))
  }, [dispatch, page])

  const fieldSiteId = localStorage.setItem("fieldSiteId", params?.id)

  const handleStatusColor = color => {
    switch (color) {
      case "Pending":
        return "warning"
      case "Completed":
        return "success"
      case "In Progess":
        return "primary"
    }
  }

  const truncateText = (text, maxWords) => {
    const words = text?.split(" ")
    if (words?.length > maxWords) {
      return words?.slice(0, maxWords)?.join(" ") + "..."
    }
    return text
  }

  const myLocations = fieldSiteDetails && fieldSiteDetails?.location
  // ? visitEntryDetail?.check_in_location
  // : "(9.9418112,76.2478592)"

  console.log(myLocations)

  let cleanedCoordinates = myLocations?.replace(/[()]/g, "")

  const coordinatesArray = cleanedCoordinates?.split(",")

  // Access the individual values
  const latitude = coordinatesArray && coordinatesArray[0]
  const longitude = coordinatesArray && coordinatesArray[1]

  const MAP_PUBLIC_KEY = mapKey

  console.log(MAP_PUBLIC_KEY)

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [dispatch])
  const [markers, setMarkers] = useState([latitude, longitude])
  const [mapValues, setMapValues] = useState([latitude, longitude])

  const pointVals = [mapValues]
  const pointMode = {
    banner: false,
    control: {
      values: pointVals,
      onClick: point => setMapValues(point),
    },
  }

  useEffect(() => {
    setMarkers(() => [
      {
        lat: latitude,
        lng: longitude,
        time: new Date(),
      },
    ])
  }, [])

  return (
    <>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen(false)}
        onDeleteClick={handleDeleteEvent}
      />
      <MetaTags>
        <title>Field Site | Loha </title>
      </MetaTags>

      <div className="page-content">
        <Breadcrumbs title="Field Site" breadcrumbItem="Details" />
        <Container fluid>
          <div className="container-fluid">
            <Row>
              <Col xl="6">
                <Card>
                  <CardBody>
                    <div>
                      <div className="mb-1 me-3">
                        <i className="mdi mdi-account-circle text-danger h1"></i>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <Badge
                            className={"font-size-10 badge-soft-success"}
                            pill
                          >
                            {moment(fieldSiteDetails?.date_added).format(
                              "DD-MM-YYYY"
                            )}
                          </Badge>
                          {/* <h6 className="text-muted font-size-12"></h6> */}
                          <h5>{fieldSiteDetails?.owner_name}</h5>
                          <p className="text-muted mb-1">
                            {fieldSiteDetails?.owner_phone}
                          </p>
                          <p
                            className="text-muted mb-0"
                            style={{ maxWidth: 450 }}
                          >
                            {/* {fieldSiteDetails?.address?.length > 25
                            ? `${fieldSiteDetails?.address?.slice(0, 50)}...`
                            : fieldSiteDetails?.address} */}
                            {fieldSiteDetails?.address}
                          </p>
                          <h6
                            style={{
                              maxWidth: 450,
                              paddingTop: 5,
                              minHeight: 30,
                            }}
                          >
                            {" "}
                            {/* {fieldSiteDetails?.remark?.length > 25
                            ? `${fieldSiteDetails?.remark?.slice(0, 50)}...`
                            : fieldSiteDetails?.remark} */}
                            <p className="my-0">Notes :</p>{" "}
                            <span className="px-2">
                              {fieldSiteDetails?.remark}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </CardBody>

                  <CardBody className="border-top">
                    <Row>
                      <div className="col-sm-6">
                        <div>
                          <p className="fw-medium mb-2 ">Site Name :</p>
                          <h4 className="mx-1 font-size-16">
                            {fieldSiteDetails?.fieldsite_name}
                          </h4>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="mt-4 mt-sm-0 ">
                          <p className="fw-medium mb-2">Field Staff :</p>
                          <h6 className="mx-1">
                            {fieldSiteDetails?.fieldstaff_name}
                          </h6>
                        </div>
                      </div>
                      {/* <div className="col-sm-4">
                        <div className="mt-4 mt-sm-0">
                          <p className="fw-medium mb-2">Location :</p>
                          <h6 className="mx-1">{fieldSiteDetails?.location}</h6>
                        </div>
                      </div> */}
                    </Row>
                  </CardBody>

                  <div className="text-end p-2">
                    <div
                      to=""
                      onClick={() => handleDelete(params?.id)}
                      className="btn btn-outline-danger me-2 w-md"
                    >
                      Delete
                    </div>{" "}
                    <Link
                      to={`/field-site/update/${params?.id}`}
                      className="btn btn-outline-primary me-2 w-md"
                    >
                      Update
                    </Link>
                  </div>
                </Card>
              </Col>
              <Col md={6}>
                {/* <Card style={{ minHeight: 365 }}>
                  <CardBody>
                    <div>
                      <span className="my-1">Location</span>
                      <div className="" style={{ maxHeight: 100 }}>
                        <Gmap
                          longitude={longitude}
                          latitude={latitude}
                          height={100}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card> */}

                <AvForm>
                  <Card style={{ minHeight: 285 }}>
                    {fieldSiteDetails?.location ? (
                      <>
                        <span className="p-2">
                          Location<small>(click to see more...)</small>
                        </span>

                        <CardBody>
                          {/* <Gmap longitude={longitude} latitude={latitude} /> */}

                          <Row className="">
                            {/* <Label>CheckIn Location</Label> */}
                            <Col className="col-12 col-sm-12 col-xs-12 col-md-12 col-lg-12 col-xl-12">
                              <MyGoogleMap
                                MAP_PUBLIC_KEY={MAP_PUBLIC_KEY}
                                setMarkers={setMarkers}
                                markers={markers}
                                height={300}
                                isSearch={false}
                                link={true}
                                longitude={longitude}
                                latitude={latitude}
                              />
                            </Col>
                            <div className="col-12 mt-2 mx-1">
                              <Label>
                                <i className="bx bx-map"></i> Location( lat /
                                lng )
                              </Label>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="  mx-1">
                                <AvField
                                  name="location.check_in_location[0]"
                                  placeholder="Latitude"
                                  type="text"
                                  id="#geolocation"
                                  errorMessage="Invalid latitude"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={latitude}
                                />
                              </div>
                              <div className="mx-2">
                                <AvField
                                  name="location.check_in_location[1]"
                                  placeholder="Longitude"
                                  type="text"
                                  errorMessage="Invalid longitude"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  value={longitude}
                                />
                              </div>
                            </div>

                            {/* <Col className="col-12 col-sm-12 col-xs-12 col-md-6 col-lg-6 col-xl-6">
                                <div className="col-12 mt-5 mx-5">
                                  <Label>
                                    <i className="bx bx-map"></i> Location( lat
                                    / lng )
                                  </Label>
                                </div>
                                <div className="col-6 mb-3 mt-2 mx-5">
                                  <AvField
                                    name="location.check_in_location[0]"
                                    placeholder="Latitude"
                                    type="text"
                                    id="#geolocation"
                                    errorMessage="Invalid latitude"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={markers[0]?.lat}
                                  />
                                </div>
                                <div className="col-6 mb-3 mt-3 mx-5">
                                  <AvField
                                    name="location.check_in_location[1]"
                                    placeholder="Longitude"
                                    type="text"
                                    errorMessage="Invalid longitude"
                                    validate={{
                                      required: { value: true },
                                    }}
                                    value={markers[0]?.lng}
                                  />
                                </div>
                              </Col> */}
                          </Row>
                        </CardBody>
                      </>
                    ) : (
                      <span
                        className="p-2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        No CheckIn Location
                      </span>
                    )}
                  </Card>
                </AvForm>
              </Col>

              <Col md={12}>
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="me-2">
                        <h5 className="card-title mb-4">VisitEntry </h5>
                      </div>
                      <Link to={`/visitEntry/create`} className="me-2">
                        <Button color="success" size="sm">
                          Add VisitEntry
                        </Button>
                      </Link>
                    </div>

                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col">Date</th>
                            <th scope="col">Staff Name</th>
                            <th scope="col">Site Name</th>
                            <th scope="col">Owner Name</th>
                            <th scope="col">Duration</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {allVisitEntries?.count === 0 ? (
                          <p className="p-5 font-size-15">No VisitEntries</p>
                        ) : (
                          <tbody>
                            {allVisitEntries?.results?.map(
                              (visitEntry, key) => (
                                <tr key={key}>
                                  {visitEntry?.check_in_date ? (
                                    <td style={{ width: "100px" }}>
                                      <span>
                                        {moment(visitEntry?.date_added).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </span>
                                    </td>
                                  ) : (
                                    <td style={{ width: "100px" }}>
                                      <span></span>
                                    </td>
                                  )}
                                  {visitEntry?.fieldstaff_name ? (
                                    <td>
                                      <h5 className="font-size-13 text-truncate mb-1">
                                        <Link to="#" className="text-dark">
                                          {visitEntry.fieldstaff_name}
                                        </Link>
                                      </h5>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {visitEntry?.fieldsite_name ? (
                                    <td>
                                      <p>{visitEntry?.fieldsite_name}</p>
                                    </td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {visitEntry?.owner_name ? (
                                    <td>{visitEntry?.owner_name}</td>
                                  ) : (
                                    <td></td>
                                  )}

                                  {visitEntry?.duration ? (
                                    <td>{visitEntry.duration}</td>
                                  ) : (
                                    <td></td>
                                  )}
                                  {/* <td>
                                    <span>
                                      {visitEntry?.duration
                                        ? visitEntry?.duration
                                        : 0}
                                    </span>
                                  </td> */}
                                  {/* <td>
                                    <span>
                                      {" "}
                                      <Badge
                                        className={
                                          "font-size-12 badge-soft-" +
                                          `${handleStatusColor(
                                            visitEntry.status
                                          )}`
                                        }
                                        pill
                                      >
                                        {visitEntry?.status}
                                      </Badge>
                                    </span>
                                  </td> */}
                                  <td>
                                    <div className="d-flex">
                                      <Link
                                        to={{
                                          pathname: `/visit-entry/${visitEntry?.id}`,
                                          state: "visitEntry",
                                        }}
                                        // onClick={() =>
                                        //   setVisitEntryOpen({
                                        //     status: true,
                                        //     data: visitEntry,
                                        //   })
                                        // }
                                      >
                                        View Details
                                      </Link>
                                      {/* <div title="Remove">
                                    <i
                                      style={{ cursor: "pointer" }}
                                      className="bx bx-trash text-danger font-size-15 px-4"
                                      onClick={() => handleDelete(item?.id)}
                                    ></i>
                                  </div> */}
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        )}
                      </table>
                    </div>
                    <MyPagination
                      pages={pages}
                      clcickedPage={page}
                      onNunClick={item => setPage(item)}
                      onNextClick={() => setPage(page + 1)}
                      onPrevClick={() => setPage(page - 1)}
                      onFastNextClick={() => setPage(pages.length)}
                      onFastPrevClick={() => setPage(1)}
                      apiPage={pageSend}
                    />
                  </CardBody>
                </Card>
              </Col>
              {/* <Col md="6">
                <Card>
                  <CardBody>
                    <div className="d-flex justify-content-between">
                      <div className="me-2">
                        <h5 className="card-title mb-4">VisitEntry Image </h5>
                      </div>
                      <div className="me-2">
                        <Button
                          color="success"
                          size="sm"
                          onClick={() =>
                            setVisitEntryImageOpen({ status: true, data: "" })
                          }
                        >
                          Add Visit Entry Image
                        </Button>
                      </div>
                    </div>

                    <div className="table-responsive">
                      <table className="table align-middle table-nowrap mb-0">
                        <thead>
                          <tr>
                            <th scope="col" colSpan="2">
                              Images
                            </th>
                            <th scope="col">Title</th>
                            <th scope="col">message</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {allVisitEntryImage?.results?.map(
                            (visitEntryImage, key) => (
                              <tr key={key}>
                                <td style={{ width: "100px" }}>
                                  <img
                                    src={visitEntryImage.image}
                                    alt=""
                                    className="avatar-md h-auto d-block rounded"
                                  />
                                </td>
                                <td>
                                  <h5 className="font-size-13 text-truncate mb-1">
                                    <Link to="#" className="text-dark">
                                      {visitEntryImage.title}
                                    </Link>
                                  </h5>
                                </td>
                                <td>{visitEntryImage?.visit_entry}</td>
                                <td>
                                  <i className="bx bx-comment-dots align-middle me-1"></i>{" "}
                                  {visitEntryImage.message.length > 25
                                    ? `${visitEntryImage.message.slice(
                                        0,
                                        25
                                      )}...`
                                    : visitEntryImage.message}
                                </td>
                                <td>
                                  <div className="d-flex">
                                    <div
                                      onClick={() =>
                                        setVisitEntryImageOpen({
                                          status: true,
                                          data: visitEntryImage,
                                        })
                                      }
                                    >
                                      View Details
                                    </div>
                                    <div title="Remove">
                                      <i
                                        style={{ cursor: "pointer" }}
                                        className="bx bx-trash text-danger font-size-15 px-4"
                                        onClick={() => handleDelete(item?.id)}
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col> */}
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default FieldSiteDetails
