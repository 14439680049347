import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_ORDERS,
  GET_ORDER_DETAIL,
  UPDATE_ORDER,
  CREATE_ORDER,
  DELETE_ORDER,
  UPDATE_ORDER_ITEM,
} from "./actionTypes"
import {
  getOrdersSuccess,
  getOrdersFail,
  getOrderDetailSuccess,
  getOrderDetailFail,
  createOrderSuccess,
  createOrderFail,
  updateOrderSuccess,
  updateOrderFail,
  deleteOrderSuccess,
  deleteOrderFail,
} from "./actions"
import { get, post, ApiPut, del, patch } from "helpers/api_methods"
import { updateOrderItemFail, updateOrderItemSuccess } from "store/actions"
import {
  doneNotification,
  errorNotification,
  Notification,
} from "components/Common/Notification"

function getOrdersAPi({ searchText, page, status }) {
  if (searchText) {
    return get(`/order/order/?search=${searchText && searchText}`)
  } else if (status) {
    return get(
      `/order/order/?page=${page ? page : 1}&status=${status && status
      }&search=${searchText && searchText}`
    )
  } else {
    return get(`/order/order/?page=${page ? page : 1}`)
  }
}
const getOrderDetailsAPi = orderId => {
  return get(`/order/order/${orderId}/`)
}
const createOrderApi = ({ order }) => {
  return post("/order/order/", order)
}
const updateOrderApi = ({ orderId, order, statusUpdate }) => {
  if (statusUpdate) {
    return patch(`/order/order/${orderId}/`, statusUpdate)
  } else {
    return ApiPut(`/order/order/${orderId}/`, order)
  }
}
const updateOrderItemApi = ({ order, orderItemId, itemQty }) => {
  if (itemQty) {
    return patch(`order/orderitem/${orderItemId}/`, { quantity: itemQty })
  } else {
    return ApiPut(`order/orderitem/${orderItemId}/`, order)
  }
}

const deleteOrderApi = orderId => {
  return del(`/order/order/${orderId}/`)
}

function* fetchOrders({ payload }) {
  try {
    const response = yield call(getOrdersAPi, payload)
    yield put(getOrdersSuccess(response))
  } catch (error) {
    yield put(getOrdersFail(error))
  }
}

function* fetchOrderDetail({ orderId }) {
  try {
    const response = yield call(getOrderDetailsAPi, orderId)
    yield put(getOrderDetailSuccess(response))
  } catch (error) {
    yield put(getOrderDetailFail(error))
  }
}
function* onCreateOrder({ payload }) {
  try {
    const response = yield call(createOrderApi, payload)

    if (response.response === "No Stocks Available") {
      yield put(createOrderFail(response))
    } else {
      yield put(createOrderSuccess(response))
      payload.history.push("/orders")
      Notification({
        type: "success",
        message: "Successfully Created Order",
        title: "Created!",
      })
    }
  } catch (error) {
    yield put(createOrderFail(error))
    errorNotification()
  }
}

function* onUpdateOrder({ payload }) {
  try {
    const response = yield call(updateOrderApi, payload)
    if (response.response == "No Stocks Available") {
      yield put(updateOrderFail(response))
    } else {
      yield put(updateOrderSuccess(response))
      if (payload.history) {
        if (response?.id) {
          payload.history.push(`/orders/${response?.id}`)
        } else {
          payload.history.goBack()
        }
      }
      doneNotification()
    }
  } catch (error) {
    console.log(error)
    yield put(updateOrderFail(error))
    errorNotification()
  }
}

function* onUpdateOrderItem({ payload }) {
  try {
    const response = yield call(updateOrderItemApi, payload)
    yield put(updateOrderItemSuccess({ ...response, id: payload.orderItemId }))
    doneNotification()
  } catch (error) {
    errorNotification()
    yield put(updateOrderItemFail(error))
  }
}

function* onDeleteOrder({ orderId, history }) {
  try {
    const response = yield call(deleteOrderApi, orderId)
    yield put(deleteOrderSuccess(response))
    history.push("/orders")
    doneNotification()
  } catch (error) {
    yield put(deleteOrderFail(error))
    errorNotification()
  }
}

function* ordersSaga() {
  yield takeEvery(GET_ORDERS, fetchOrders)
  yield takeEvery(GET_ORDER_DETAIL, fetchOrderDetail)
  yield takeEvery(CREATE_ORDER, onCreateOrder)
  yield takeEvery(UPDATE_ORDER, onUpdateOrder)
  yield takeEvery(DELETE_ORDER, onDeleteOrder)
  yield takeEvery(UPDATE_ORDER_ITEM, onUpdateOrderItem)
}

export default ordersSaga
