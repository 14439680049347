/* FieldSites - This line cannot be edited or removed */
export const GET_FIELDSITES = "GET_FIELDSITES"
export const GET_FIELDSITES_SUCCESS = "GET_FIELDSITES_SUCCESS"
export const GET_FIELDSITES_FAIL = "GET_FIELDSITES_FAIL"

/* FieldSite_DETAILS */
export const GET_FIELDSITE_DETAILS = "GET_FIELDSITE_DETAILS"
export const GET_FIELDSITE_DETAILS_SUCCESS = "GET_FIELDSITE_DETAILS_SUCCESS"
export const GET_FIELDSITE_DETAILS_FAIL = "GET_FIELDSITE_DETAILS_FAIL"

/* CREATE FieldSite */
export const CREATE_FIELDSITE = "CREATE_FIELDSITE"
export const CREATE_FIELDSITE_SUCCESS = "CREATE_FIELDSITE_SUCCESS"
export const CREATE_FIELDSITE_FAIL = "CREATE_FIELDSITE_FAIL"

/* Edit FieldSite */
export const UPDATE_FIELDSITE = "UPDATE_FIELDSITE"
export const UPDATE_FIELDSITE_SUCCESS = "UPDATE_FIELDSITE_SUCCESS"
export const UPDATE_FIELDSITE_FAIL = "UPDATE_FIELDSITE_FAIL"

/* Delete FieldSite */
export const DELETE_FIELDSITE = "DELETE_FIELDSITE"
export const DELETE_FIELDSITE_SUCCESS = "DELETE_FIELDSITE_SUCCESS"
export const DELETE_FIELDSITE_FAIL = "DELETE_FIELDSITE_FAIL"

// map

export const GET_GMAP_PKEY = "GET_GMAP_PKEY"
export const GET_GMAP_PKEY_SUCCESS = "GET_GMAP_PKEY_SUCCESS"
export const GET_GMAP_PKEY_FAIL = "GET_GMAP_PKEY_FAIL"

export const SEARCH_LOCATION = "SEARCH_LOCATION"
export const SEARCH_LOCATION_SUCCESS = "SEARCH_LOCATION_SUCCESS"
export const SEARCH_LOCATION_FAIL = "SEARCH_LOCATION_FAIL"
