import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FIELDSTAFFS,
  GET_FIELDSTAFF_DETAILS,
  CREATE_FIELDSTAFF,
  UPDATE_FIELDSTAFF,
  DELETE_FIELDSTAFF,
  GET_ALL_FIELDS_COUNT,
} from "./actionTypes"
import {
  getFieldStaffsFail,
  getFieldStaffsSuccess,
  getFieldStaffDetailsSuccess,
  getFieldStaffDetailsFail,
  createFieldStaffFail,
  createFieldStaffSuccess,
  updateFieldStaffSuccess,
  updateFieldStaffFail,
  deleteFieldStaffSuccess,
  deleteFieldStaffFail,
  getAllFieldsCountSuccess,
  getAllFieldsCountFail,
} from "./actions"

import { Notification } from "../../components/Common/Notification"
import { ApiPut, del, get, post } from "helpers/api_methods"

// FieldStaff - This line cannot be edited or removed
function getFieldStaffsAPi({ page, sort, limit, searchText }) {
  return get(
    `/fieldsite/fieldstaff?sort=${sort}&page=${page ? page : 1}&limit=${
      limit ? limit : 10
    }&search=${searchText ? searchText : ""}`
  )
}

const getFieldStaffDetailsAPi = fieldstaffId => {
  return get(`/fieldsite/fieldstaff/${fieldstaffId}/`)
}

const createFieldStaffApi = ({ fieldstaff }) => {
  return post("/fieldsite/fieldstaff/", fieldstaff)
}

const updateFieldStaffApi = ({ fieldstaff, fieldstaffId }) => {
  return ApiPut(`/fieldsite/fieldstaff/${fieldstaffId}/`, fieldstaff)
}

const deleteFieldStaffApi = fieldstaffId => {
  console.log(fieldstaffId)
  return del(`/fieldsite/fieldstaff/${fieldstaffId}/`)
}

const getAllFieldsApi = () => {
  return get(`/fieldsite/visitentry-card-data`)
}

function* fetchFieldStaffs({ payload }) {
  try {
    const response = yield call(getFieldStaffsAPi, payload)
    yield put(getFieldStaffsSuccess(response))
  } catch (error) {
    yield put(getFieldStaffsFail(error))
  }
}

function* fetchFieldStaffDetails({ payload: fieldstaffId }) {
  try {
    const response = yield call(getFieldStaffDetailsAPi, fieldstaffId)
    yield put(getFieldStaffDetailsSuccess(response))
  } catch (error) {
    yield put(getFieldStaffDetailsFail(error))
  }
}

function* onCreateFieldStaff({ payload }) {
  try {
    const response = yield call(createFieldStaffApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createFieldStaffSuccess(response))
      payload.onCloseClick()
      doneNotification("FieldStaff Created Successfully!")
    }
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createFieldStaffFail(error))
  }
}

function* onUpdateFieldStaff({ payload }) {
  try {
    const response = yield call(updateFieldStaffApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "FieldStaff Updated Successfully!",
        title: "",
      })
      yield put(updateFieldStaffSuccess(response))
      if (payload.onCloseClick) {
        payload?.onCloseClick()
      }
    }
  } catch (error) {
    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateFieldStaffFail(error.response.data))
  }
}

function* onDeleteFieldStaff({
  payload: { fieldstaffId, onCloseClick, history },
}) {
  try {
    const response = yield call(deleteFieldStaffApi, fieldstaffId)
    yield put(deleteFieldStaffSuccess(fieldstaffId))
    Notification({
      type: "success",
      message: "FieldStaff Deleted Successfully!",
      title: "",
    })
    if (history) {
      history?.goBack()
    }
    onCloseClick()
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteFieldStaffFail(error?.response?.data))
  }
}

function* fetchAllFieldsCount({ payload }) {
  try {
    const response = yield call(getAllFieldsApi, payload)
    yield put(getAllFieldsCountSuccess(response))
  } catch (error) {
    yield put(getAllFieldsCountFail(error))
  }
}

function* fieldstaffSaga() {
  yield takeEvery(GET_FIELDSTAFFS, fetchFieldStaffs)
  yield takeEvery(GET_FIELDSTAFF_DETAILS, fetchFieldStaffDetails)
  yield takeEvery(CREATE_FIELDSTAFF, onCreateFieldStaff)
  yield takeEvery(UPDATE_FIELDSTAFF, onUpdateFieldStaff)
  yield takeEvery(DELETE_FIELDSTAFF, onDeleteFieldStaff)
  yield takeEvery(GET_ALL_FIELDS_COUNT, fetchAllFieldsCount)
}

export default fieldstaffSaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
