
/* VisitEntryImages - This line cannot be edited or removed */
export const GET_VISITENTRYIMAGES = 'GET_VISITENTRYIMAGES';
export const GET_VISITENTRYIMAGES_SUCCESS = 'GET_VISITENTRYIMAGES_SUCCESS';
export const GET_VISITENTRYIMAGES_FAIL = 'GET_VISITENTRYIMAGES_FAIL';

/* VisitEntryImage_DETAILS */
export const GET_VISITENTRYIMAGE_DETAILS = 'GET_VISITENTRYIMAGE_DETAILS';
export const GET_VISITENTRYIMAGE_DETAILS_SUCCESS = 'GET_VISITENTRYIMAGE_DETAILS_SUCCESS';
export const GET_VISITENTRYIMAGE_DETAILS_FAIL = 'GET_VISITENTRYIMAGE_DETAILS_FAIL';

/* CREATE VisitEntryImage */
export const CREATE_VISITENTRYIMAGE = 'CREATE_VISITENTRYIMAGE';
export const CREATE_VISITENTRYIMAGE_SUCCESS = 'CREATE_VISITENTRYIMAGE_SUCCESS';
export const CREATE_VISITENTRYIMAGE_FAIL = 'CREATE_VISITENTRYIMAGE_FAIL';

/* Edit VisitEntryImage */
export const UPDATE_VISITENTRYIMAGE = 'UPDATE_VISITENTRYIMAGE';
export const UPDATE_VISITENTRYIMAGE_SUCCESS = 'UPDATE_VISITENTRYIMAGE_SUCCESS';
export const UPDATE_VISITENTRYIMAGE_FAIL = 'UPDATE_VISITENTRYIMAGE_FAIL';

/* Delete VisitEntryImage */
export const DELETE_VISITENTRYIMAGE = 'DELETE_VISITENTRYIMAGE';
export const DELETE_VISITENTRYIMAGE_SUCCESS = 'DELETE_VISITENTRYIMAGE_SUCCESS';
export const DELETE_VISITENTRYIMAGE_FAIL = 'DELETE_VISITENTRYIMAGE_FAIL';
