import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_VISITENTRYS,
  GET_VISITENTRY_DETAILS,
  CREATE_VISITENTRY,
  UPDATE_VISITENTRY,
  DELETE_VISITENTRY,
} from "./actionTypes"
import {
  getVisitEntrysFail,
  getVisitEntrysSuccess,
  getVisitEntryDetailsSuccess,
  getVisitEntryDetailsFail,
  createVisitEntryFail,
  createVisitEntrySuccess,
  updateVisitEntrySuccess,
  updateVisitEntryFail,
  deleteVisitEntrySuccess,
  deleteVisitEntryFail,
} from "./actions"

import { get, post, ApiPut, del } from "helpers/api_methods"
import { Notification } from "../../components/Common/Notification"

// VisitEntry - This line cannot be edited or removed
function getVisitEntrysAPi({
  page,
  sort,
  limit,
  searchText,
  staffId,
  handleDateSearch,
}) {
  return get(
    `/fieldsite/visitentry?fieldsite=${sort ? sort : ""}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 10}&search=${searchText}&field_staff=${
      staffId ? staffId : ""
    }&from_date=${
      handleDateSearch?.from ? handleDateSearch?.from : ""
    }&to_date=${handleDateSearch?.to ? handleDateSearch?.to : ""}`
  )
}

const getVisitEntryDetailsAPi = visitentryId => {
  return get(`/fieldsite/visitentry/${visitentryId}/`)
}

const createVisitEntryApi = ({ visitentry }) => {
  return post("/fieldsite/visitentry/", visitentry)
}

const updateVisitEntryApi = ({ visitentry, visitentryId }) => {
  return ApiPut(`/fieldsite/visitentry/${visitentryId}/`, visitentry)
}

const deleteVisitEntryApi = visitentryId => {
  console.log(visitentryId)
  return del(`/fieldsite/visitentry/${visitentryId}/`)
}

function* fetchVisitEntrys({ payload }) {
  try {
    const response = yield call(getVisitEntrysAPi, payload)
    yield put(getVisitEntrysSuccess(response))
  } catch (error) {
    yield put(getVisitEntrysFail(error))
  }
}

function* fetchVisitEntryDetails({ payload: visitentryId }) {
  try {
    const response = yield call(getVisitEntryDetailsAPi, visitentryId)
    yield put(getVisitEntryDetailsSuccess(response))
  } catch (error) {
    yield put(getVisitEntryDetailsFail(error))
  }
}

function* onCreateVisitEntry({ payload }) {
  try {
    const response = yield call(createVisitEntryApi, payload)
    if (response?.message) {
      Notification({
        type: "error",
        message: response?.message,
        title: "",
      })
    } else if (response) {
      yield put(createVisitEntrySuccess(response))
      payload?.history?.push(`/visit-entry/${response?.id}`)
      doneNotification("VisitEntry Created Successfully!")
    }
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(createVisitEntryFail(error))
  }
}

function* onUpdateVisitEntry({ payload }) {
  try {
    const response = yield call(updateVisitEntryApi, payload)
    if (response) {
      Notification({
        type: "success",
        message: "VisitEntry Updated Successfully!",
        title: "",
      })
      yield put(updateVisitEntrySuccess(response))
      if (payload?.history) {
        payload?.history.goBack()
      }
    }
  } catch (error) {
    console.log(error)

    if (error.response?.data?.message) {
      Notification({
        type: "error",
        message: error.response?.data?.message,
        title: "",
      })
    }
    if (error?.response?.status === 413) {
      Notification({
        type: "error",
        message: "file size is Too Large",
        title: "",
      })
    }
    yield put(updateVisitEntryFail(error.response.data))
  }
}

function* onDeleteVisitEntry({ payload }) {
  console.log("payload => ", payload)
  try {
    const response = yield call(deleteVisitEntryApi, payload?.visitentryId)
    yield put(deleteVisitEntrySuccess(response))
    Notification({
      type: "success",
      message: "VisitEntry Deleted Successfully!",
      title: "",
    })
    payload?.history?.goBack()
  } catch (error) {
    console.log(error)
    if (error?.response?.data?.message) {
      Notification({
        type: "error",
        message: error?.response?.data?.message,
        title: "",
      })
    }
    yield put(deleteVisitEntryFail(error?.response?.data))
  }
}

function* visitentrySaga() {
  yield takeEvery(GET_VISITENTRYS, fetchVisitEntrys)
  yield takeEvery(GET_VISITENTRY_DETAILS, fetchVisitEntryDetails)
  yield takeEvery(CREATE_VISITENTRY, onCreateVisitEntry)
  yield takeEvery(UPDATE_VISITENTRY, onUpdateVisitEntry)
  yield takeEvery(DELETE_VISITENTRY, onDeleteVisitEntry)
}

export default visitentrySaga

function doneNotification(message) {
  Notification({
    type: "success",
    message: message,
    title: "",
  })
}
