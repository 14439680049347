import {
  GET_SHEETS,
  GET_SHEETS_SUCCESS,
  GET_SHEETS_FAIL,
  GET_SHEET_DETAIL,
  GET_SHEET_DETAIL_SUCCESS,
  GET_SHEET_DETAIL_FAIL,
  CREATE_SHEET,
  CREATE_SHEET_SUCCESS,
  CREATE_SHEET_FAIL,
  UPDATE_SHEET,
  UPDATE_SHEET_SUCCESS,
  UPDATE_SHEET_FAIL,
  DELETE_SHEET,
  DELETE_SHEET_SUCCESS,
  DELETE_SHEET_FAIL,
  GET_SHEET_ORDERS,
  GET_SHEET_ORDERS_SUCCESS,
  GET_SHEET_ORDERS_FAIL,
  GET_SHEET_ORDER_DETAIL,
  GET_SHEET_ORDER_DETAIL_SUCCESS,
  GET_SHEET_ORDER_DETAIL_FAIL,
  CREATE_SHEET_ORDER,
  CREATE_SHEET_ORDER_SUCCESS,
  CREATE_SHEET_ORDER_FAIL,
  UPDATE_SHEET_ORDER,
  UPDATE_SHEET_ORDER_SUCCESS,
  UPDATE_SHEET_ORDER_FAIL,
  DELETE_SHEET_ORDER,
  DELETE_SHEET_ORDER_SUCCESS,
  DELETE_SHEET_ORDER_FAIL,
  ADD_SHEET_QUANTITY,
  UPDATE_ORDER_ITEM_QUANTITY,
  ORDER_DELIVERY_CHANGE,
  GET_SHEET_TOTAL_WEIGHT,
  GET_SHEET_TOTAL_WEIGHT_SUCCESS,
  GET_SHEET_TOTAL_WEIGHT_FAIL,
  GET_SHEET_ORDERS_WEIGHT,
  GET_SHEET_ORDERS_WEIGHT_SUCCESS,
  GET_SHEET_ORDERS_WEIGHT_FAIL
} from "./actionTypes"

export const getSheets = (searchText,thick,width, page) => ({
  type: GET_SHEETS,
  payload: { searchText,thick,width, page },
})

export const getSheetsSuccess = (payload,thick) => ({
  type: GET_SHEETS_SUCCESS,
  payload: {payload,thick}
})
export const getSheetsFail = error => ({
  type: GET_SHEETS_FAIL,
  payload: error,
})

export const getSheetDetail = id => ({
  type: GET_SHEET_DETAIL,
  id,
})

export const getSheetDetailSuccess = data => ({
  type: GET_SHEET_DETAIL_SUCCESS,
  payload: data,
})

export const getSheetDetailFail = error => ({
  type: GET_SHEET_DETAIL_FAIL,
  payload: error,
})

export const createSheet = (data, history) => ({
  type: CREATE_SHEET,
  payload: { data, history },
})

export const createSheetSuccess = id => ({
  type: CREATE_SHEET_SUCCESS,
  payload: id,
})

export const createSheetFail = error => ({
  type: CREATE_SHEET_FAIL,
  payload: error,
})

export const updateSheet = (id, data, isUpdate) => ({
  type: UPDATE_SHEET,
  payload: { id, data, isUpdate },
})

export const updateSheetSuccess = id => ({
  type: UPDATE_SHEET_SUCCESS,
  payload: id,
})

export const updateSheetFail = error => ({
  type: UPDATE_SHEET_FAIL,
  payload: error,
})

export const deleteSheet = id => ({
  type: DELETE_SHEET,
  payload: { id },
})

export const deleteSheetSuccess = id => ({
  type: DELETE_SHEET_SUCCESS,
  payload: { id },
})

export const deleteSheetFail = error => ({
  type: DELETE_SHEET_FAIL,
  payload: error,
})
export const getSheetTotalWeight = (thick,width) => ({
  type: GET_SHEET_TOTAL_WEIGHT,
  payload : {thick,width}
})
export const getSheetTotalWeightSuccess = payload => ({
  type: GET_SHEET_TOTAL_WEIGHT_SUCCESS,
  payload : payload,
})
export const getSheetTotalWeightFail = error => ({
  type: GET_SHEET_TOTAL_WEIGHT_FAIL,
  error,
})




export const getSheetOrders = (searchText,orderType, page) => ({
  type: GET_SHEET_ORDERS,
  payload: { searchText,orderType, page },
})

export const getSheetOrdersSuccess = sheets => ({
  type: GET_SHEET_ORDERS_SUCCESS,
  payload: sheets,
})
export const getSheetOrdersFail = error => ({
  type: GET_SHEET_ORDERS_FAIL,
  payload: error,
})

export const getSheetOrderDetail = id => ({
  type: GET_SHEET_ORDER_DETAIL,
  id,
})

export const getSheetOrderDetailSuccess = data => ({
  type: GET_SHEET_ORDER_DETAIL_SUCCESS,
  payload: data,
})

export const getSheetOrderDetailFail = error => ({
  type: GET_SHEET_ORDER_DETAIL_FAIL,
  payload: error,
})

export const createSheetOrder = (data, history) => ({
  type: CREATE_SHEET_ORDER,
  payload: { data, history },
})

export const createSheetOrderSuccess = id => ({
  type: CREATE_SHEET_ORDER_SUCCESS,
  payload: id,
})

export const createSheetOrderFail = error => ({
  type: CREATE_SHEET_ORDER_FAIL,
  payload: error,
})

export const updateSheetOrder = (id, data, isUpdate) => ({
  type: UPDATE_SHEET_ORDER,
  payload: { id, data, isUpdate },
})

export const updateSheetOrderSuccess = id => ({
  type: UPDATE_SHEET_ORDER_SUCCESS,
  payload: id,
})

export const updateSheetOrderFail = error => ({
  type: UPDATE_SHEET_ORDER_FAIL,
  payload: error,
})

export const deleteSheetOrder = id => ({
  type: DELETE_SHEET_ORDER,
  payload: { id },
})

export const deleteSheetOrderSuccess = id => ({
  type: DELETE_SHEET_ORDER_SUCCESS,
  payload: { id },
})

export const deleteSheetOrderFail = error => ({
  type: DELETE_SHEET_ORDER_FAIL,
  payload: error,
})


export const getSheetOrdersWeight = (from,to,orderType) => ({
  type: GET_SHEET_ORDERS_WEIGHT,
  payload: { from,to, orderType },
})

export const getSheetOrdersWeightSuccess = sheets => ({
  type: GET_SHEET_ORDERS_WEIGHT_SUCCESS,
  payload: sheets,
})
export const getSheetOrdersWeightFail = error => ({
  type: GET_SHEET_ORDERS_WEIGHT_FAIL,
  payload: error,
})



export const addSheetQuantity = (sheet_id, qty) => ({
  type: ADD_SHEET_QUANTITY,
  payload: { sheet_id, qty },
})

export const updateOrderItemQuantity = (id, qty) => ({
  type: UPDATE_ORDER_ITEM_QUANTITY,
  payload: { id, qty },
})

export const onOrderDeliveryChange = (val) => ({
  type: ORDER_DELIVERY_CHANGE,
  payload: { val },
})