import {
  GET_VISITENTRYIMAGES,
  GET_VISITENTRYIMAGES_SUCCESS,
  GET_VISITENTRYIMAGES_FAIL,
  GET_VISITENTRYIMAGE_DETAILS,
  GET_VISITENTRYIMAGE_DETAILS_SUCCESS,
  GET_VISITENTRYIMAGE_DETAILS_FAIL,
  CREATE_VISITENTRYIMAGE,
  CREATE_VISITENTRYIMAGE_SUCCESS,
  CREATE_VISITENTRYIMAGE_FAIL,
  UPDATE_VISITENTRYIMAGE,
  UPDATE_VISITENTRYIMAGE_SUCCESS,
  UPDATE_VISITENTRYIMAGE_FAIL,
  DELETE_VISITENTRYIMAGE,
  DELETE_VISITENTRYIMAGE_SUCCESS,
  DELETE_VISITENTRYIMAGE_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  visitentryimages: {
    visitentryimages: [],
    total: "",
    page: "",
  },
  visitentryimageDetails: {},
  error: {},
  loading: false,
}

const VisitEntryImage = (state = INIT_STATE, action) => {
  switch (action.type) {
    // VisitEntryImage - This line cannot be edited or removed
    case GET_VISITENTRYIMAGES:
    case GET_VISITENTRYIMAGE_DETAILS:
    case CREATE_VISITENTRYIMAGE:
    case UPDATE_VISITENTRYIMAGE:
    case DELETE_VISITENTRYIMAGE:
      return {
        ...state,
        loading: true,
      }

    case DELETE_VISITENTRYIMAGE_FAIL:
    case GET_VISITENTRYIMAGES_FAIL:
    case GET_VISITENTRYIMAGE_DETAILS_FAIL:
    case UPDATE_VISITENTRYIMAGE_FAIL:
    case CREATE_VISITENTRYIMAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_VISITENTRYIMAGES_SUCCESS:
      return {
        ...state,
        visitentryimages: action.payload,
        error: {},
        loading: false,
      }

    case GET_VISITENTRYIMAGE_DETAILS_SUCCESS:
      return {
        ...state,
        visitentryimageDetails: action.payload,
        error: {},
        loading: false,
      }

    case CREATE_VISITENTRYIMAGE_SUCCESS:
      console.log(state)
      return {
        ...state,
        visitentryimages: {
          ...state.visitentryimages,
          results: [...state.visitentryimages.results, action.payload],
          total: state.visitentryimages.count + 1,
        },
        visitentryimageDetails: action.payload,
        error: {},
        loading: false,
      }

    case UPDATE_VISITENTRYIMAGE_SUCCESS:
      return {
        ...state,
        visitentryimages: {
          ...state.visitentryimages,
          results: state.visitentryimages.visitentryimages.map(
            visitentryimage =>
              visitentryimage._id === action.payload._id
                ? { ...visitentryimage, ...action.payload }
                : visitentryimage
          ),
        },
        visitentryimageDetails: action.payload,
        loading: false,
        error: {},
      }

    case DELETE_VISITENTRYIMAGE_SUCCESS:
      return {
        ...state,
        visitentryimages: {
          ...state.visitentryimages,
          results: state.visitentryimages.results.filter(
            visitentryimage => visitentryimage.id !== action.payload
          ),
        },
        error: {},
        loading: false,
      }
    default:
      return state
  }
}

export default VisitEntryImage
