import React, { useEffect, useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Spinner,
  Button,
  Alert,
} from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import moment from "moment"
import DatePicker from "react-datepicker"
import { map } from "lodash"

//actions
import { getOrderDetail, updateOrder } from "store/actions"

//componetns
import OrderDetail from "./OrderDetail"

//css
import "react-datepicker/dist/react-datepicker.css"

function OrderStatus() {
  const dispatch = useDispatch()
  const params = useParams()

  const { orderDetail, loading, error } = useSelector(state => ({
    orderDetail: state.Orders.orderDetail,
    error: state.Orders.error,
    loading: state.Orders.loading,
  }))

  const [startDate, setStartDate] = useState(Date.now())
  const [endDate, setEndDate] = useState(Date.now())

  const [newStatus, setNewStatus] = useState({
    status: "",
  })
  const [orderPartialUpdate, setOrderPartialUpdate] = useState({
    customer_name: "",
    delivery_site: "",
    note: "",
  })

  useEffect(() => {
    dispatch(getOrderDetail(params.id))
  }, [dispatch])

  const status = [
    {
      id: 100,
      statusText: "Pending",
      class: "info",
      text: "Pending",
    },
    {
      id: 200,
      statusText: "Approved",
      class: "success",
      text: "Approve",
    },
    {
      id: 300,
      statusText: "Canceled",
      class: "danger",
      text: "Cancel",
    },

    {
      id: 400,
      statusText: "Shipped",
      class: "success",
      text: "Shipped",
    },
    {
      id: 500,
      statusText: "Delivered",
      class: "success",
      text: "Delivered",
    },
  ]

  function statusList() {
    if (orderDetail?.status == "Pending") {
      return status?.slice(1, 3)
    } else if (orderDetail?.status == "Approved") {
      return status?.filter(item => item.statusText == "Shipped")
    } else if (orderDetail?.status == "Shipped") {
      return status?.filter(item => item.statusText == "Delivered")
    } else if (orderDetail?.status == "Started") {
      return status?.filter(item => item.statusText == "Shipped")
    }
  }

  function handlerFinalValue(event) {
    setNewStatus({
      ["status"]: event.target.value,
    })
    {
      if (event.target.value == "Approved") {
        dispatch(
          updateOrder("", params.id, "", {
            status: event.target.value,
          })
        )
      } else {
        dispatch(
          updateOrder("", params.id, "", {
            status: event.target.value,
          })
        )
      }
    }
  }

  const Status = status => {
    if (status == "Pending") {
      return "info"
    }
    if (status == "Approved") {
      return "success"
    }
    if (status == "Canceled") {
      return "danger"
    }
    if (status == "Started") {
      return "warning"
    }
    if (status == "Shipped") {
      return "success"
    }
    if (status == "Delivered") {
      return "success"
    }
  }

  const handleSubmit = () => {
    const dates = {
      ...orderPartialUpdate,
      start_date: moment(startDate).format("yyyy-MM-DD"),
      end_date: moment(endDate).format("yyyy-MM-DD"),
    }
    dispatch(updateOrder("", params.id, "", dates))

    dispatch(getOrderDetail(params.id))
  }

  const Role = localStorage.getItem("role")

  const Handler = () => {
    if (orderDetail?.status == "Canceled") {
      return false
    } else if (Role == "dealer") {
      return false
    } else if (orderDetail?.status == "Delivered") {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    setOrderPartialUpdate({
      ...orderPartialUpdate,
      customer_name: orderDetail?.customer_name,
      delivery_site: orderDetail?.delivery_site,
      note: orderDetail?.note,
    })
  }, [orderDetail])

  return (
    <>
      <Col lg={`${Handler() ? "6" : "12"}`} style={{ paddingleft: 0 }}>
        <Card>
          <CardBody>
            {loading ? (
              <Spinner type="grow" color="gray" />
            ) : (
              <>
                <OrderDetail />
              </>
            )}

            <Row className="task-dates d-flex justify-content-between">
              <Col sm="4" xs="6">
                <div className="mt-4 pb-2">
                  <Badge
                    className={
                      "font-size-14 p-2 badge-soft-" +
                      `${Status(orderDetail?.status)}`
                    }
                    pill
                  >
                    {orderDetail?.status}
                  </Badge>
                </div>
              </Col>
              {newStatus?.label == "canceled" && (
                <Col sm="4" xs="6">
                  <div className="mt-4">
                    <Link
                      style={{ opacity: 0.8 }}
                      to="#"
                      className={`btn btn-danger btn-sm`}
                    >
                      Delete this order
                      <i className="fas fa-trash ms-1"></i>
                    </Link>
                  </div>
                </Col>
              )}
            </Row>
            <Row className="mt-4">
              <CardTitle className="mb-2">Update Status</CardTitle>
              {error?.response && (
                <Alert color="danger">{error?.response}</Alert>
              )}
              <div className="mb-1 ajax-select mt-3 mt-lg-0 select2-container">
                {map(statusList(), (item, index) => (
                  <Button
                    key={index}
                    type="submit"
                    color={item.class}
                    value={item.statusText}
                    className="w-md mb-2 btn-md "
                    style={{ marginRight: "1rem" }}
                    onClick={e => handlerFinalValue(e)}
                  >
                    {item.text}
                  </Button>
                ))}
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
      {Handler() && (
        <Col lg="6" style={{ paddingRight: "0" }}>
          <Card>
            <CardBody>
              {orderDetail?.status == "Pending" && (
                <>
                  <Row>
                    <Col className="col-6 position-relative">
                      <label htmlFor="resume">
                        Start Date (
                        {orderDetail?.start_date ? orderDetail?.start_date : ""}
                        )
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        minDate={moment().toDate()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control mb-2"
                      />
                    </Col>
                    <Col className="col-6">
                      <label htmlFor="resume">
                        End Date (
                        {orderDetail?.end_date ? orderDetail?.end_date : ""})
                      </label>

                      <DatePicker
                        selected={endDate}
                        onChange={date => setEndDate(date)}
                        minDate={moment().toDate()}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                      />
                    </Col>
                    <Col className="col-6 mt-1">
                      <label htmlFor="resume">Customer Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Customer Name"
                        onChange={e =>
                          setOrderPartialUpdate({
                            ...orderPartialUpdate,
                            customer_name: e.target.value,
                          })
                        }
                        value={orderPartialUpdate.customer_name}
                      />
                    </Col>
                    <Col className="col-6 mt-1">
                      <label htmlFor="resume">Delivery Site</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Delivery Site"
                        onChange={e =>
                          setOrderPartialUpdate({
                            ...orderPartialUpdate,
                            delivery_site: e.target.value,
                          })
                        }
                        value={orderPartialUpdate.delivery_site}
                      />
                    </Col>
                    <Col lg="12" className="mt-2">
                      <label htmlFor="resume">Comment</label>
                      <textarea
                        type="text"
                        className="form-control"
                        placeholder="Enter Comment"
                        rows="4"
                        cols="50"
                        name="note"
                        onChange={e =>
                          setOrderPartialUpdate({
                            ...orderPartialUpdate,
                            note: e.target.value,
                          })
                        }
                        value={orderPartialUpdate.note}
                      />
                    </Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "end",
                      }}
                      className="mt-2"
                    >
                      <Button
                        type="submit"
                        color="success"
                        className="w-md mb-1 mt-2 btn-md"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      )}
    </>
  )
}

export default OrderStatus
